import { environmentVariables } from "../../../config";
import { OrderWithEscalation } from "../../Dashboards/EscalationsDashboard/types";
import { DashboardRow } from "../../Dashboards/types";

type LinkCellProps = {
  row: DashboardRow | OrderWithEscalation;
};

export function LinkCell(props: LinkCellProps) {
  const { row } = props;

  return (
    <>
      <a
        className="underline text-aa-purple"
        href={`${environmentVariables.aeUrl}/loan-consultants/all-leads/id=${row?.aeLeadId}`}
        target="_blank"
      >
        {row?.recordId}
      </a>
    </>
  );
}
