import { WORKFLOW_HOST_URL } from "../core/types";
import axios, { AxiosRequestConfig } from "axios";
import { ActionListing, ActionListingWithAssociations } from "./types";

export async function getActionListings(titleOrderId: number, token: string): Promise<Array<ActionListingWithAssociations>> {
  const url = `${WORKFLOW_HOST_URL}/action-listing?titleOrderId=${titleOrderId}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.get(url, config);
  return response.data;
}

export async function createActionListing(actionListing: ActionListing, token: string) {
  const url = `${WORKFLOW_HOST_URL}/action-listing`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  await axios.post(url, actionListing, config);
}