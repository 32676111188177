import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { Circle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { formatDate } from "../Dashboards/shared/FieldFormats";
import { NotificationEvent, NotificationStatus } from "../../networking/core/types";

export function NotificationItem(props: { notification: NotificationEvent }) {
  const { notification } = props;
  const isUnread = notification.status === NotificationStatus.UNREAD;

  return (
    <Box
      py={"15px"}
      height={"125px"}
      bgcolor={isUnread ? "#F9F8FB" : "transparent"}
      borderBottom={"1px solid #E0E0E0"}
      sx={{ display: "flex", flexShrink: 0 }}
    >
      <Box px={"20px"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Circle
          sx={{
            fontSize: "10px",
            color: "#5132A2",
            visibility: isUnread ? "visible" : "hidden",
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography pb={"10px"} sx={{ fontSize: "12px", fontWeight: "400" }}>
          {formatDate(notification.eventDate, { formatOptions: DateTime.DATETIME_SHORT_WITH_SECONDS })}
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{notification.data}</Typography>
      </Box>
    </Box>
  );
}
