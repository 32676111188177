import { Static, Type } from "@sinclair/typebox";
import { validateAgainstSchema } from "../core/schemaValidation";

export type AeNotes = {
  leadId: string;
  data: AeNote[];
  any: unknown;
  skip: number;
  take: number;
  count: number;
};

export type AeNote = {
  id: string;
  createdAt: string;
  description: string;
  createdByName: string | null;
};

export type CreateAeNoteResponse = Static<typeof AeNoteSchema>;
export const AeNoteSchema = Type.Object({
  id: Type.String(),
  createdAt: Type.String(),
  description: Type.String(),
  createdByName: Type.Union([Type.String(), Type.Null()]),
});
export const isCreateNoteResponse = (input: unknown): input is CreateAeNoteResponse =>
  validateAgainstSchema(input, AeNoteSchema);

export type AeNotesResponse = Static<typeof AeNotesSchema>;
export const AeNotesSchema = Type.Object({
  leadId: Type.String(),
  any: Type.Unknown(),
  skip: Type.Number(),
  take: Type.Number(),
  count: Type.Number(),
  data: Type.Array(AeNoteSchema),
});
export const isNotesForLeadResponse = (input: unknown): input is AeNotesResponse =>
  validateAgainstSchema(input, AeNotesSchema);
