import { AeLeadStatus } from "@auto-approve/auto-approve-ui-library";

export const AeLeadStatuses: AeLeadStatus[] = Object.values(AeLeadStatus).filter(
  (val): val is AeLeadStatus => typeof val !== "string"
);

export enum TitleEngineStatus {
  EstimateCreated,
  DeadEstimate,
  OrderCreated,
  PacketBuilding,
  VendorHold,
  ReadyForReview,
  SubmittedToVendor,
  ReceivedByVendor,
  SentToJurisdiction,
  DMVOnHold,
  Perfected,
  ResubmittedToVendor,
}

export const titleHoldingStates = new Set(["KY", "MD", "MI", "MN", "MO", "MT", "NY", "OK", "WY"]);
export const nonTitleHoldingStates = new Set([
  "AK",
  "AL",
  "AR",
  "CT",
  "DC",
  "DE",
  "IN",
  "ME",
  "MS",
  "ND",
  "NH",
  "NJ",
  "NM",
  "OR",
  "RI",
  "TN",
  "UT",
  "WI",
  "WV",
]);

export enum WklsEstimateTransactionType {
  TitleAndRegistration = "Title and Registration",
  LienAdd = "Lien Add",
  TitleOnly = "Title Only",
  LienAddAddRemoveSpouse = "Lien Add - Add/Remove Spouse",
  DuplicateTitle = "Duplicate Title",
}

export enum WklsEstimateTransactionDisplayType {
  TitleAndRegistrationStateChange = "Title and Registration - State Change",
  TitleAndRegistrationLeaseBuyout = "Title and Registration - Lease Buyout",
  LienAdd = "Lien Add",
  TitleOnly = "Title Only",
  LienAddAddRemoveSpouse = "Lien Add - Add/Remove Spouse",
  DuplicateTitle = "Duplicate Title",
}
