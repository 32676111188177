import Page from "../shared/Page/Page";
import { useState } from "react";
import { getTitleOrder } from "../../networking/TitlesWorkflowAPI";
import { useAuth } from "../Auth/AuthProvider";
import { useQueryParams } from "../../Hooks/customHooks";
import { LookupByRecordId } from "../CreateEstimate/steps/0_LookupByRecordId";
import { Box, CircularProgress } from "@mui/material";
import DocumentsSection from "./DocumentsSection";
import { useQuery } from "@tanstack/react-query";

export const DocumentsPage = () => {
  const { user } = useAuth();

  const leadId = useQueryParams("leadId") ?? undefined;
  const [recordId, setRecordId] = useState<string>(useQueryParams("recordId") ?? "");

  const { isFetching, data: lead } = useQuery(
    // TODO - confirm proper query key complex handling
    ["getLead", leadId, recordId],
    () => getTitleOrder(
      {
        aeLeadId: leadId,
        aeRecordId: recordId,
        refresh: true
      },
      user?.token ?? ""),
    {
      refetchOnWindowFocus: false,
      enabled: leadId !== undefined || recordId !== ""
      // TODO add error handling
    });

  return <div className={"w-full mt-3"}>
    <Page>
      {isFetching
        ? <Box
          display="flex"
          margin="auto"
          width="100%"
          justifyContent="center"
          position="absolute"
          top="50%"
          sx={{ transform: "translate(0, -50%)" }}
        >
          <CircularProgress />
        </Box>
        : lead
          ? <DocumentsSection leadOid={lead.aeLeadId} lead={lead} />
          : <LookupByRecordId
            onSubmit={async ({ recordId }) => {
              setRecordId(recordId);
            }}
            headerOverride="Get Documents"
            /* empty string will trigger formik validation error */
            defaultRecordId={recordId === "" ? null : recordId}
          />}
    </Page>
  </div>;
};
