import { CustomButton } from "./Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { environmentVariables } from "../../../config";
import { getCopy } from "../../../authoring/copy";

// TODO - move shared copy to a single place
const copy = getCopy().baseDrawer.details.actionsList;

type OpenRecordInAeButtonProps = {
  aeLeadId: string;
};

export const OpenRecordInAeButton = (props: OpenRecordInAeButtonProps) => <a target="_blank"
  href={`${environmentVariables.aeUrl}/loan-consultants/all-leads/id=${props.aeLeadId}`}>
  <CustomButton variant="outlined">
    <div style={{ display: "flex", alignItems: "center" }}>
      {copy.openInAEButtonText}<OpenInNewIcon style={{ paddingLeft: "2px" }} fontSize="small" />
    </div>
  </CustomButton>
</a>;