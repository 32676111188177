import { StatusSeverity } from "../../../networking/core/types";
import { formatDaysInStatus } from "./FieldFormats";
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getCopy } from "../../../authoring/copy";

const copy = getCopy().titleOrdersDashboard.severities;

export function DaysInStatusCell(props: { daysInStatus: number }) {
  return <div>{formatDaysInStatus(props.daysInStatus)}</div>;
}

const options = {
  shouldForwardProp: (prop: string) => prop !== "bgcolor",
};
const StyledChip = styled(
  Chip,
  options
)((props: { bgcolor: string }) => ({
  color: "white",
  backgroundColor: props.bgcolor,
  height: "24px",
  lineHeight: "normal",
}));
export function StatusSeverityChip(props: { severity: StatusSeverity | undefined }) {
  switch (props.severity) {
    case StatusSeverity.Red:
      return <StyledChip label={copy[StatusSeverity.Red]} bgcolor="rgba(223, 48, 74, 0.6)" />;
    case StatusSeverity.Yellow:
      return <StyledChip label={copy[StatusSeverity.Yellow]} bgcolor="rgba(242, 195, 29, 0.6)" />;
    case StatusSeverity.Green:
      return <StyledChip label={copy[StatusSeverity.Green]} bgcolor="rgba(76, 175, 80, 0.6)" />;
    default:
      return <></>;
  }
}
