import addFormat from "ajv-formats";
import Ajv from "ajv/dist/2020";
import { Static, Type } from "@sinclair/typebox";
import { TitleEngineStatus } from "../../components/types";
import { DateTime } from "luxon";
import { User } from "../../components/Auth/Auth";
import { UIEstimateTemplate, UIEstimateTemplateSchema } from "../../contexts/estimates/core/types";
import { environmentVariables } from "../../config";
import { validateAgainstSchema } from "./schemaValidation";
import { AeLeadStatus, AeRole } from "@auto-approve/auto-approve-ui-library";

export const ajv = addFormat(new Ajv({}), ["date-time"]).addKeyword("kind").addKeyword("modifier");

export const WORKFLOW_HOST_URL = `${environmentVariables.titlesWorkflowApi}`;

// AE 1.0: https://dev.azure.com/tf-autoapprove/AutoApprove.Microservice.LeadManagement/_git/AutoApprove.Microservice.LeadManagement?path=/AutoApprove.Microservice.LeadManagement.Shared/Enums/DealTypeEnum.cs&version=GBmain-dev
// TWS: https://gitlab.com/auto-approve/approve-engine/titles-engine/titles-workflow-server/-/blob/main/src/contexts/titles/approve_engine/core/types.ts#L16
export enum AeDealTypeEnum {
  LeaseBuyout = 1,
  Refinance = 2,
  Purchase = 3,
}

export enum StatusSeverity {
  Red = 3,
  Yellow = 2,
  Green = 1,
}

export type AEComments = Static<typeof AECommentsSchema>;

const AECommentsSchema = Type.Object({
  timestamp: Type.Optional(Type.Number()),
  textComment: Type.Optional(Type.String()),
});

export const TitleOrderSchema = Type.Object({
  id: Type.Number(),
  teOrderId: Type.String(),
  aeLeadId: Type.String(),
  aeRecordId: Type.Number(),
  ownerId: Type.Optional(Type.String()),
  fundedDate: Type.Optional(Type.String()),
  lenderName: Type.Optional(Type.String()),
  createTimeStamp: Type.Optional(Type.String()),
  updateTimeStamp: Type.Optional(Type.String()),
  state: Type.Optional(Type.String()),
  transactionType: Type.Optional(Type.String()),
  eta: Type.Optional(Type.String()),
  duplicateTitleEta: Type.Optional(Type.String()),
  assignedAssociateTitleSpecialistId: Type.Optional(Type.String()),
  lastTouched: Type.Optional(Type.String()),
  newAeStatus: Type.Enum(AeLeadStatus),
  aeStatusTimeStamp: Type.String(),
});

export type StatusHistoryEvent = Static<typeof StatusHistoryEventSchema>;
export const StatusHistoryEventSchema = Type.Object({
  id: Type.Number(),
  titleOrderId: Type.Number(),
  oldStatus: Type.Optional(Type.Enum(AeLeadStatus)),
  newStatus: Type.Enum(AeLeadStatus),
  reason: Type.Optional(Type.String()),
  timeStamp: Type.String(),
});
export const WithStatusChangeEvents = Type.Object({
  statusChangeEvents: Type.Array(StatusHistoryEventSchema),
});

export enum TitleOrderEtaType {
  CUSTOMER = "CUSTOMER",
  LIENHOLDER = "LIENHOLDER",
}

export type TitleOrderWithStatusChangeEvents = Static<typeof TitleOrderWithStatusChangeEventsSchema>;
export const TitleOrderWithStatusChangeEventsSchema = Type.Object({
  titleOrder: Type.Intersect([TitleOrderSchema, WithStatusChangeEvents]),
});
export const isTitleOrderWithStatusChangeEvents = (input: unknown): input is TitleOrderWithStatusChangeEvents =>
  validateAgainstSchema(input, TitleOrderWithStatusChangeEventsSchema);

export const TitleOrderDocEtaResponseSchema = Type.Object({
  id: Type.Integer(),
  date: Type.String(),
  type: Type.Enum(TitleOrderEtaType),
  titleOrderId: Type.Integer(),
  titleOrderTaskId: Type.Integer(),
  titleOrderTaskHistoryId: Type.Integer(),
});
export const TitleOrderDocEtaListResponseSchema = Type.Object({
  documentEtas: Type.Array(TitleOrderDocEtaResponseSchema),
});
export type TitleOrderDocEtaListResponse = Static<typeof TitleOrderDocEtaListResponseSchema>;
export type TitleOrderDocEtaResponse = Static<typeof TitleOrderDocEtaResponseSchema>;
export type TitleOrderDocEta = {
  id: number;
  date: DateTime;
  type: TitleOrderEtaType;
  titleOrderId: number;
  titleOrderTaskId: number;
  titleOrderTaskHistoryId: number;
};

export enum TopPriorityType {
  CUSTOMER = "Customer",
  LIENHOLDER = "Lienholder",
  CUSTOMER_AND_LIENHOLDER = "Customer | Lienholder",
}

// Reference to TitleVendorTypeEnum in AE: https://dev.azure.com/tf-autoapprove/_git/AutoApprove.Microservice.LeadManagement?path=/AutoApprove.Microservice.LeadManagement.Shared/Enums/TitleVendorTypeEnum.cs&version=GBmain-dev&line=5&lineEnd=6&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
export enum AeTitleVendorTypeEnum {
  AutoApprove = 0,
  WKLS = 1,
  LocationServices = 2
}
export const isTitleOrderDocEtaResponse = (input: unknown): input is TitleOrderDocEtaResponse =>
  validateAgainstSchema(input, TitleOrderDocEtaResponseSchema);
export const isTitleOrderDocEtaListResponse = (input: unknown): input is TitleOrderDocEtaListResponse =>
  validateAgainstSchema(input, Type.Object({ documentEtas: Type.Array(TitleOrderDocEtaResponseSchema) }));

export type DashboardTitleOrder = Static<typeof DashboardTitleOrderSchema>;
export type WithEscalation = Static<typeof WithEscalationSchema>;

const DashboardTitleOrderSchema = Type.Object({
  titleOrderId: Type.Integer(),
  recordId: Type.Number(),
  aeLeadId: Type.String(),
  customer: Type.Optional(
    Type.Object({
      firstName: Type.Optional(Type.String()),
      middleName: Type.Optional(Type.String()),
      lastName: Type.Optional(Type.String()),
      birthday: Type.Optional(Type.String()),
      phone: Type.Optional(Type.String()),
      id: Type.Optional(Type.Number()),
    })
  ),
  dealType: Type.Optional(Type.Union([Type.Null(), Type.Enum(AeDealTypeEnum)])),
  vin: Type.Union([Type.String(), Type.Null()]),
  state: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  lender: Type.Optional(Type.Object({
    id: Type.Optional(Type.Number()),
    name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    phone: Type.Optional(Type.String()),
  })),
  transactionType: Type.String(),
  status: Type.Optional(Type.Enum(TitleEngineStatus)),
  aeStatusSeverity: Type.Optional(Type.Enum(StatusSeverity)),
  daysInStatus: Type.Number(),
  totalDays: Type.Number(),
  lienholder: Type.Object({
    id: Type.Number(),
    name: Type.Union([Type.String(), Type.Null()]),
    phone: Type.String()
  }),
  fundedDate: Type.Union([Type.String(), Type.Null()]),
  aeStatus: Type.Enum(AeLeadStatus),
  owner: Type.Optional(
    Type.Object({
      firstName: Type.Optional(Type.String()),
      middleName: Type.Optional(Type.String()),
      lastName: Type.Optional(Type.String()),
    })
  ),
  lastTouched: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  followUpDate: Type.Optional(Type.String()),
  // TODO - remove once ATS dashboard is completed
  assignee: Type.Optional(Type.String()),
  aeComments: Type.Array(AECommentsSchema),
  workedOnBy: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  isTopPriority: Type.Optional(Type.Boolean()),
  topPriorityType: Type.Optional(Type.Enum(TopPriorityType)),
  vendor: Type.Optional(Type.Union([Type.Enum(AeTitleVendorTypeEnum), Type.Null()])),
  vendorFee: Type.Optional(Type.Union([Type.Number(), Type.Null()]))
});

const TitlesEngineEscalationSchema = Type.Object({
  id: Type.Number(),
  titleOrderId: Type.Number(),
  ownerId: Type.String(),
  description: Type.String(),
  requestedAt: Type.String(),
  acceptedAt: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  resolvedAt: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  resolutionDescription: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  updateTimeStamp: Type.String(),
});
export type TitlesEngineEscalation = Static<typeof TitlesEngineEscalationSchema>;

const WithEscalationSchema = Type.Object({
  daysInFlagStatus: Type.Number(),
  stipulations: Type.Boolean(),
  flag: Type.String(),
  totalNumberOfHolds: Type.Number(),
  escalation: TitlesEngineEscalationSchema,
});

export function isDashboardTitleOrder(data: unknown): data is DashboardTitleOrder {
  return ajv.validate(DashboardTitleOrderSchema, data);
}

const CreateEstimateAddressSchema = Type.Object({
  street1: Type.String(),
  street2: Type.String(),
  city: Type.String(),
  state: Type.String(),
  zip: Type.String(),
});

export enum BorrowerRelationship {
  JOINT_AND_SPOUSAL = "JOINT_AND_SPOUSAL",
  COSIGNER_NON_SPOUSAL = "COSIGNER_NON_SPOUSAL",
  SPOUSE = "SPOUSE",
  PARTNER = "PARTNER",
  SIBLING = "SIBLING",
  PARENT = "PARENT",
  CHILD = "CHILD",
}

const CreateEstimateCustomerSchema = Type.Object({
  firstName: Type.String(),
  middleName: Type.Optional(Type.String()),
  lastName: Type.String(),
  birthday: Type.Optional(Type.String({ format: "date-time" })),
  phoneNumbers: Type.Array(Type.String()),
  email: Type.Optional(Type.String()),
  type: Type.Optional(Type.String()),
  ssn: Type.Optional(Type.String()),
  driversLicenseNum: Type.Optional(Type.String()),
  federalEmployerId: Type.Optional(Type.String()),
  address: CreateEstimateAddressSchema,
  relationshipToPrimary: Type.Optional(Type.Enum(BorrowerRelationship)),
});
export type CreateEstimateCustomer = Static<typeof CreateEstimateCustomerSchema>;

const CreateEstimateLienHolderSchema = Type.Object({
  name: Type.String(),
  lienDate: Type.Optional(Type.String({ format: "date-time" })),
  requestedDueDate: Type.Optional(Type.String({ format: "date-time" })),
  eltNum: Type.Optional(Type.String()),
  address: CreateEstimateAddressSchema,
});

export type CreateEstimateVehicle = Static<typeof CreateEstimateVehicleSchema>;
const CreateEstimateVehicleSchema = Type.Object({
  vin: Type.String(),
  year: Type.Optional(Type.Integer()),
  make: Type.Optional(Type.String()),
  model: Type.Optional(Type.String()),
  isTrailer: Type.Optional(Type.Boolean()),
  mileage: Type.Optional(Type.Integer()),
  dateOfMileage: Type.Optional(Type.String()),
  buyerSellerRelationship: Type.Optional(Type.String()),
  isGift: Type.Optional(Type.Boolean()),
  currentState: Type.Optional(Type.String()),
  leased: Type.Optional(Type.Boolean()),
  taxPaymentType: Type.Optional(Type.String()),
  titleOrMso: Type.Optional(Type.String()),
  color: Type.Optional(Type.String()),
  fuelType: Type.Optional(Type.String()),
  purchasePrice: Type.Optional(Type.Number()),
  purchaseDate: Type.Optional(Type.String({ format: "date-time" })),
  purchaseType: Type.Optional(Type.String()),
  grossWeightInPounds: Type.Optional(Type.Number()),
  emptyWeightInPounds: Type.Optional(Type.Number()),
  lengthInFeet: Type.Optional(Type.Number()),
  lengthOfTrailerInFeet: Type.Optional(Type.Number()),
  numberOfWheels: Type.Optional(Type.Integer()),
  taxExempt: Type.Optional(Type.Boolean()),
  taxExemptReason: Type.Optional(Type.String()),
  taxesPaidToDealer: Type.Optional(Type.Number()),
  tradeInAmount: Type.Optional(Type.Number()),
  additionalFees: Type.Optional(Type.Number()),
  mcNumberForIrpPlate: Type.Optional(Type.String()),
});

export type CreateEstimateTitleOrder = Static<typeof CreateEstimateTitleOrderSchema>;
const CreateEstimateTitleOrderSchema = Type.Object({
  aeRecordId: Type.Number(),
  aeLeadId: Type.Optional(Type.String()),
  transactionType: Type.Optional(Type.String()),
  primaryCustomer: Type.Optional(CreateEstimateCustomerSchema),
  secondaryCustomer: Type.Optional(CreateEstimateCustomerSchema),
  lienHolder: CreateEstimateLienHolderSchema,
  vehicle: CreateEstimateVehicleSchema,
  vendor: Type.Optional(Type.Union([Type.Null(), Type.Enum(AeTitleVendorTypeEnum)]))
});

export function isCreateEstimateTitleOrder(data: unknown): data is CreateEstimateTitleOrder {
  const valid = ajv.validate(CreateEstimateTitleOrderSchema, data);
  if (!valid) {
    console.log(`${JSON.stringify(ajv.errors)}`);
  }
  return valid;
}

export function isUIEstimateTemplate(data: unknown): data is UIEstimateTemplate {
  const valid = ajv.validate(UIEstimateTemplateSchema, data);
  if (!valid) {
    console.log(`${JSON.stringify(ajv.errors)}`);
  }
  return valid;
}

export type GetCountiesResponse = Static<typeof GetCountiesResponseSchema>;
const GetCountiesResponseSchema = Type.Array(Type.String());
export const isGetCountiesResponse = (input: unknown): input is GetCountiesResponse =>
  validateAgainstSchema(input, GetCountiesResponseSchema);

export type CreateEstimateUnknownField = Static<typeof CreateEstimateUnknownFieldSchema>;
export const CreateEstimateUnknownFieldSchema = Type.Object({
  group: Type.String(),
  teGroup: Type.String(),
  name: Type.String(),
  value: Type.String(),
  required: Type.Boolean(),
  type: Type.String(),
  validationRule: Type.Optional(Type.String()),
});

const SpecialInstructionsFieldSchema = Type.Object({
  name: Type.String(),
  value: Type.String(),
});
export const SpecialInstructionsSchema = Type.Object({
  text: Type.String(),
  fields: Type.Array(SpecialInstructionsFieldSchema),
});

export type TitleVendorCreateEstimateRequest = Static<typeof TitleVendorCreateEstimateRequestSchema>;
export const TitleVendorCreateEstimateRequestSchema = Type.Object({
  titleOrder: CreateEstimateTitleOrderSchema,
  specialInstructions: SpecialInstructionsSchema,
  unknownFields: Type.Array(CreateEstimateUnknownFieldSchema),
  estimateId: Type.Number(),
  excludedFieldGroup: Type.Object({
    secondaryBorrower: Type.Boolean(),
  }),
});

export type LeadsMetadataFieldValues = Static<typeof TeOrdersMetadataFieldValuesSchema>;

const TeOrdersMetadataFieldValuesSchema = Type.Object({
  since: Type.String(),
  count: Type.Number(),
});

export type LeadsMetaDataField = Static<typeof TeOrdersMetadataFieldSchema>;

const TeOrdersMetadataFieldSchema = Type.Object({
  total: TeOrdersMetadataFieldValuesSchema,
  delta: TeOrdersMetadataFieldValuesSchema,
});

export type TeOrdersMetadata = Static<typeof TeOrdersMetadataSchema>;

const TeOrdersMetadataSchema = Type.Object({
  submitted: TeOrdersMetadataFieldSchema,
  onHold: TeOrdersMetadataFieldSchema,
  resolvedHolds: TeOrdersMetadataFieldSchema,
  pendingTitlePacket: TeOrdersMetadataFieldSchema,
  sentToJurisdiction: TeOrdersMetadataFieldSchema,
});

export type AssociateTitleSpecialistDashboardData = Static<typeof AssociateTitleSpecialistDashboardDataSchema>;

const AssociateTitleSpecialistDashboardDataSchema = Type.Object({
  titleOrders: Type.Array(DashboardTitleOrderSchema),
  countInDb: Type.Number(),
  countInDbWithFilters: Type.Number(),
});

export type TitleOrderDashboardData = Static<typeof TitleOrderDashboardDataSchema>;

const TitleOrderDashboardDataSchema = Type.Object({
  titleOrders: Type.Array(DashboardTitleOrderSchema),
  countInDb: Type.Number(),
  countInDbWithFilters: Type.Number(),
  metadata: TeOrdersMetadataSchema,
});

export type TitleOrderEscalationDashboardData = Static<typeof TitleOrderEscalationDashboardDataSchema>;

const TitleOrderEscalationDashboardDataSchema = Type.Object({
  countInDb: Type.Number(),
  titleOrders: Type.Array(Type.Intersect([DashboardTitleOrderSchema, WithEscalationSchema])),
});

export function isTitleOrderDashboardData(response: unknown): response is TitleOrderDashboardData {
  const validate = ajv.compile(TitleOrderDashboardDataSchema);

  const valid = validate(response);

  if (!valid) {
    validate.errors?.map((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return valid;
}

export function isTitleOrderEscalationDashboardData(response: unknown): response is TitleOrderEscalationDashboardData {
  const validate = ajv.compile(TitleOrderEscalationDashboardDataSchema);

  const valid = validate(response);

  if (!valid) {
    validate.errors?.map((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return valid;
}

export function isTitlesEngineEscalation(response: unknown): response is TitlesEngineEscalation {
  const validate = ajv.compile(TitlesEngineEscalationSchema);

  const valid = validate(response);

  if (!valid) {
    validate.errors?.map((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return valid;
}

export function isAssociateTitleSpecialistDashboardData(
  response: unknown
): response is AssociateTitleSpecialistDashboardData {
  const validate = ajv.compile(AssociateTitleSpecialistDashboardDataSchema);

  const valid = validate(response);

  if (!valid) {
    validate.errors?.map((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return valid;
}

export enum TaskGroupType {
  CUSTOMER_CORE = "CUSTOMER_CORE",
  CUSTOMER_REMINDER = "CUSTOMER_REMINDER",
  CUSTOMER_URGENT_REMINDER = "CUSTOMER_URGENT_REMINDER",
  LIENHOLDER_CORE = "LIENHOLDER_CORE",
  LIENHOLDER_REMINDER = "LIENHOLDER_REMINDER",
}

export type TaskGroup = {
  type: TaskGroupType;
  description: string;
};

export type TitleOrderTask = {
  id: number;
  historyEventId?: number;
  completed: boolean;
  precedence: number;
  label: string;
  instructions: string[];
  updatedAt: DateTime;
};

export type TitleOrderTasks = {
  group: TaskGroup;
  tasks: TitleOrderTask[];
};

export type TitleOrderTasksResponse = Static<typeof TitleOrderTasksResponseSchema>;
export const TitleOrderTasksResponseSchema = Type.Object({
  group: Type.Object({
    type: Type.Enum(TaskGroupType),
    description: Type.String(),
  }),
  tasks: Type.Array(
    Type.Object({
      id: Type.Number(),
      historyEventId: Type.Optional(Type.Number()),
      completed: Type.Boolean(),
      precedence: Type.Number(),
      label: Type.String(),
      instructions: Type.Optional(
        Type.Union([
          Type.Array(Type.String()), // API should send as string array. Other types are for backwards compatibility
          Type.Null(),
          Type.String(),
        ])
      ),
      updatedAt: Type.String(),
    })
  ),
});

export function isTitleOrderTasksResponse(input: unknown): input is TitleOrderTasksResponse {
  const validate = ajv.compile(TitleOrderTasksResponseSchema);

  const valid = validate(input);

  if (!valid) {
    validate.errors?.map((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return valid;
}

export type UpdateTitleOrderTaskPostRequest =
  | {
      titleOrderTaskId: number;
      completed: true;
    }
  | {
      titleOrderTaskId: number;
      completed: false;
      changeReason: string;
    };

export type UpdateTitleOrderTaskResponseWithMetaData =
  | (UpdateTitleOrderTaskResponse & { updated: true })
  | { updated: false };
export type UpdateTitleOrderTaskResponse = Static<typeof UpdateTitleOrderTaskResponseSchema>;
export const UpdateTitleOrderTaskResponseSchema = Type.Object({
  event: Type.Object({
    id: Type.Integer(),
    completed: Type.Boolean(),
    createdAt: Type.String(),
    changeReason: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    titleOrderTaskId: Type.Integer(),
  }),
  invalidatedEta: Type.Optional(Type.Boolean()),
});
export const isUpdateTitleOrderTaskResponse = (input: unknown): input is UpdateTitleOrderTaskResponse =>
  validateAgainstSchema(input, UpdateTitleOrderTaskResponseSchema);

export type CreateTitleOrderTaskDocsEvent =
  | CreateTitleOrderTaskDocsReceivedEventPostRequest
  | CreateTitleOrderTaskReopenTasksEventPostRequest;
export type CreateTitleOrderTaskDocsReceivedEventPostRequest = {
  docsReceived: true;
  titleOrderId: number;
  userId: number;
  taskGroupType: TaskGroupType;
};
export type CreateTitleOrderTaskReopenTasksEventPostRequest = Omit<
  CreateTitleOrderTaskDocsReceivedEventPostRequest,
  "docsReceived"
> & {
  docsReceived: false;
  changeReason: string;
};

export type GetTitleOrderTaskDocsReceivedEventRequest = {
  titleOrderId: number;
  taskGroupType: TaskGroupType;
};

export const TitleOrderTaskDocsEventReceivedSchema = Type.Object({
  id: Type.Integer(),
  receivedAt: Type.String(),
  taskGroupType: Type.Enum(TaskGroupType),
  titleOrderId: Type.Integer(),
  userId: Type.Integer(),
  createdAt: Type.String(),
  updatedAt: Type.String(),
});

export type TitleOrderTaskDocsEventReceivedResponse = Static<typeof TitleOrderTaskDocsEventReceivedResponseSchema>;
export const TitleOrderTaskDocsEventReceivedResponseSchema = Type.Object({
  event: TitleOrderTaskDocsEventReceivedSchema,
});
export const isTitleOrderTaskDocsEventReceivedResponse = (
  input: unknown
): input is TitleOrderTaskDocsEventReceivedResponse =>
  validateAgainstSchema(input, TitleOrderTaskDocsEventReceivedResponseSchema);

export const TitleOrderTaskDocsEventReopenTasks = Type.Object({
  id: Type.Integer(),
  taskGroupType: Type.Enum(TaskGroupType),
  titleOrderId: Type.Integer(),
  userId: Type.Integer(),
  createdAt: Type.String(),
  updatedAt: Type.String(),
  changeReason: Type.String(),
});
export type TitleOrderTaskDocsEventReopenTasksResponse = Static<
  typeof TitleOrderTaskDocsEventReopenTasksResponseSchema
>;
export const TitleOrderTaskDocsEventReopenTasksResponseSchema = Type.Object({
  event: TitleOrderTaskDocsEventReopenTasks,
});
export const isTitleOrderTaskDocsEventReopenTasksResponse = (
  input: unknown
): input is TitleOrderTaskDocsEventReopenTasksResponse =>
  validateAgainstSchema(input, TitleOrderTaskDocsEventReopenTasksResponseSchema);

export type GetTitleOrderTaskDocsEventResponse = Static<typeof GetTitleOrderTaskDocsEventResponseSchema>;
export const isGetTitleOrderTaskDocsEventResponse = (input: unknown): input is GetTitleOrderTaskDocsEventResponse =>
  validateAgainstSchema(input, GetTitleOrderTaskDocsEventResponseSchema);

export const GetTitleOrderTaskDocsEventResponseSchema = Type.Union([
  Type.Object({
    event: Type.Optional(TitleOrderTaskDocsEventReceivedSchema),
  }),
  Type.Object({
    event: Type.Optional(TitleOrderTaskDocsEventReopenTasks),
  }),
]);

export type TaskNote = Static<typeof TaskNoteSchema>;
export const TaskNoteSchema = Type.Object({
  id: Type.Number(),
  text: Type.String(),
  titleOrderId: Type.Number(),
  userId: Type.Number(),
  createdAt: Type.String({ format: "date-time" }),
  updatedAt: Type.String({ format: "date-time" }),
  user: Type.Object({
    firstName: Type.String(),
    lastName: Type.String(),
  }),
});
export type TitleOrderTaskNotesResponse = Static<typeof TitleOrderTaskNotesResponseSchema>;
export const TitleOrderTaskNotesResponseSchema = Type.Object({
  notes: Type.Array(TaskNoteSchema),
});
export const isTitleOrderTaskNotesResponse = (input: unknown): input is TitleOrderTaskNotesResponse =>
  validateAgainstSchema(input, TitleOrderTaskNotesResponseSchema);

export type CreateTitleOrderTaskNoteRequest = {
  text: string;
  titleOrderId: number;
  user: User;
};
export type TaskNoteCreateResponse = Static<typeof TitleOrderTaskNoteCreateResponseSchema>;
export const TitleOrderTaskNoteCreateResponseSchema = Type.Object({
  note: TaskNoteSchema,
});
export const isTaskNoteCreateResponseSchema = (input: unknown): input is TaskNoteCreateResponse =>
  validateAgainstSchema(input, TitleOrderTaskNoteCreateResponseSchema);

export type CreateTitleOrderDocEtaPostRequest = {
  date: DateTime;
  type: TitleOrderEtaType;
  titleOrderTaskId: number;
};

export type GetTitleOrderDocEtaQuery = {
  association: "TASK_HISTORY_EVENT";
  titleOrderTaskHistoryId: number;
};

export type GetAllTitleOrderDocEtasQuery = {
  association: "TITLE_ORDER";
  titleOrderId: number;
};

export enum Status {
  APPROVED = "approved",
  PENDING = "pending",
  REVIEWED = "reviewed",
  REJECTED = "rejected",
  MISSING = "missing",
}

export enum NotificationStatus {
  UNREAD = "UNREAD",
  READ = "READ",
  DISMISSED = "DISMISSED",
}

export type NotificationEvent = Static<typeof NotificationEventSchema>;
export const NotificationEventSchema = Type.Object({
  id: Type.Integer(),
  eventDate: Type.String(),
  status: Type.Enum(NotificationStatus),
  data: Type.String(),
});

export type GetNotificationEventResponse = Static<typeof GetNotificationEventResponseSchema>;
export const GetNotificationEventResponseSchema = Type.Array(NotificationEventSchema);

export const isGetNotificationEventResponse = (input: unknown): input is GetNotificationEventResponse =>
  validateAgainstSchema(input, GetNotificationEventResponseSchema);

export type MarkAllNotificationEventsAsReadResponse = Static<typeof MarkAllNotificationEventsAsReadResponseSchema>;
export const MarkAllNotificationEventsAsReadResponseSchema = Type.Object({
  notificationEventsUpdated: Type.Boolean(),
});
export const isMarkAllNotificationEventsAsReadResponse = (
  input: unknown
): input is MarkAllNotificationEventsAsReadResponse =>
  validateAgainstSchema(input, MarkAllNotificationEventsAsReadResponseSchema);

export type TitleOrderStipulation = Static<typeof TitleOrderStipulationSchema>;
export const TitleOrderStipulationSchema = Type.Object({
  id: Type.Number(),
  titleOrderId: Type.Number(),
  documentType: Type.String(),
  createdAt: Type.String(),
  updatedAt: Type.String(),
});

export type CreateStipulationsResponse = Static<typeof CreateStipulationsResponseSchema>;
export const CreateStipulationsResponseSchema = Type.Object({
  stipulations: Type.Array(TitleOrderStipulationSchema),
});
export const isCreateStipulationsResponse = (input: unknown): input is CreateStipulationsResponse =>
  validateAgainstSchema(input, CreateStipulationsResponseSchema);

export type DocumentTypesResponse = Static<typeof DocumentTypesSchema>;
export const DocumentTypesSchema = Type.Array(
  Type.Object({
    id: Type.Number(),
    documentType: Type.String(),
  })
);
export const isDocumentTypesResponse = (input: unknown): input is DocumentTypesResponse =>
  validateAgainstSchema(input, DocumentTypesSchema);

export type TaskContact = "customer" | "lienholder";

export type TitleOrderFilter = { column: string; value: string };

export type TitleOrderSortOrder = {
  column:
    | string // should not include generic `string` but this is coming from browser storage ATM
    | "fundedDate"
    | "lenderName"
    | "lenderPhone"
    | "lienholderName"
    | "lienholderPhone"
    | "vin"
    | "state"
    | "daysinstatus"
    | "totaldays"
    | "recordid"
    | "assignee"
    | "customerName"
    | "customerBirthday"
    | "customerPhone"
    | "followUpDate";
  order: string; // should be `"asc" | "desc"` but this is coming from browser storage ATM
};

export type ExcludedFieldGroup = {
  secondaryBorrower: boolean;
};

export type GetTitleOrdersArgs = {
  token: string;
  query: {
    titleSpecialistFilter?: string;
    searchString?: string;
    columnFilters?: Array<TitleOrderFilter>;
    columnSortOrders?: Array<TitleOrderSortOrder>;
    showCompleted?: boolean;
    //Grouping for manager will be handled in TE-1027.
    //totalDaysGroupings?: Array<TitleOrderTotalDaysGrouping>;
    take?: "all" | number;
    skip?: number; // non-negative integer
  };
};

export type UserInfo = {
  id: number
  aeUserId: string,
  email: string,
  firstName: string,
  lastName: string,
  role: AeRole
};

export type GetTitleOrderQuery = {
  id?: string,
  aeLeadId?: string,
  aeRecordId?: string,
  // converted to string when parameterized
  refresh?: boolean
};
