import { DashboardTitleOrder } from "../../networking/core/types";
import { GridFilterItem, GridFilterModel, GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { isArray } from "lodash";
import { Type } from "@sinclair/typebox";

export type DashboardRow = {
  id: string;
  isHovered: boolean;
  etaSource?: string;
} & DashboardTitleOrder;

export type AdditionalDashboardColumn =
  "customerName"
  | "customerPhone"
  | "lienholderName"
  | "lienholderPhone"
  | "customerPhone"
  | "customerBirthday"
  | "lenderName"
  | "lenderPhone";

export function convertTitleOrderToDashboardRow(order: DashboardTitleOrder): DashboardRow {
  return {
    ...order,
    id: order.aeLeadId,
    isHovered: false,
  };
}

export type ColumnFilter = {
  value: string;
  columnName: string;
};

export const ColumnFilterSchema = Type.Object({
  value: Type.String(),
  columnName: Type.String(),
});

export type SortOption = {
  sortBy: string;
  sortOrder: "asc" | "desc";
};

export function toSortOptions(gridSortModel: GridSortModel): Array<SortOption> {
  return gridSortModel
    .filter((item): item is GridSortItem & { sort: "asc" | "desc" } => {
      return item.sort === "asc" || item.sort === "desc";
    })
    .map((item) => ({
      sortBy: item.field,
      sortOrder: item.sort,
    }));
}

function toGridSortItem(sortOption: SortOption): GridSortItem {
  return {
    field: sortOption.sortBy,
    sort: sortOption.sortOrder,
  };
}

export function toGridSortModel(sortOptions: Array<SortOption> | SortOption | undefined): GridSortModel {
  if (sortOptions === undefined) return [];

  if (isArray(sortOptions)) {
    return sortOptions.map(toGridSortItem);
  }

  return [toGridSortItem(sortOptions)];
}

export function toColumnFilters(gridFilterModel: GridFilterModel): Array<ColumnFilter> {
  return gridFilterModel.items.flatMap((item) => {
    if (item.operatorValue === "contains" && typeof item.value === "string") {
      return { columnName: item.columnField, value: item.value };
    } else if (item.operatorValue === "isAnyOf" && Array.isArray(item.value)) {
      return item.value.flatMap((val) => ({
        columnName: item.columnField,
        value: val,
      }));
    } else {
      return [];
    }
  });
}

function toGridFilterItem(filterOption: ColumnFilter): GridFilterItem {
  return {
    value: filterOption.value,
    columnField: filterOption.columnName,
    operatorValue: "isAnyOf", //Todo - TE-1146 - ADD "operatorValue" to ColumnFilter type
  };
}

export function toGridFilterModel(filterOptions: Array<ColumnFilter> | ColumnFilter | undefined): GridFilterModel {
  if (filterOptions === undefined) return { items: [] };

  if (isArray(filterOptions)) {
    return { items: filterOptions.map(toGridFilterItem) };
  }

  return { items: [toGridFilterItem(filterOptions)] };
}
