import { createContext, ReactNode, useContext, useState } from "react";
import { useOperations } from "../hook/useOperations";
import { SharedDashboardContextType } from "./types";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- always create context with a null
const SharedDashboardContext = createContext<SharedDashboardContextType>(null!);

export function useSharedDashboard(): SharedDashboardContextType {
  return useContext(SharedDashboardContext);
}

export function SharedDashboardProvider({ children }: { children: ReactNode }) {
  const { searchTerm, setSearchTerm, dashboardUpdate, setDashboardUpdate } = useOperations();
  const [page, setPage] = useState(0);

  function refreshDashboard() {
    setDashboardUpdate(dashboardUpdate + 1);
  }

  const value = {
    searchTerm,
    setSearchTerm,
    refreshDashboard,
    dashboardUpdate,
    page,
    setPage,
  };

  return <SharedDashboardContext.Provider value={value}>{children}</SharedDashboardContext.Provider>;
}
