import { Static, Type } from "@sinclair/typebox";
import { validateAgainstSchema } from "../core/schemaValidation";

export type UserToken = Static<typeof UserTokenSchema>;
export const UserTokenSchema = Type.Object({
  token: Type.Object({
    access_token: Type.String(),
    expires_in: Type.Number(),
    role: Type.String(),
    is_manager: Type.Boolean(),
    permissions: Type.Array(Type.String()),
  }),
  user: Type.Object({
    id: Type.Number(),
    aeUserId: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    email: Type.String(),
    firstName: Type.String(),
    lastName: Type.String(),
    role: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    isActive: Type.Boolean(),
  })
});
export const isUserToken = (input: unknown): input is UserToken => validateAgainstSchema(input, UserTokenSchema);