import { TextareaAutosize, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import { getCopy } from "../../../../authoring/copy";
import { useAuth } from "../../../Auth/AuthProvider";
import { BaseModal } from "../../../shared/BaseModal/BaseModal";
import { CustomButton } from "../Button";

const { reOpenTasksModal } = getCopy().associateTitleSpecialistDashboard.taskPanel;

export function AreYouSureModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (changeReason: string) => Promise<unknown>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<keyof typeof reOpenTasksModal>("areYouSure");
  const [changeReason, setChangeReason] = useState("");
  const { user } = useAuth();

  return (
    <BaseModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={reOpenTasksModal[step].heading}
      actions={[
        <CustomButton
          key={reOpenTasksModal[step].cancelButton.label}
          variant="text"
          sx={{ px: 4, fontWeight: "normal", mr: 1, color: "#767676" }}
          onClick={props.onClose}
          disabled={loading}
        >
          {reOpenTasksModal[step].cancelButton.label}
        </CustomButton>,
        <CustomButton
          variant="outlined"
          key={reOpenTasksModal[step].continueButton.label}
          sx={{ px: 4 }}
          onClick={() => (step === "areYouSure" ? setStep("changeReason") : handleSubmit(changeReason))}
          disabled={loading || (step === "changeReason" && isEmpty(changeReason))}
        >
          {loading ? reOpenTasksModal[step].continueButton.loadingLabel : reOpenTasksModal[step].continueButton.label}
        </CustomButton>,
      ]}
    >
      {step === "areYouSure" ? (
        <Typography
          fontSize={16}
          className="text-sm text-gray-800"
          id="docs-received-task-modal-explanation"
          variant="body1"
          paragraph={true}
        >
          {reOpenTasksModal.areYouSure.additionalInformation}
        </Typography>
      ) : (
        <TextareaAutosize
          value={changeReason}
          onChange={(event) => setChangeReason(event.target.value)}
          className="w-full outline-none resize-none"
          minRows={4}
          placeholder={reOpenTasksModal.changeReason.textArea.placeHolder}
        />
      )}
    </BaseModal>
  );

  async function handleSubmit(changeReason: string) {
    if (user?.token) {
      setLoading(true);
      props
        .onSubmit(changeReason)
        .then(() => {
          props.onClose();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          alert(`Failed to save document status. Error: ${err}`);
        });
    }
  }
}
