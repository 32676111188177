import { now } from "../../contexts/time/now";
import { decode } from "jsonwebtoken";
import { getUserToken } from "../../networking/auth/TitlesWorkflowAuthApi";
import { UserToken } from "../../networking/auth/types";
import { PermissionAction, Role, mapAeRoleToRole } from "@auto-approve/auto-approve-ui-library";
import { estimateRoles } from "../../App";

export type User = {
  username: string;
  token: string;
  expirationTime: string;
  role?: Role;
  userId: string;
  email: string,
  firstName: string,
  lastName: string,
  teUserId: number;
  permissions: PermissionAction[];
};

export enum AuthOrigin {
  TitlesEngine = "TitlesEngine",
  ActiveDirectory = "ActiveDirectory",
}

export function calculateExpirationTime(expires_in: number) {
  return now().plus({ seconds: expires_in }).toISO();
}

export async function loginUser(username: string, password: string): Promise<User> {
  try {
    const res = await getUserToken(username, password);

    return convertUserTokenToUser(username, res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export function convertUserTokenToUser(username: string | undefined, userToken: UserToken): User {
  const decodedToken = decode(userToken.token.access_token, { complete: true });
  const userId = decodedToken?.payload?.Id;
  // TODO - move to backend or refactor
  const decodedUsername = decodedToken?.payload?.Username ?? "";
  return {
    username: username ?? decodedUsername,
    token: userToken.token.access_token,
    expirationTime: calculateExpirationTime(userToken.token.expires_in),
    role: mapAeRoleToRole(userToken.token.role),
    userId: userId,
    teUserId: userToken.user.id,
    email: userToken.user.email,
    lastName: userToken.user.lastName,
    firstName: userToken.user.firstName,
    permissions: userToken.token.permissions as PermissionAction[],
  };
}

export function isManager(user: User): boolean {
  return user.role === Role.MANAGER;
}

export function isTitleSpecialist(user: User): boolean {
  return user.role === Role.TITLE_SPECIALIST;
}

export function isAssociateTitleSpecialist(user: User): boolean {
  return user.role === Role.ASSOCIATE_TITLE_SPECIALIST;
}

export function isManagerOrTitleSpecialist(user: User): boolean {
  return user.role === Role.TITLE_SPECIALIST || user.role === Role.MANAGER;
}

export function isLoanConsultant(user: User): boolean {
  return user.role === Role.LOAN_CONSULTANT;
}

export function isLoanSupportSpecialist(user: User): boolean {
  return user.role === Role.LOAN_SUPPORT_SPECIALIST || user.role === Role.SENIOR_LOAN_SUPPORT_SPECIALIST;
}

// TODO - move to permissions
export const canCreateEstimate = (user: User | null) => {
  if (!user?.role) return false;
  return estimateRoles.includes(user.role);
};