import { createContext, ReactNode, useContext, useState } from "react";
import { useOperations } from "../hook/useOperations";
import { EscalationsDashboardContextType } from "./types";
import { DashboardTitleOrder } from "../../../networking/core/types";
import { OrderWithEscalation } from "../EscalationsDashboard/types";
import { useEscalationsDashboardFilter, useEscalationsDashboardSort } from "../../../Hooks/GridCollation";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- always create context with a null
const EscalationsDashboardContext = createContext<EscalationsDashboardContextType>(null!);

export function useEscalationsDashboard(): EscalationsDashboardContextType {
  return useContext(EscalationsDashboardContext);
}

export function EscalationsDashboardProvider({ children }: { children: ReactNode }) {
  const { dashboardRows, setDashboardRows, setDashboardUpdate, loading, setLoading } = useOperations();
  const [allRows, setAllRows] = useState<OrderWithEscalation[]>([]);
  const [filterModel, setFilterModel] = useEscalationsDashboardFilter();
  const [sortOptions, setSortOptions] = useEscalationsDashboardSort();
  const [showCompleted, setShowCompleted] = useState(false);

  function removeTitleOrderAfterResolution(titleOrderRow: DashboardTitleOrder) {
    const newRows = allRows.filter((row) => row.aeLeadId !== titleOrderRow.aeLeadId);
    setAllRows(newRows);
  }

  const value = {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    setDashboardUpdate,
    filterModel,
    setFilterModel,
    sortOptions,
    setSortOptions,
    loading,
    setLoading,
    removeTitleOrderAfterResolution,
    showCompleted,
    setShowCompleted,
  };

  return <EscalationsDashboardContext.Provider value={value}>{children}</EscalationsDashboardContext.Provider>;
}
