import { UiEstimateTemplateField, TitleOrderField } from "../types";
import { DynamicFieldGroup } from "./DynamicFieldGroup";
import { CreateEstimateTitleOrder, ExcludedFieldGroup } from "../../../../../networking/core/types";

export function DynamicFields(props: {
  knownFields: TitleOrderField[];
  unknownFields: UiEstimateTemplateField[];
  titleOrder: CreateEstimateTitleOrder | undefined;
  setExcludedFieldGroup: (value: ExcludedFieldGroup) => void;
}) {
  const groups = getFieldGroups(props.knownFields, props.unknownFields);
  return (
    <>
      {groups.map((group, index) => {
        return (
          <DynamicFieldGroup
            group={group}
            key={index}
            knownFields={props.knownFields}
            unknownFields={props.unknownFields}
            titleOrder={props.titleOrder}
            setExcludedFieldGroup={props.setExcludedFieldGroup}
          />
        );
      })}
    </>
  );
}

function getFieldGroups(knownFields: TitleOrderField[], unknownFields: UiEstimateTemplateField[]) {
  const knownGroupNames = knownFields.map((field) => field.estimateTemplateField.teGroup);
  const unknownGroupNames = unknownFields.map((field) => field.teGroup);
  const groupNames = [...new Set([...knownGroupNames, ...unknownGroupNames])];
  return groupNames
    .map((name) => ({
      groupName: name,
      knownFields: knownFields.filter((field) => field.estimateTemplateField.teGroup === name),
      unknownFields: unknownFields.filter((field) => field.teGroup === name),
    }))
    .sort((a, b) => {
      if (a.groupName === "Estimate Special Instructions") {
        return 1;
      }

      const groupNameA = a.groupName.toUpperCase();
      const groupNameB = b.groupName.toUpperCase();
      return groupNameA < groupNameB ? -1 : groupNameA > groupNameB ? 1 : 0;
    });
}
