import { TSchema } from "@sinclair/typebox";
import addFormat from "ajv-formats";
import Ajv from "ajv/dist/2020";

export const ajv = addFormat(new Ajv({}), ["date-time"]).addKeyword("kind").addKeyword("modifier");

export function validateAgainstSchema(input: unknown, schema: TSchema): boolean {
  const validate = ajv.compile(schema);
  const valid = validate(input);

  if (!valid) {
    validate.errors?.map((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return valid;
}
