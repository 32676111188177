import axios, { AxiosRequestConfig } from "axios";
import { environmentVariables } from "../../config";

export async function createElectronicSignatureEnvelopeView(leadId: string, documentIds: string[], signal: AbortSignal, token: string) {
  const url = `${environmentVariables.titlesWorkflowApi}/electronicsignature/envelope-view`;
  const config: AxiosRequestConfig = {
    signal,
    headers: {
      Authorization: token,
    }
  };

  const body = {
    leadId,
    documentIds,
  };
  return await axios.post(url, body, config)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return Promise.reject(error.response.data);
  });

}