/**
 * Makes the form invalid initially, but without any actual errors displayed.
 *
 * Usage:
 * ```typescript
 *   useFormik({
 *     // ...
 *     initialErrors: noInitialErrorsButInvalid(),
 *   })
 * ```
 */
export function noInitialErrorsButInvalid(): object {
  // Formik will see that this error object has >0 properties and mark the form as invalid.
  // But, none of the fields names of the form are in the error object, so no errors are shown.
  return { "": undefined };
}
