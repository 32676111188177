import { DateTime } from "luxon";
import {
  TitleOrderDocEtaResponse,
  TitleOrderDocEta,
  TitleOrderTask,
  TitleOrderTasksResponse,
  CreateEstimateUnknownField,
  ExcludedFieldGroup,
} from "./types";
import { pick } from "lodash";
import {
  UiEstimateTemplateField,
  SpecialInstructions,
} from "../../components/CreateEstimate/steps/submitEstimate/types";
import { getCopy } from "../../authoring/copy";

export function toTitleOrderTaskFromResponse(
  titleOrderTaskResponse: TitleOrderTasksResponse["tasks"][number]
): TitleOrderTask {
  return {
    ...pick(titleOrderTaskResponse, ["id", "completed", "precedence", "label"]),
    updatedAt: DateTime.fromISO(titleOrderTaskResponse.updatedAt),
    instructions: toInstructionsFromTitleOrderTaskResponse(titleOrderTaskResponse),
  };
}

function toInstructionsFromTitleOrderTaskResponse({
  instructions,
}: TitleOrderTasksResponse["tasks"][number]): TitleOrderTask["instructions"] {
  if (instructions === null || instructions === undefined) {
    return [];
  } else if (typeof instructions === "string") {
    return [instructions];
  } else {
    return instructions;
  }
}

export function titleOrderDocEtaResponseToTitleOrderDocEta(
  titleOrderDocEtaResponse: TitleOrderDocEtaResponse
): TitleOrderDocEta {
  return {
    ...pick(titleOrderDocEtaResponse, ["id", "type", "titleOrderId", "titleOrderTaskId", "titleOrderTaskHistoryId"]),
    date: DateTime.fromISO(titleOrderDocEtaResponse.date),
  };
}

export const titleOrderTaskResponse = {
  toTitleOrderTask: toTitleOrderTaskFromResponse,
};

export const titleOrderDocEtaResponse = {
  toTitleOrderDocEta: titleOrderDocEtaResponseToTitleOrderDocEta,
};

export const specialInstructionsConverter = (data: SpecialInstructions) => {
  return {
    // AAT-524: WKLS API does not supported new line characters
    text: data.text.replaceAll("\n", " | "),
    fields: Array.from(data.fields.keys()).map((key) => ({
      name: key,
      value: data.fields.get(key) ?? "",
    })),
  };
};

export const unknownFieldsConverter = (data: UiEstimateTemplateField[]): CreateEstimateUnknownField[] => {
  return data.map((field) => {
    return {
      group: field.group,
      teGroup: field.teGroup,
      name: field.name,
      value: field.value ?? "",
      required: field.required,
      type: field.fieldInfo.type,
      validationRule: field.regex,
    };
  });
};

export function excludedFieldGroupToGroupNames(excludedGroup: ExcludedFieldGroup): string[] {
  if (excludedGroup.secondaryBorrower) {
    return [getCopy().createEstimate.submitEstimate.borrower.secondaryGroupName];
  }
  return [];
}