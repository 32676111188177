import { IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

type SearchProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler;
  clearSearch: () => void;
};

export function Search({ value, onChange, clearSearch, onKeyPress }: SearchProps) {
  return (
    <TextField
      style={{ height: "42px" }}
      variant="standard"
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" sx={{ marginX: "8px" }} />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: value ? "visible" : "hidden" }}
            onClick={clearSearch}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
        disableUnderline: true,
      }}
      sx={{
        border: "1px solid #C8C8C8",
        outline: "none",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center"
      }}
    />
  );
}
