import { createContext, ReactNode, useContext, useState } from "react";
import { useOperations } from "../hook/useOperations";
import { TitleSpecialistDashboardForManagerContextType } from "./types";
import {
  useTitleSpecialistDashboardFilter,
  useTitleSpecialistDashboardSort,
} from "../../../Hooks/GridCollation";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- always create context with a null
const TitleSpecialistDashboardForManagerContext = createContext<TitleSpecialistDashboardForManagerContextType>(null!);

export function useTitleSpecialistDashboardForManager(): TitleSpecialistDashboardForManagerContextType {
  return useContext(TitleSpecialistDashboardForManagerContext);
}

export function TitleSpecialistDashboardForManagerProvider({ children }: { children: ReactNode }) {
  const { allRows, setAllRows, dashboardRows, setDashboardRows, setDashboardUpdate, loading, setLoading } =
    useOperations();
  const [filterModel, setFilterModel] = useTitleSpecialistDashboardFilter();
  const [sortOptions, setSortOptions] = useTitleSpecialistDashboardSort();
  const [showCompleted, setShowCompleted] = useState(false);

  const value = {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    setDashboardUpdate,
    filterModel,
    setFilterModel,
    sortOptions,
    setSortOptions,
    loading,
    setLoading,
    showCompleted,
    setShowCompleted,
  };

  return (
    <TitleSpecialistDashboardForManagerContext.Provider value={value}>
      {children}
    </TitleSpecialistDashboardForManagerContext.Provider>
  );
}
