import CircleIcon from "@mui/icons-material/Circle";

type EscalationIndicatorProps = {
  acceptedAt: string;
};

export function EscalationIndicator(props: EscalationIndicatorProps) {
  if (props.acceptedAt) return null;

  return <CircleIcon fontSize="inherit" htmlColor="#5132A2" data-testid="escalation-indicator" />;
}
