import { getCopy } from "../../../authoring/copy";
import {
  getGridStringOperators,
  GridCellParams,
  GridColumns,
  GridComparatorFn,
  GridEnrichedColDef,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid";
import { AdditionalDashboardColumn, DashboardRow, SortOption } from "../types";
import { formatAeDealType, formatDate, formatDaysInStatus, formatLastTouchedDate, formatState, formatTotalDays, severityToDisplayLabel } from "../shared/FieldFormats";
import { StatusSeverityChip } from "../shared/StatusSeverity";
import { TitleSpecialistDashboardLinkCell } from "../shared/LinkCell/TitleSpecialistDashboardLinkCell";
import { LinkCell } from "../../shared/Tables/LinkCell";
import { Chip, IconButton, Snackbar, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { StatusSeverity } from "../../../networking/core/types";
import { ContentCopy } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { GridStateColDef } from "@mui/x-data-grid-premium";
import { formatAeLeadStatus } from "@auto-approve/auto-approve-ui-library";
import { formatDateWithoutTimestamp } from "../helpers";

const { columnLabels, severities } = getCopy().titleOrdersDashboard;
const { columnLabels: atsMyQueueLabels } = getCopy().associateTitleSpecialistMyQueue;

export function restrictAndOverrideFilterOperations<TOperator extends GridFilterOperator>(
  gridStringOperators: TOperator[],
  allowed: string[] = ["contains", "isAnyOf"]
): TOperator[] {
  return overrideStringFilterOperations(gridStringOperators).filter((operator) => allowed.includes(operator.value));
}

export function overrideStringFilterOperations<TOperator extends GridFilterOperator>(
  gridStringOperators: TOperator[]
): TOperator[] {
  return gridStringOperators.map((operator) => {
    if (operator.value === "isAnyOf") {
      return { ...operator, getApplyFilterFn: customIsAnyOfFilter };
    }
    return operator;
  });
}

export function customIsAnyOfFilter(filterItem: GridFilterItem) {
  return (params: GridCellParams) => {
    if (Array.isArray(filterItem.value)) {
      if (!filterItem.value || filterItem.value.length === 0) {
        return true;
      } else {
        return filterItem.value.some((value) => {
          return params.value.toString().toLowerCase().includes(value.toString().toLowerCase());
        });
      }
    }
    return true;
  };
}

const getTooltipText = (text: unknown, width?: number): string => {
  return width && ((`${text ?? ""}`).length * 7 + 20) > width ? `${text ?? ""}` : "";
};

export const CellTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "#200a52",
    fontWeight: 400,
    fontSize: 14,
    border: "1px solid #dadde9",
  },
}));

export const TransparentTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#200a52",
    fontWeight: 400,
    fontSize: 14,
    background: "none"
  },
}));


type CopyToClipboardButtonProps = {
  value: string | number;
}
export const CopyToClipboardButton = ({ value }: CopyToClipboardButtonProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(`${value}`);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <ContentCopy fontSize="small" />
      </IconButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={1000}
        message="Copied to clipboard"
      />
    </>
  );
};

type DefaultCellProps = {
  colDef: GridStateColDef,
  children: ReactNode,
  enableCopy?: boolean,
}
const DefaultCell = ({ children, enableCopy, colDef }: DefaultCellProps) => {
  const copy = enableCopy && children ? <CopyToClipboardButton value={`${children}`} /> : "";
  const tooltip = getTooltipText(children, colDef.computedWidth);

  return <CellTooltip
    placement="right"
    title={tooltip || copy ? <>{copy}{tooltip}</> : ""}>
    <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{children}</span>
  </CellTooltip>;
};
const getSortOrder = (sortBy: string, sortOptions?: SortOption[]) => {
  const sortOrder = sortOptions ? sortOptions.find(sortOption => sortOption.sortBy === sortBy)?.sortOrder : null;
  return sortOrder;
};
const missingValueComparator = (v1: string | number | null, v2: string | number | null, sortOrder: SortOption["sortOrder"] | null |undefined) => {
    
  if (!sortOrder || v1 === v2) return 0;

  if (v1 === undefined || v1 === "-") return sortOrder === "asc" ? 1 : -1;
  if (v2 === undefined || v1 === "-") return sortOrder === "asc" ? -1 : 1;

  if (v1 && v2 && v1 < v2) return -1;
  if (v1 && v2 && v1 > v2) return 1;
  return 0;
};

type DefaultHeaderProps = {
  children: ReactNode,
  colDef: GridStateColDef,
}
const DefaultHeader = ({ children, colDef }: DefaultHeaderProps) => {
  const tooltip = getTooltipText(colDef.headerName, colDef.computedWidth);

  return <CellTooltip
    placement="top"
    title={tooltip}>
    <span style={{ fontWeight: 500, overflow: "hidden", textOverflow: "ellipsis" }}>{children}</span>
  </CellTooltip>;
};

export function getTitleSpecialistDashboardColumns(sortOptions?: SortOption[]) {
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | AdditionalDashboardColumn }[] = [
    getTSRecordIdColumn(),
    getCustomerNameColumn(),
    getCustomerBirthdayColumn(),
    getCustomerPhoneColumn(),
    getVinColumn(),
    getStateColumn(true, sortOptions),
    getLenderNameColumn(false, sortOptions),
    getLenderPhoneColumn(),
    getDealTypeColumn(),
    getAeStatusStringColumn(),
    getSeverityColumn(true, true),
    getDaysInStatusColumn(),
    getTotalDaysColumn(),
    getLastTouchedColumn(),
    getFollowUpDateColumn(sortOptions),
    getFundedDateColumn(),
    getLienholderNameColumns(),
    getLienholderPhoneColumn(),
  ];
  return columns;
}

export function getTitleSpecialistDashboardForManagerColumns() {
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | AdditionalDashboardColumn }[] = [
    getTSRecordIdColumn(),
    getTitleRepColumn(),
    getCustomerNameColumn(),
    getCustomerBirthdayColumn(),
    getCustomerPhoneColumn(),
    getVinColumn(),
    getStateColumn(true),
    getLenderNameColumn(true),
    getLenderPhoneColumn(),
    getDealTypeColumn(),
    getAeStatusColumn(),
    getSeverityColumn(true, true),
    getDaysInStatusColumn(),
    getTotalDaysColumn(),
    getLastTouchedColumn(),
    getFollowUpDateColumn(),
    getFundedDateColumn(),
    getLienholderNameColumns(),
    getLienholderPhoneColumn(),
  ];
  return columns;
}

export function getAssociateTitleSpecialistDashboardColumns(sortOptions?: SortOption[]) {
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | "customerName" | "lenderName" }[] = [
    getTSRecordIdColumn(),
    getWorkedOnByColumn(),
    getCustomerNameColumn(),
    getVinColumn(),
    getStateColumn(true, sortOptions),
    getLenderNameColumn(false, sortOptions),
    getAeStatusStringColumn(),
    getSeverityColumn(),
    getDaysInStatusColumn(),
    getTotalDaysColumn(),
    getLastTouchedColumn(),
  ];
  return columns;
}

function getBaseATSMyQueueColumns(sortOptions?: SortOption[]) {
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | "customerName" | "lenderName" }[] = [
    getATSRecordIdColumn(),
    getWorkedOnByColumn(),
    getCustomerNameColumn(),
    getVinColumn(),
    getStateColumn(true, sortOptions),
    getLenderNameColumn(false, sortOptions),
    getAeStatusStringColumn(),
    getSeverityColumn(),
    getDaysInStatusColumn(),
    getTotalDaysColumn(),
  ];
  return columns;
}

export function getAtsMyQueueColumnsForTopPriority() {
  const baseColumns = getBaseATSMyQueueColumns();
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | "customerName" | "lenderName" }[] = [
    ...baseColumns,
    getEtaColumn(),
  ];
  return columns;
}

export function getAtsMyQueueColumns() {
  const baseColumns = getBaseATSMyQueueColumns();
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | "customerName" | "lenderName" }[] = [
    ...baseColumns,
    getLastTouchedColumn(),
  ];
  return columns;
}

export function getAssociateTitleSpecialistDashboardForManagerColumns() {
  const columns: GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | "customerName" | "lenderName" }[] = [
    getTSRecordIdColumn(),
    getAssignedAssociateTitleSpecialistColumn(),
    getCustomerNameColumn(),
    getVinColumn(),
    getStateColumn(false),
    getLenderNameColumn(true),
    getAeStatusColumn(),
    getSeverityColumn(false, false),
    getDaysInStatusColumn(),
    getTotalDaysColumn(),
    getLastTouchedColumn(),
  ];
  return columns;
}

function getATSRecordIdColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.recordId,
    field: "recordId",
    flex: 1,
    renderCell: (params) => <LinkCell row={params.row} />,
    filterable: false,
    minWidth: 80,
  };
}
function getLienholderNameColumns(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton" | "lienholderName";
} {
  return {
    headerName: columnLabels.lienholderName,
    field: "lienholderName",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => params.row.lienholder.name,
    minWidth: 140,
  };
}
function getLienholderPhoneColumn(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton" | "lienholderPhone";
} {
  return {
    headerName: columnLabels.lienholderPhone,
    field: "lienholderPhone",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => params.row.lienholder.phone,
    filterable: false,
    minWidth: 120,
  };
}
function getCustomerPhoneColumn(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton" | "customerPhone";
} {
  return {
    headerName: columnLabels.customerPhone,
    field: "customerPhone",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => params.row.customer?.phone ?? "",
    filterable: false,
    minWidth: 80,
  };
}
function getFundedDateColumn(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.fundedDate,
    field: "fundedDate",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => formatDate(params.row.fundedDate),
    filterable: false,
    minWidth: 80,
  };
}
function getDealTypeColumn(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.dealType,
    field: "dealType",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => formatAeDealType(params.row.dealType),
    sortable: false,
    minWidth: 80,
  };
}
function getLenderPhoneColumn(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton" | "lenderPhone";
} {
  return {
    headerName: columnLabels.lenderPhone,
    field: "lenderPhone",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => params.row?.lender?.phone ?? "",
    filterable: false,
    minWidth: 80,
  };
}
function getCustomerBirthdayColumn(): GridEnrichedColDef<DashboardRow, string | null, unknown> & {
  field: keyof DashboardRow | "refreshButton" | "customerBirthday";
} {
  return {
    headerName: columnLabels.customerBirthday,
    field: "customerBirthday",
    renderCell: ({value, colDef}) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => params.row.customer?.birthday ? formatDateWithoutTimestamp(params.row.customer?.birthday) : "",
    filterable: false,
    minWidth: 80,
  };
}

function getTSRecordIdColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.recordId,
    field: "recordId",
    flex: 1,
    renderCell: (params) => <TitleSpecialistDashboardLinkCell row={params.row} />,
    filterable: false,
    minWidth: 80,
  };
}

function getTitleRepColumn(): GridEnrichedColDef<DashboardRow, string | number | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.titleRep,
    flex: 2,
    field: "assignee",
    minWidth: 150,
    width: 150,
    renderCell: ({ value, colDef }) => <DefaultCell enableCopy colDef={colDef}>{value}</DefaultCell>,
    valueGetter: params => params.row.owner?.lastName
      ? `${params.row.owner.lastName}, ${params.row.owner.firstName}`
      : "Unassigned",
    filterOperators: restrictAndOverrideFilterOperations(getGridStringOperators()),
  };
}

function getAssignedAssociateTitleSpecialistColumn(): GridEnrichedColDef<
  DashboardRow,
  string | number | null,
  unknown
> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.associateTitleSpecialistAssignee,
    field: "assignee",
    minWidth: 150,
    width: 150,
    renderCell: (params) => {
      const { row } = params;
      return row.owner?.firstName ? `${row.owner.firstName}, ${row.owner.lastName}` : "Unassigned";
    },
    filterOperators: restrictAndOverrideFilterOperations(getGridStringOperators()),
  };
}

function getWorkedOnByColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.workedOnBy,
    field: "workedOnBy",
    minWidth: 190,
    filterable: false,
    renderCell: (params) => {
      return (
        <p style={{ textAlign: params.row.workedOnBy ? "left" : "center", width: "100%" }}>
          {params.row.workedOnBy ?? "-"}
        </p>
      );
    },
  };
}

function getCustomerNameColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton" | "customerName";
} {
  return {
    headerName: columnLabels.customer,
    flex: 2,
    field: "customerName",
    filterable: false,
    minWidth: 150,
    renderCell: ({ value, colDef }) => <DefaultCell enableCopy colDef={colDef}>{`${value}`}</DefaultCell>,
    valueGetter: params => params.row.customer
      ? `${params.row.customer.lastName}, ${params.row.customer.firstName}`
      : "",
  };
}

function getVinColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.vin,
    flex: 2,
    field: "vin",
    filterable: false,
    minWidth: 80,
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef} enableCopy>{`${value}`}</DefaultCell>
  };
}

function getStateColumn(filterable: boolean, sortOptions?: SortOption[]): GridEnrichedColDef<DashboardRow, string | number | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  const sortOrder = getSortOrder("state", sortOptions);
  return {
    headerName: columnLabels.state,
    flex: 1,
    field: "state",
    align: "center",
    filterable: filterable,
    headerAlign: "center",
    filterOperators: restrictAndOverrideFilterOperations(getGridStringOperators()),
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef}>{value}</DefaultCell>,
    valueGetter: ({ value }) => value ? formatState(value) : "-",
    minWidth: 50,
    width: 50,
    sortComparator: (v1, v2) => missingValueComparator(v1, v2, sortOrder),
  };
}

function getLenderNameColumn(restrictFilterOperators: boolean, sortOptions?: SortOption[]): GridEnrichedColDef<
  DashboardRow,
  string | number | null, unknown
> & {
  field: keyof DashboardRow | "refreshButton" | "lenderName";
} {
  const sortOrder = getSortOrder("lenderName", sortOptions);
  return {
    headerName: columnLabels.lenderName,
    flex: 2,
    field: "lenderName",
    minWidth: 100,
    width: 100,
    valueGetter: params => params.row.lender?.name ? params.row.lender?.name : "",
    filterOperators: restrictFilterOperators
      ? restrictAndOverrideFilterOperations(getGridStringOperators())
      : overrideStringFilterOperations(getGridStringOperators()),
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef} enableCopy>{value}</DefaultCell>,
    sortComparator: (v1, v2) => missingValueComparator(v1, v2, sortOrder),
  };
}

function getAeStatusStringColumn(): GridEnrichedColDef<DashboardRow, string | number | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.aeStatus,
    headerClassName: "test",
    field: "aeStatus",
    minWidth: 100,
    filterOperators: overrideStringFilterOperations(getGridStringOperators()),
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef} enableCopy>{value}</DefaultCell>,
    valueGetter: (params) => formatAeLeadStatus(params.row.aeStatus)
  };
}

function getAeStatusColumn(): GridEnrichedColDef<DashboardRow, string | number | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.aeStatus,
    headerClassName: "test",
    field: "aeStatus",
    flex: 2,
    minWidth: 100,
    maxWidth: 270,
    filterOperators: restrictAndOverrideFilterOperations(getGridStringOperators()),
    sortable: false,
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef} enableCopy>{value}</DefaultCell>,
    valueGetter: (params) => formatAeLeadStatus(params.row.aeStatus)
  };
}

function getEtaColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: atsMyQueueLabels.etaSource,
    field: "etaSource",
    flex: 1,
    filterable: false,
    align: "center",
    minWidth: 180,
    renderCell: (params) => {
      return (
        <Chip
          label={params.row.topPriorityType}
          sx={{ backgroundColor: "rgba(81, 50, 162, 0.65)", color: "#FFFFFF" }}
        />
      );
    },
  };
}

const statusSeverityComparator: GridComparatorFn<string | number | null> = (v1, v2) => {
  const priorityOrderMap = {
    [severities[StatusSeverity.Green]]: 1,
    [severities[StatusSeverity.Yellow]]: 2,
    [severities[StatusSeverity.Red]]: 3,
  };
  return (v1 ? priorityOrderMap[v1] : 0) - (v2 ? priorityOrderMap[v2] : 0);
};

function getSeverityColumn(
  filterable = true,
  sortable = true
): GridEnrichedColDef<DashboardRow, string | number | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.severity,
    field: "aeStatusSeverity",
    flex: 1,
    filterable,
    headerAlign: "center",
    filterOperators: restrictAndOverrideFilterOperations(getGridStringOperators()),
    align: "center",
    minWidth: 100,
    valueGetter: (params) => severityToDisplayLabel(params.row.aeStatusSeverity),
    sortable,
    renderCell: (params) => <StatusSeverityChip severity={params.row.aeStatusSeverity} />,
    sortComparator: statusSeverityComparator,
  };
}

function getDaysInStatusColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.daysInStatus,
    renderHeader: ({ colDef }) => <DefaultHeader colDef={colDef}>{columnLabels.daysInStatus}</DefaultHeader>,
    field: "daysInStatus",
    flex: 1,
    filterable: false,
    align: "center",
    headerAlign: "center",
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef}>{`${value}`}</DefaultCell>,
    valueGetter: (params) => formatDaysInStatus(params.row.daysInStatus),
    minWidth: 60,
  };
}

function getTotalDaysColumn(): GridEnrichedColDef<DashboardRow, unknown, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.totalDays,
    renderHeader: ({ colDef }) => <DefaultHeader colDef={colDef}>{columnLabels.totalDays}</DefaultHeader>,
    field: "totalDays",
    flex: 1,
    filterable: false,
    align: "center",
    headerAlign: "center",
    renderCell: ({ value, colDef }) => <DefaultCell colDef={colDef}>{`${value}`}</DefaultCell>,
    valueGetter: (params) => formatTotalDays(params.row.totalDays),
    minWidth: 60,
  };
}

function getLastTouchedColumn(filterable = false): GridEnrichedColDef<DashboardRow, string | number | null, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  return {
    headerName: columnLabels.lastTouched,
    renderHeader: ({ colDef }) => <DefaultHeader colDef={colDef}>{columnLabels.lastTouched}</DefaultHeader>,
    field: "lastTouched",
    flex: 1,
    filterable,
    filterOperators: filterable ? overrideStringFilterOperations(getGridStringOperators()) : undefined,
    align: "center",
    headerAlign: "center",
    minWidth: 110,
    renderCell: ({ row, colDef }) => <DefaultCell colDef={colDef}>{`${formatLastTouchedDate(row.lastTouched)}`}</DefaultCell>
  };
}

function getFollowUpDateColumn(sortOptions?: SortOption[]): GridEnrichedColDef<DashboardRow, number, unknown> & {
  field: keyof DashboardRow | "refreshButton";
} {
  const sortOrder = getSortOrder("followUpDate", sortOptions);
  return {
    headerName: columnLabels.followUpDate,
    field: "followUpDate",
    filterable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    renderCell: ({ row, colDef }) => <DefaultCell colDef={colDef}>
      {row.followUpDate ? `${formatDate(row.followUpDate)}` : "-"}
    </DefaultCell>,
    sortComparator: (v1, v2) => missingValueComparator(v1, v2, sortOrder),
  };
}
