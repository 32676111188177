import {
    DocumentEmbedLink,
    DocumentHistoryModel,
    DocumentInformation,
    DocumentVersion,
    EditDocumentMetadataModel,
    FileUploadRequest,
    GetDownloadUrlRequest,
    GetDownloadUrlResponse,
    JsonPatchOperation,
    UploadDocumentMetadataModel,
} from "@auto-approve/auto-approve-ui-library";
import axios, { AxiosRequestConfig } from "axios";
import { WORKFLOW_HOST_URL } from "../core/types";

export const deleteDocument = (
    documentId: string,
    signal: AbortSignal,
    token: string,
) => {
    const url = `${WORKFLOW_HOST_URL}/documents/${documentId}`;
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token,
        },
        signal
    };
    return axios
        .delete(url, config)
        .then(response => {
            return response;
        });
};

export const getDocumentsHistory = async (documentIds: Array<string>, signal: AbortSignal, token: string)
    : Promise<Array<DocumentHistoryModel>> => {
    const url = `${WORKFLOW_HOST_URL}/documents/history`;

    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token,
        },
        signal
    };

    const body = { documentIds };

    const response = await axios.post(url, body, config);
    if (response.data && Array.isArray(response.data)) {
        return response.data.map((history: Array<DocumentVersion>, i) => ({
            documentId: documentIds[i],
            history
        }));
    }

    return Promise.reject("Unable to retrieve file history.");
};

export const getDocumentEmbedLink = async (
    documentId: string,
    signal: AbortSignal,
    token: string
): Promise<DocumentEmbedLink> => {
    const url = `${WORKFLOW_HOST_URL}/documents/${documentId}/embed-link`;
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token,
        },
        signal
    };

    const response = await axios.get(url, config);
    if (response.data) {
        return response.data;
    }
    return Promise.reject("Unable to retrieve document embed link.");
};

export const getDownloadUrls = async (
    request: GetDownloadUrlRequest,
    signal: AbortSignal,
    token: string
): Promise<GetDownloadUrlResponse> => {
    const url = `${WORKFLOW_HOST_URL}/documents/download-urls`;
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token,
        },
        signal
    };

    const response = await axios.post(url, request, config);
    if (response.data) {
        return response.data;
    }
    return Promise.reject("Unable to retrieve download urls.");
};

export const patchDocumentMetadata = async (
    documentId: string,
    updatedMetadata: EditDocumentMetadataModel,
    signal: AbortSignal,
    token: string,
) => {
    const operations: Array<JsonPatchOperation> = [
        { op: "add", path: "/expirationDate", value: updatedMetadata.expirationDate },
        { op: "add", path: "/status", value: updatedMetadata.status },
        { op: "add", path: "/comments", value: updatedMetadata.comments },
        { op: "add", path: "/documentType", value: updatedMetadata.recipeCard.key },
        { op: "add", path: "/templateKey", value: updatedMetadata.recipeCard.key },
        { op: "add", path: "/recipeCardId", value: `${updatedMetadata.recipeCard.id ?? ""}` },
        { op: "add", path: "/documentCategory", value: updatedMetadata.recipeCard.category },
        // TODO - figure out if we want to patch source. Could help with remediation
        //{ op: "add", path: "/source", value: updatedMetadata.source },
    ];
    
    const url = `${WORKFLOW_HOST_URL}/documents/${documentId}/metadata`;
    const config: AxiosRequestConfig = {
        headers: {
            "Content-type": "application/json-patch+json",
            Authorization: token,
        },
        signal
    };

    const response = await axios.patch(url, operations, config);
    if (response.status === 204) {
        return response.data;
    }

    return Promise.reject("Unable to patch document metadata");
};

export const putReplaceDocumentFile = async (
    documentId: string,
    file: File,
    signal: AbortSignal,
    token: string
) => {
    const url = `${WORKFLOW_HOST_URL}/documents/${documentId}`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token
        },
        signal
    };

    const response = await axios.put<DocumentInformation>(url, formData, config);
    if (response.data) {
        return response.data;
    }
    return Promise.reject("Unable to replace file");
};

export const postGenerateDocumentUploadLink = async (
    leadOid: string,
    signal: AbortSignal,
    token: string,
): Promise<FileUploadRequest> => {
    const url = `${WORKFLOW_HOST_URL}/upload-requests`;
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token
        },
        signal
    };
    const body = { leadOid };
    const response = await axios.post(url, body, config);
    if (response.data) {
        return response.data;
    }
    return Promise.reject("Unable to retrieve file request upload link.");
};

export const postUploadDocumentFile = async (
    file: File,
    leadOid: string,
    initialMetadata: UploadDocumentMetadataModel,
    token: string,
    signal: AbortSignal,
): Promise<DocumentInformation> => {
    const url = `${WORKFLOW_HOST_URL}/documents`;
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("leadOid", leadOid);
    formData.append("documentType", initialMetadata.recipeCard.key);
    formData.append("status", initialMetadata.status);
    formData.append("templateKey", initialMetadata.recipeCard.key);
    formData.append("recipeCardId", `${initialMetadata.recipeCard?.id ?? ""}`);
    formData.append("source", "DOCUMENT_MANAGEMENT");
    
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token
        },
        signal
    };

    const response = await axios.post(url, formData, config);
    if (response.data) {
        return response.data;
    }
    return Promise.reject("Unable to upload document.");
};

export async function getDocumentActivity(token: string, documentId: string, skip = 0): Promise<GetDocumentActivityResponse> {
    const url = `${WORKFLOW_HOST_URL}/documents/${documentId}/activity`;
    const config: AxiosRequestConfig = {
        params: {
            skip,
        },
        headers: {
            Authorization: token,
        },
    };
    const response = await axios.get(url, config);
    if (response.data) {
        return response.data;
    }
    return Promise.reject("Unable to retrieve document activity");
};

// #region - TODO - export from shared library
export enum ActivityType {
    CREATE = "CREATE",
    READ = "READ",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
};

export type Activity = {
    key: string; // i.e. status, expiration_date
    // human description as to what changed
    description: string;
    // allows UI to override the description with custom logic
    type: ActivityType;
};

// These will be mapped to the TimelineItems & TimelineItem component on the Frontend
// https://gitlab.com/auto-approve/approve-engine/shared/auto-approve-ui-library/-/blob/feat/main/src/components/TimelineComponent/types.ts
export type DocumentActivity = {
    // calculated id: `documentVersion.id + field`
    id: string;
    // TODO - move to separate type other after refactor
    version: {
        // postgres documentVersion.id
        id: number;
        // i.e. Box version id
        storageId?: string;
        // TODO - version number (needs to be added to DB or inferred)
        //number?: number;
    };

    // ISO Date string (format: YYYY-MM-DDTHH:Mi:SS | example: TODO)
    createdAt: string;
    // You cannot rely on this being an active AE user (may be email of customer or random header)
    // Shared document service uses x-on-behalf-of header or box attributes to determine createdBy
    createdBy: {
        // this is a string in shared document service (likely email)
        id: string;
        name: string;
    };
} & Activity;

export type GetDocumentActivityResponse = {
    // postgres document.id
    id: number;
    // i.e. Box document id
    storageId: string;
    // AE mongo id
    leadOid: string;
    // postgres folder.id
    folderId: number;
    // i.e. Box folder id
    folderStorageId: string;

    // common pagination attributes
    data: DocumentActivity[];
    skip: number;
    take: number;
    // Server count
    count: number;
};
// #endregion