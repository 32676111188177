import { DateTime } from "luxon";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Search } from "./Search";
import React, { ReactElement, useState } from "react";
import { localNow } from "../../../contexts/time/now";
import { IconBarButton } from "../../shared/IconBarButton/IconBarButton";
import { useSharedDashboard } from "../context/SharedDashboardContext";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getCopy } from "../../../authoring/copy";
import { HasShowCompleted } from "../context/types";

type DashboardHeaderProps = {
  customButton?: ReactElement;
  showCompletedContext?: () => HasShowCompleted;
  grouped?: boolean;
  onGroupToggle?: (grouped: boolean) => void;
};

export const DashboardHeader = React.memo(function DashboardHeader(props: DashboardHeaderProps) {
  const [refreshDateTime, setRefreshDateTime] = useState<DateTime>(localNow());
  const [searchTextValue, setSearchText] = useState("");
  const { setSearchTerm, refreshDashboard, setPage } = useSharedDashboard();
  const copy = getCopy().titleOrdersDashboardHeader;
  const showCompleted = props.showCompletedContext ? props.showCompletedContext() : undefined;

  const onKeyPressedInSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      setSearchTerm(searchTextValue);
      setRefreshDateTime(localNow());
      setPage(0);
    }
  };

  return (
    <>
      <div className="mt-3 w-full flex justify-between items-center">
        <div className="flex display flex align-center">
          <Search
            value={searchTextValue}
            onChange={(e) => setSearchText(e.target.value)}
            clearSearch={() => {
              setSearchText("");
              setSearchTerm("");
            }}
            onKeyPress={onKeyPressedInSearch}
          />
          {props.customButton}
          {props.grouped !== undefined && (
            <div className={"ml-4"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.grouped}
                    onChange={(event) => {
                      if (props.onGroupToggle) {
                        props.onGroupToggle(event.target.checked);
                      }
                    }}
                  />
                }
                label="Group by Priority"
              />
            </div>
          )}
          {showCompleted && (
            <div className={"ml-4"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showCompleted.showCompleted}
                    onChange={(event) => {
                      if (showCompleted) {
                        showCompleted.setShowCompleted(event.target.checked);
                      }
                    }}
                  />
                }
                label={copy.showCompletedCheckboxLabel}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end items-center">
          <span className="mr-1">Last Update: {refreshDateTime.toLocaleString(DateTime.DATETIME_FULL)}</span>
          <IconBarButton
            onClick={() => {
              refreshDashboard();
              setRefreshDateTime(localNow());
            }}
            tooltipText="Refresh"
            dataTestId="global refresh button"
          >
            <AutorenewIcon />
          </IconBarButton>
        </div>
      </div>
    </>
  );
});
