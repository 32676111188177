import { EstimateTemplateField } from "../../../../contexts/estimates/core/types";
import {
  BorrowerRelationship,
  CreateEstimateCustomer,
  CreateEstimateTitleOrder,
} from "../../../../networking/core/types";
import { getCopy } from "../../../../authoring/copy";
import { UiEstimateTemplateField, TitleOrderField } from "./types";
import { DateTime } from "luxon";
import { Role } from "@auto-approve/auto-approve-ui-library";

const copy = getCopy().createEstimate.submitEstimate;
const borrowerCopy = copy.borrower;

export function autoPopulateFields(
  fields?: EstimateTemplateField[],
  titleOrder?: CreateEstimateTitleOrder,
  userRole?: Role
): { titleOrderFieldsPopulated: TitleOrderField[]; unknownFieldsPopulated: UiEstimateTemplateField[] } {
  if (!fields) {
    return {
      titleOrderFieldsPopulated: [],
      unknownFieldsPopulated: [],
    };
  }

  const titleOrderFieldsPopulated: TitleOrderField[] = [];
  const unknownFieldsPopulated: UiEstimateTemplateField[] = [];

  const getPrimaryCustomer = (titleOrder: CreateEstimateTitleOrder | undefined) => titleOrder?.primaryCustomer;
  const getSecondaryCustomer = (titleOrder: CreateEstimateTitleOrder | undefined) => titleOrder?.secondaryCustomer;

  fields.forEach((field) => {
    if (isExcludedField(field)) return;

    const populatedFields: Array<TitleOrderField | undefined> = [];
    if (field.group === copy.borrower.groupName) {
      if (field.teGroup !== copy.borrower.secondaryGroupName) {
        const primaryBorrowerPopulatedFields = autoPopulateBorrowerInformationField(
          field,
          getPrimaryCustomer,
          copy.borrower.primaryGroupName
        );
        if (Array.isArray(primaryBorrowerPopulatedFields)) {
          primaryBorrowerPopulatedFields.forEach((field) => {
            populatedFields.push(field);
          });
        } else {
          populatedFields.push(primaryBorrowerPopulatedFields);
        }
      }
      const secondaryBorrowerFields = autoPopulateBorrowerInformationField(
        field,
        getSecondaryCustomer,
        copy.borrower.secondaryGroupName
      );

      if (Array.isArray(secondaryBorrowerFields)) {
        secondaryBorrowerFields.forEach((field) => {
          populatedFields.push(field);
        });
      } else {
        populatedFields.push(secondaryBorrowerFields);
      }
    } else if (field.group === copy.lienHolderGroupName) {
      if (!titleOrder?.lienHolder.address.street1 && userRole === Role.LOAN_CONSULTANT) {
        prePopulateTitleOrderLienHolderFields(titleOrder);
        populatedFields.push(autoPopulateLienHolderFieldForLoanConsultant(field));
      } else {
        populatedFields.push(autoPopulateLienHolderField(field));
      }
    } else if (field.group === copy.vehicle.groupName) {
      populatedFields.push(autoPopulateVehicleField(field));
    }

    if (populatedFields.length === 0 || populatedFields.every((field) => field === undefined)) {
      if (field.group === copy.borrower.groupName) {
        unknownFieldsPopulated.push(autoPopulateWithDefaultField(field, copy.borrower.primaryGroupName));
        unknownFieldsPopulated.push(autoPopulateWithDefaultField(field, copy.borrower.secondaryGroupName));
      } else {
        unknownFieldsPopulated.push(autoPopulateWithDefaultField(field));
      }
    }
    populatedFields.forEach((titleOrderField) => {
      if (titleOrderField) {
        titleOrderFieldsPopulated.push(titleOrderField);
      }
    });
  });

  return {
    titleOrderFieldsPopulated,
    unknownFieldsPopulated,
  };
}

function prePopulateTitleOrderLienHolderFields(titleOrder: CreateEstimateTitleOrder | undefined) {
  const lienHolderHardcodedData = getCopy().createEstimate.submitEstimate.lienHolderHardcodedData;
  if (!titleOrder) {
    return;
  }

  if (!titleOrder.lienHolder.address.street1) {
    titleOrder.lienHolder.address = {
      city: lienHolderHardcodedData.city,
      state: lienHolderHardcodedData.state,
      street1: lienHolderHardcodedData.address,
      street2: "",
      zip: lienHolderHardcodedData.zip,
    };
  }
  if (!titleOrder.lienHolder.eltNum) {
    titleOrder.lienHolder.eltNum = lienHolderHardcodedData.eltNum;
  }
  if (!titleOrder.lienHolder.name) {
    titleOrder.lienHolder.name = lienHolderHardcodedData.name;
  }
}

function autoPopulateBorrowerInformationField(
  field: EstimateTemplateField,
  getCustomer: (titleOrder: CreateEstimateTitleOrder | undefined) => CreateEstimateCustomer | undefined,
  displayGroup: string
): TitleOrderField | TitleOrderField[] | undefined {
  if (field.name === "Type") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.type ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.type = newValue;
        }
      },
      displayGroup
    );
  }
  if (field.name === borrowerCopy.name) {
    return [
      titleOrderFieldFactory(
        { ...field, name: borrowerCopy.firstName },
        (titleOrder: CreateEstimateTitleOrder | undefined) => {
          const customer = getCustomer(titleOrder);
          return customer ? `${customer.firstName ?? ""}`.trim() : "";
        },
        (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
          const customer = getCustomer(titleOrder);
          if (customer) {
            if (!newValue?.trim()) {
              customer.firstName = "";
            } else {
              customer.firstName = newValue;
            }
          }
        },
        displayGroup,
        borrowerCopy.firstName
      ),
      titleOrderFieldFactory(
        { ...field, name: borrowerCopy.middleName, required: false },
        (titleOrder: CreateEstimateTitleOrder | undefined) => {
          const customer = getCustomer(titleOrder);
          return customer ? `${customer.middleName ?? ""}`.trim() : "";
        },
        (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
          const customer = getCustomer(titleOrder);
          if (customer) {
            if (!newValue?.trim()) {
              customer.middleName = "";
            } else {
              customer.middleName = newValue;
            }
          }
        },
        displayGroup,
        borrowerCopy.middleName
      ),
      titleOrderFieldFactory(
        { ...field, name: borrowerCopy.lastName },
        (titleOrder: CreateEstimateTitleOrder | undefined) => {
          const customer = getCustomer(titleOrder);
          return customer ? `${customer.lastName ?? ""}`.trim() : "";
        },
        (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
          const customer = getCustomer(titleOrder);
          if (customer) {
            if (!newValue?.trim()) {
              customer.lastName = "";
            } else {
              customer.lastName = newValue;
            }
          }
        },
        displayGroup,
        borrowerCopy.lastName
      ),
    ];
  }
  if (field.name === "Address") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.address.street1 ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.address.street1 = newValue ?? "";
        }
      },
      displayGroup
    );
  }
  if (field.name === "City") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.address.city ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.address.city = newValue ?? "";
        }
      },
      displayGroup
    );
  }
  if (field.name === "State") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.address.state ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.address.state = newValue ?? "";
        }
      },
      displayGroup
    );
  }
  if (field.name === "Zip") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.address.zip ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.address.zip = newValue ?? "";
        }
      },
      displayGroup
    );
  }
  if (field.name === "DL Number") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.driversLicenseNum ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.driversLicenseNum = newValue;
        }
      },
      displayGroup
    );
  }
  if (field.name === "FEIN (if Business)") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.federalEmployerId ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.federalEmployerId = newValue;
        }
      },
      displayGroup
    );
  }
  if (field.name === "Social Security Number(s) of Owners" || field.name === "SSN (if Individual)" || field.name === "Social Security Number(s) of Owner(s)") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.ssn ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.ssn = newValue;
        }
      },
      displayGroup
    );
  }
  if (field.name === "Date of Birth") {
    return titleOrderFieldFactory(
      { ...field, fieldInfo: {type: "date" }},
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.birthday ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          if (!isoIsValid(newValue)) {
            customer.birthday = undefined;
          }
          customer.birthday = newValue;
        }
      },
      displayGroup
    );
  }
  if (field.name === copy.borrower.relationshipToBorrowerLabel) {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return getCustomer(titleOrder)?.relationshipToPrimary ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        const customer = getCustomer(titleOrder);
        if (customer) {
          customer.relationshipToPrimary = isBorrowerRelationship(newValue) ? newValue : undefined;
        }
      },
      displayGroup
    );
  }
  return undefined;
}

function isBorrowerRelationship(value: unknown): value is BorrowerRelationship {
  return typeof value === "string" && value in BorrowerRelationship;
}

function autoPopulateLienHolderFieldForLoanConsultant(field: EstimateTemplateField): TitleOrderField | undefined {
  const lienHolderHardcodedData = getCopy().createEstimate.submitEstimate.lienHolderHardcodedData;

  if (field.name === "Name") {
    return titleOrderFieldFactory(
      field,
      () => {
        return lienHolderHardcodedData.name;
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.name = newValue ?? "";
      }
    );
  }
  if (field.name === "Address" || field.name === "Address 1") {
    return titleOrderFieldFactory(
      field,
      () => {
        return lienHolderHardcodedData.address;
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.street1 = newValue ?? "";
      }
    );
  }
  if (field.name === "State") {
    return titleOrderFieldFactory(
      field,
      () => {
        return lienHolderHardcodedData.state;
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.state = newValue ?? "";
      }
    );
  }
  if (field.name === "City") {
    return titleOrderFieldFactory(
      field,
      () => {
        return lienHolderHardcodedData.city;
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.city = newValue ?? "";
      }
    );
  }
  if (field.name === "Zip") {
    return titleOrderFieldFactory(
      field,
      () => {
        return lienHolderHardcodedData.zip;
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.zip = newValue ?? "";
      }
    );
  }
  if (field.name === "ELT #") {
    return titleOrderFieldFactory(
      field,
      () => {
        return lienHolderHardcodedData.eltNum;
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.eltNum = newValue;
      }
    );
  }
  return undefined;
}

function autoPopulateLienHolderField(field: EstimateTemplateField): TitleOrderField | undefined {
  if (field.name === "Name") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.name ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.name = newValue ?? "";
      }
    );
  }
  if (field.name === "Address" || field.name === "Address 1") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.address.street1 ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.street1 = newValue ?? "";
      }
    );
  }
  if (field.name === "City") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.address.city ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.city = newValue ?? "";
      }
    );
  }
  if (field.name === "State") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.address.state ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.state = newValue ?? "";
      }
    );
  }
  if (field.name === "Zip") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.address.zip ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.address.zip = newValue ?? "";
      }
    );
  }
  if (field.name === "ELT #") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.eltNum ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.lienHolder.eltNum = newValue;
      }
    );
  }
  if (field.name === "Date of Lien") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.lienDate ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (!isoIsValid(newValue)) {
          titleOrder.lienHolder.lienDate = undefined;
        }
        titleOrder.lienHolder.lienDate = newValue;
      }
    );
  }
  if (field.name === "Requested Due Date") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.lienHolder.requestedDueDate ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (!isoIsValid(newValue)) {
          titleOrder.lienHolder.requestedDueDate = undefined;
        }
        titleOrder.lienHolder.requestedDueDate = newValue;
      }
    );
  }
  return undefined;
}

function autoPopulateVehicleField(field: EstimateTemplateField): TitleOrderField | undefined {
  if (field.name === "VIN") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.vin ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.vin = newValue ?? "";
      }
    );
  }
  if (field.name === "Current State Title is from") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.currentState ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.currentState = newValue;
      }
    );
  }
  if (field.name === "Title or MSO Vehicle?") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.titleOrMso ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.titleOrMso = newValue;
      }
    );
  }
  if (field.name === "Is this a trailer?") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return booleanToYesNoString(titleOrder?.vehicle.isTrailer);
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.isTrailer = yesNoStringToBoolean(newValue);
      }
    );
  }
  if (field.name === "Year") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.year?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.year = Number(newValue);
        } else {
          titleOrder.vehicle.year = undefined;
        }
      }
    );
  }
  if (field.name.trim() === "Make") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.make ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.make = newValue;
      }
    );
  }
  if (field.name === "Model") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.model ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.model = newValue;
      }
    );
  }
  if (field.name === "Vehicle Color") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.color ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.color = newValue;
      }
    );
  }
  if (field.name === "Fuel Type") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.fuelType ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.fuelType = newValue;
      }
    );
  }
  if (field.name === "Mileage") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.mileage?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.mileage = Number(newValue);
        } else {
          titleOrder.vehicle.mileage = undefined;
        }
      }
    );
  }
  if (field.name === "Purchase Price - $") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.purchasePrice?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.purchasePrice = Number(newValue);
        } else {
          titleOrder.vehicle.purchasePrice = undefined;
        }
      }
    );
  }
  if (field.name === "Purchase Date") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.purchaseDate ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (!isoIsValid(newValue)) {
          titleOrder.vehicle.purchaseDate = undefined;
        }
        titleOrder.vehicle.purchaseDate = newValue;
      }
    );
  }
  if (field.name === "Date of Mileage Reading") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.dateOfMileage ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (!newValue) {
          titleOrder.vehicle.dateOfMileage = undefined;
        }
        titleOrder.vehicle.dateOfMileage = newValue;
      }
    );
  }
  if (field.name === "Gross Weight - lbs") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.grossWeightInPounds?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.grossWeightInPounds = Number(newValue);
        } else {
          titleOrder.vehicle.grossWeightInPounds = undefined;
        }
      }
    );
  }
  if (field.name === "Empty Weight - lbs") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.emptyWeightInPounds?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.emptyWeightInPounds = Number(newValue);
        } else {
          titleOrder.vehicle.emptyWeightInPounds = undefined;
        }
      }
    );
  }
  if (field.name === "Length of Vehicle - ft") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.lengthInFeet?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.lengthInFeet = Number(newValue);
        } else {
          titleOrder.vehicle.lengthInFeet = undefined;
        }
      }
    );
  }
  if (field.name === "Length of Trailer - ft") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.lengthOfTrailerInFeet?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.lengthOfTrailerInFeet = Number(newValue);
        } else {
          titleOrder.vehicle.lengthOfTrailerInFeet = undefined;
        }
      }
    );
  }
  if (field.name === "Number of Wheels") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.numberOfWheels?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.numberOfWheels = Number(newValue);
        } else {
          titleOrder.vehicle.numberOfWheels = undefined;
        }
      }
    );
  }
  if (field.name === "Type of Purchase") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.purchaseType ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.purchaseType = newValue;
      }
    );
  }
  if (field.name === "Taxes Paid to Dealer $") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.taxesPaidToDealer?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.taxesPaidToDealer = Number(newValue);
        } else {
          titleOrder.vehicle.taxesPaidToDealer = undefined;
        }
      }
    );
  }
  if (field.name === "Trade In Amount $") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.tradeInAmount?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.tradeInAmount = Number(newValue);
        } else {
          titleOrder.vehicle.tradeInAmount = undefined;
        }
      }
    );
  }
  if (field.name === "Additional Fees $") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.additionalFees?.toString() ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        if (isNumber(newValue)) {
          titleOrder.vehicle.additionalFees = Number(newValue);
        } else {
          titleOrder.vehicle.additionalFees = undefined;
        }
      }
    );
  }
  if (field.name === "Tax Exempt?") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return booleanToYesNoString(titleOrder?.vehicle.taxExempt);
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.taxExempt = yesNoStringToBoolean(newValue);
      }
    );
  }
  if (field.name === "Gift between Buyer and Seller?") {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return booleanToYesNoString(titleOrder?.vehicle.isGift);
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.isGift = yesNoStringToBoolean(newValue);
      }
    );
  }
  if (field.name === copy.vehicle.relationship) {
    return titleOrderFieldFactory(
      field,
      (titleOrder: CreateEstimateTitleOrder | undefined) => {
        return titleOrder?.vehicle.buyerSellerRelationship ?? "";
      },
      (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => {
        titleOrder.vehicle.buyerSellerRelationship = newValue;
      }
    );
  }
  return undefined;
}

function autoPopulateWithDefaultField(
  field: EstimateTemplateField,
  displayGroup = defaultDisplayGroup(field)
): UiEstimateTemplateField {
  return {
    ...field,
    value: field.required ? "" : undefined,
    teGroup: displayGroup,
    isValid: undefined,
  };
}

export function yesNoStringToBoolean(yesNo: string | undefined) {
  return yesNo === "Yes";
}

export function booleanToYesNoString(bool: boolean | undefined) {
  if (bool === undefined) return "";
  return bool ? "Yes" : "No";
}

function isExcludedField(field: EstimateTemplateField) {
  return ["Is there a Lien Holder", "Estimate Special Instructions"].includes(field.name);
}

function defaultDisplayGroup(field: EstimateTemplateField) {
  if (field.group === copy.lienHolderGroupName) {
    return copy.lienHolderDisplayName;
  }
  return field.group;
}

function titleOrderFieldFactory(
  field: EstimateTemplateField,
  getValue: (titleOrder: CreateEstimateTitleOrder | undefined) => string,
  setValue: (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => void,
  displayGroup = defaultDisplayGroup(field),
  displayLabel = field.name
): TitleOrderField {
  return {
    estimateTemplateField: {
      ...field,
      teGroup: displayGroup,
    },
    displayLabel,
    getValue,
    setValue,
    isValid: undefined,
  };
}

function isoIsValid(newValue: string | undefined) {
  return newValue && DateTime.fromISO(newValue).isValid;
}

function isNumber(newValue: string | undefined) {
  const num = Number(newValue);
  return newValue && !isNaN(num);
}
