import { DashboardRow } from "./types";
import { getCopy } from "../../authoring/copy";
import { DateTime } from "luxon";

const copy = getCopy();

export function filterByTotalDays(rows: DashboardRow[], daysRange: number[], excludeRowsWithFollowUpDate = false) {
  return rows.filter((row) => {
    if (
      excludeRowsWithFollowUpDate &&
      row.followUpDate !== undefined &&
      row.followUpDate !== copy.titleOrdersDashboard.notFound
    ) {
      return false;
    }
    return row.totalDays > daysRange[0] && row.totalDays <= daysRange[1];
  });
}

export function filterByFollowUpDate(rows: DashboardRow[]) {
  return rows.filter(
    (row) => row.followUpDate !== undefined && row.followUpDate !== copy.titleOrdersDashboard.notFound
  );
}

export const fieldsWithDisabledCellClick = ["refreshButton", "recordId", "__check__"];

export const formatDateWithoutTimestamp = (isoDateStr: string): string  => {
  // AAT-1779: This will make DOB matchAE 1.0. The AE 1.0 datepicker saves in UTC 12:00 AM
  return DateTime.fromISO(isoDateStr).toUTC().toFormat("MM/dd/yyyy");
};