import { Box, Link } from "@mui/material";
import { CenteredDiv } from "../CenteredDiv";
import { environmentVariables } from "../../../config";
import { ROUTES } from "../../../App";

type NextStepsProps = {
  recordId: string;
  aeLeadId: string;
};

export function NextSteps(props: NextStepsProps) {
  return (
    <CenteredDiv className="mt-10">
      <Box boxShadow={2} sx={{ p: "50px", width: "600px" }}>
        <h2 className="mb-4 text-2xl text-center leading-8 font-medium text-aa-dark-purple">
          Successfully submitted the Estimate for <strong>Record {props.recordId}</strong>
        </h2>
        <p className="text-gray-500 text-sm text-center mt-8">
          Please check iLien to view the Title Estimate
        </p>
        <div className="mt-2 text-center">
          <Link href={environmentVariables.iLienUrl} color="#5230A2" underline="none" fontWeight="700" target="_blank">iLien</Link>
        </div>
        <div className="mt-1 text-center">
          <Link href={ROUTES.LANDING} color="#5230A2" underline="none" fontWeight="700">Titles Engine</Link>
        </div>
        <div className="mt-1 text-center">
          <Link href={`${environmentVariables.aeUrl}/loan-consultants/all-leads/id=${props.aeLeadId}`} color="#5230A2" underline="none" fontWeight="700" target="_blank">Approve Engine</Link>
        </div>
      </Box>
    </CenteredDiv>
  );
}
