import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useEscalationsDashboard } from "../context/EscalationsDashboardContext";
import { OrderWithEscalation } from "./types";
import { getCopy } from "../../../authoring/copy";
import { resolveEscalation } from "../../../networking/escalations/TitleWorkflowEscalationsApi";

const { heading, cancelButtonText, confirmButtonText } = getCopy().escalationsDashboard.resolveEscalation.modal;

type RequestEscalationModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  row: OrderWithEscalation;
  closeDrawer: () => void;
};

export function ResolveEscalationModal(props: RequestEscalationModalProps) {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { removeTitleOrderAfterResolution } = useEscalationsDashboard();

  const handleClose = () => props.setOpen(false);

  async function handleResolveEscalation() {
    if (!user?.token) return;
    setLoading(true);
    await resolveEscalation(user.token, {
      description,
      aeLeadId: props.row.aeLeadId,
    })
      .then(() => {
        removeTitleOrderAfterResolution(props.row);
        props.closeDrawer();
      })
      .catch((err) => {
        alert(`Failed to resolve the escalation. Error: ${err}`);
      })
      .finally(() => {
        setLoading(false);
        props.setOpen(false);
      });
  }

  const styles = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    select: {
      margin: "10px 10px 10px 0",
      width: "40%",
    },
    textField: {
      width: "100%",
    },
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box sx={styles.box}>
        <div className={"float-right"}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography id="escalate-order-heading" variant="h6" component="h6">
          {heading}
          {props.row?.recordId}
        </Typography>
        <TextField
          placeholder="Describe escalation"
          style={styles.textField}
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="float-right mt-4">
          <Button variant="text" onClick={handleClose} style={{ color: "#6F6F6F" }} disabled={loading}>
            {cancelButtonText}
          </Button>
          <Button variant="text" onClick={handleResolveEscalation} style={{ color: "#5132A2" }} disabled={loading}>
            {confirmButtonText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
