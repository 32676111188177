import { getTitleOrderById, refreshTitleOrder } from "../../networking/TitlesWorkflowAPI";
import { convertTitleOrderToDashboardRow, DashboardRow } from "./types";
import { Dispatch, SetStateAction } from "react";
import { DashboardTitleOrder } from "../../networking/core/types";
import { toDashboardTitleOrder } from "../../networking/title_orders/converters";

export async function refreshDashboardRowFromAe(
  token: string,
  aeLeadId: string,
  rows: DashboardRow[],
  setRows: Dispatch<SetStateAction<DashboardRow[]>>
) {
  return refreshTitleOrder(token, aeLeadId)
    .then((titleOrder) => {
      setNewDashboardRow(titleOrder, rows, setRows, aeLeadId);
    })
    .catch((error) => {
      window.alert("The API call to refresh the title order information failed.");
      console.error(error);
    });
}

export async function refreshDashboardRowFromDb(
  token: string,
  titleOrderId: number,
  rows: DashboardRow[],
  setRows: Dispatch<SetStateAction<DashboardRow[]>>
) {
  const titleOrder = await getTitleOrderById(titleOrderId, token);
  setNewDashboardRow(toDashboardTitleOrder(titleOrder), rows, setRows, titleOrder.aeLeadId);
}

function setNewDashboardRow(
  newTitleOrder: DashboardTitleOrder,
  rows: DashboardRow[],
  setRows: Dispatch<SetStateAction<DashboardRow[]>>,
  aeLeadId: string
) {
  const newData = [...rows];
  const rowIndex = newData.findIndex((dataRow) => dataRow.aeLeadId === aeLeadId);
  newData[rowIndex] = convertTitleOrderToDashboardRow(newTitleOrder);
  setRows(newData);
}
