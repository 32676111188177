import { WklsEstimateTransactionDisplayType } from "../components/types";
import { ActionListType } from "../networking/actionListing/types";
import { StatusSeverity, TaskGroupType } from "../networking/core/types";

export function getCopy() {
  return {
    shared: {
      missingValue: "-",
    },
    appHeader: {
      notifications: {
        title: "Inbox",
        markAllReadButton: {
          label: "Mark all read",
        },
      },
    },
    documentManagement: {
      navLabel: "Document Management"
    },
    titleOrdersDashboardHeader: {
      showCompletedCheckboxLabel: "Show Completed",
    },
    titleOrdersDashboard: {
      myQueueNavLabel: "Queue",
      inTransitNavLabel: "In Transit",
      refreshButton: {
        label: "Refresh",
      },
      columnLabels: {
        recordId: "Record ID",
        titleRep: "Title Rep",
        associateTitleSpecialistAssignee: "ATS Assignee",
        lastName: "Last name",
        firstName: "First name",
        customer: "Customer",
        customerPhone: "Customer Phone",
        customerBirthday: "Customer DOB",
        vin: "VIN",
        lenderName: "Lender",
        lenderPhone: "Lender Phone",
        lienholderName: "Lienholder",
        lienholderPhone: "Lienholder Phone",
        fundedDate: "Funded date",
        transactionType: "Transaction type",
        state: "State",
        aeStatus: "AE Status",
        daysInStatus: "Days in status",
        totalDays: "Total days",
        received: "Received",
        flags: "Flags",
        lastTouched: "Touched",
        workedOnBy: "Worked on by",
        severity: "Severity",
        followUpDate: "Follow Up",
        dealType: "Deal Type",
      },
      notFound: "Not found",
      neverTouched: "Never touched",
      accordion: {
        topPriority: "Top Priority",
        overNinetyDaysHeaderText: "Over 90 days",
        sixtyToNinetyDaysHeaderText: "60-90 days",
        underSixtyHeaderText: "Less than 60 days",
      },
      severities: {
        [StatusSeverity.Red]: "High",
        [StatusSeverity.Yellow]: "Medium",
        [StatusSeverity.Green]: "Low",
      },
    },
    associateTitleSpecialistDashboard: {
      navLabel: "ATS Queue",
      addToMyQueueButton: "Add to My Queue",
      titleOrderInformation: {
        existingLienholder: {
          label: "Existing lienholder",
          notFound: "Not found"
        },
        fundedDate: {
          label: "Funded date",
          missingValue: "Invalid DateTime",
        },
        aeStatus: {
          label: "AE Status",
        },
      },
      taskPanel: {
        datePicker: {
          label: "ETA Date",
          openDialogAriaLabel: "Choose Date",
        },
        taskGroupHeadings: {
          [TaskGroupType.CUSTOMER_CORE]: "Customer Core Tasks",
          [TaskGroupType.CUSTOMER_REMINDER]: "Customer Reminder Tasks",
          [TaskGroupType.CUSTOMER_URGENT_REMINDER]: "Customer Urgent Reminder Tasks",
          [TaskGroupType.LIENHOLDER_CORE]: "Lienholder Core Tasks",
          [TaskGroupType.LIENHOLDER_REMINDER]: "Lienholder Reminder Tasks",
        },
        docsReceived: {
          confirmation: {
            tooltipLabel: "Click here if the documents have arrived",
          },
          success: {
            label: "Documents received",
            button: {
              label: "Reopen Tasks",
            },
          },
        },
        docsReceivedModal: {
          heading: "Documents Received?",
          explanation: "Clicking “Yes” will disable all tasks for this record",
          cancelButton: {
            label: "NO",
          },
          submitButton: {
            label: "YES",
            loadingLabel: "SAVING...",
          },
        },
        reOpenTasksModal: {
          areYouSure: {
            heading: "Are you sure you want to re-open tasks?",
            additionalInformation: "This action will take you back to the last saved progress.",
            cancelButton: {
              label: "CANCEL",
            },
            continueButton: {
              loadingLabel: "RE-OPENING...",
              label: "YES, RE-OPEN TASKS",
            },
          },
          changeReason: {
            heading: "Why do you want to re-open tasks?",
            textArea: {
              placeHolder: "Type reason...",
            },
            cancelButton: {
              label: "CANCEL",
            },
            continueButton: {
              label: "DONE, RE-OPEN TASKS",
              loadingLabel: "RE-OPENING...",
            },
          },
        },
        undoTaskModal: {
          headingPrefix: "Undo Task",
          confirmButton: { label: "Undo" },
          changeReasonTextField: {
            placeHolder: "Reason for change.",
          },
          errorMessages: {
            emptyChangeReason: "Change Reason must not be empty",
          },
        },
        eta: {
          datePrefix: "ETA: ",
          saveButton: {
            label: "SAVE",
          },
          cancelButton: {
            label: "CANCEL",
          },
        },
      },
      taskNotes: {
        notesTitle: "NOTES",
        addNewNoteButton: {
          label: "ADD NEW NOTE",
        },
        newNoteTitle: "ADD NEW NOTE",
        newNotePlaceHolderText: "Type here...",
        saveNoteButton: {
          label: "SAVE NOTE",
          loadingLabel: "SAVING...",
        },
      },
    },
    associateTitleSpecialistMyQueue: {
      navLabel: "My Queue",
      accordion: {
        topPriorityHeaderText: "Top Priority",
        overNinetyDaysHeaderText: "Over 90 days",
        sixtyToNinetyDaysHeaderText: "60-90 days",
        underSixtyHeaderText: "Less than 60 days",
      },
      columnLabels: {
        recordId: "Record ID",
        assignee: "Assignee",
        lastName: "Last name",
        firstName: "First name",
        lastThenFirstName: "Last, first name",
        vin: "VIN",
        lender: "Lender",
        lienHolder: "Lienholder",
        fundedDate: "Funded date",
        transactionType: "Transaction type",
        state: "State",
        aeStatus: "AE Status",
        daysInStatus: "Days in status",
        totalDays: "Total days",
        received: "Received",
        flags: "Flags",
        lastTouched: "Last touched",
        workedOnBy: "Worked on by",
        etaSource: "ETA",
      },
    },
    titleSpecialistDashboard: {
      nav: {
        managerTabLabel: "All Queues",
        tsTabLabel: "My Queue",
      },
      updateProgressModal: {
        heading: "Update Record",
        formHeader: "What actions need to be taken?",
        updateProgressOptions: {
          submittedToTitleVendor: {
            label: "Submitted To Title Vendor",
            fields: {
              trackingNumber: "Tracking Number",
              uploadDateTime: "Upload Date/Time",
            },
          },
          onHoldReasonsResolvedAndSubmitted: {
            label: "On Hold Reasons Resolved And Submitted",
            fields: {
              issuesResolved: "Issues Resolved",
            },
          },
          missingOrExpiredDocuments: {
            label: "Missing / Expired Documents Or Information",
            fields: {
              missingOrExpiredDocuments: "List missing / expired documents or information",
            },
          },
        },
        cancel: {
          buttonText: "Cancel",
        },
        updateRecord: {
          buttonText: "Update Record",
        },
      },
      drawer: {
        markAs: {
          buttonText: "Update Status",
        },
        tasks: {
          duplicateTitleRequired: "Order a duplicate title",
        },
      },
      titleOrderInformation: {
        sectionTitles: {
          details: "Details",
          primaryCustomer: "Primary Borrower",
          lienholderContact: "Lienholder",
          lenderContact: "Lender",
          vehicle: "Vehicle",
          secondaryCustomer: "Co-Borrower",
        },
        customerContactDetails: {
          customerName: {
            label: "Name",
          },
          phone: {
            label: "Phone",
          },
          otherPhone: {
            label: "Other",
          },
          email: {
            label: "Email",
          },
          address: {
            label: "Address",
          },
          birthday: {
            label: "Birthday"
          }
        },
        fundedDate: {
          label: "Funded date",
          missingValue: "Not funded",
        },
        aeStatus: {
          label: "AE Status",
        },
        state: {
          label: "State",
        },
        lienHolderContactDetails: {
          lienHolderName: {
            label: "Name",
          },
          lienHolderPhone: {
            label: "Phone",
          },
          address: {
            label: "Address",
          },
        },
        lenderContactDetail: {
          lenderName: {
            label: "Name",
          },
          lenderPhone: {
            label: "Phone",
          },
          lenderAddress: {
            label: "Address",
          }
        },
        recordDetails: {
          vin: "VIN",
          referenceNumber: "Reference #",
          fundedDate: "Funded Date",
          state: "State",
          lienType: "Lien Type",
          aeStatus: "AE Status",
          followUpDate: "Follow Up Date",
          dealType: "Deal Type",
          titleVendor: "Title Vendor"
        },
      },
    },
    escalationsDashboard: {
      navLabel: "Escalations",
      requestEscalation: {
        openModal: {
          buttonText: "ESCALATE",
        },
        modal: {
          heading: "Escalate Record #",
          managerSelect: {
            label: "Manager",
            placeHolder: "Manager",
          },
          descriptionInput: {
            placeHolder: "Describe escalation",
          },
          errorMessages: {
            emptyDescription: "Description must not be empty",
          },
          cancel: {
            buttonText: "Cancel",
          },
          requestEscalation: {
            buttonText: "Request Escalation",
          },
        },
      },
      acceptEscalation: { buttonText: "Accept escalation" },
      resolveEscalation: {
        buttonText: "Resolve",
        modal: {
          heading: "Resolve escalation for Record #",
          cancelButtonText: "Cancel",
          confirmButtonText: "Mark as resolved",
        },
      },
      titleOrderInformation: {
        existingLienholder: {
          label: "Existing lienholder",
          missingValue: "Not found",
        },
        fundedDate: {
          label: "Funded date",
          missingValue: "Not funded",
        },
        aeStatus: {
          label: "AE Status",
        },
        state: {
          label: "State",
        },
        daysInFlagStatus: {
          label: "Days in flag status",
        },
        numberOfTimesOnHold: {
          label: "Number of times on hold",
        },
      },
    },
    createEstimate: {
      navLabel: "Create Estimate",
      errorHeader: "Error",
      errorOkayButtonText: "Okay",
      lookupRecordId: {
        header: "Get Estimate",
        enterRecordId: "Enter Record ID:",
        nextButtonLabel: "Next",
        apiErrorText: "An error occurred while looking up a title order by record ID",
        recordIdInputPlaceholder: "Record ID",
        recordIdValidationError: "Record ID should be a number",
      },
      confirmEstimate: {
        header: "Is this the record you're looking for?",
        backButtonText: "Go Back",
        confirmationText: "Does the above information look correct?",
        confirmButtonText: "YES, NEXT",
        transactionType: {
          options: {
            [WklsEstimateTransactionDisplayType.TitleAndRegistrationStateChange]:
              "Title and Registration - State Change",
            [WklsEstimateTransactionDisplayType.TitleAndRegistrationLeaseBuyout]:
              "Title and Registration - Lease Buyout",
            [WklsEstimateTransactionDisplayType.LienAdd]: "Lien Add",
            [WklsEstimateTransactionDisplayType.TitleOnly]: "Title Only",
            [WklsEstimateTransactionDisplayType.LienAddAddRemoveSpouse]: "Lien Add - Add/Remove Spouse",
            [WklsEstimateTransactionDisplayType.DuplicateTitle]: "Duplicate Title",
          },
          placeholder: "Select a Transaction Type",
          errorMessage: "Transaction Type is required",
        },
        state: {
          placeholder: "Select a State",
          errorMessage: "State is required",
        },
        county: {
          placeholder: "Select a County",
          errorMessage: "County is required",
        },
      },
      submitEstimate: {
        validation: {
          genericInvalid: "Field is not valid",
          genericRequired: "Field is required",
          dateMustBeInPast: "The date must be in the past",
          dateMustBeInFuture: "The date must be in the future",
          validationErrorButtonText: "Okay",
          validationErrorDialogTitle: "Validation Errors",
          validationErrorDialogText: "There are validation errors. Please scroll up and correct them.",
        },
        borrower: {
          groupName: "Borrower Information",
          primaryGroupName: "Primary Borrower Information",
          secondaryGroupName: "Secondary Borrower Information",
          name: "Name",
          lastName: "Last Name",
          firstName: "First Name",
          middleName: "Middle Name",
          secondaryCheckBoxLabel: "Do not include Borrower 2",
          relationshipToBorrowerLabel: "Relationship to Borrower 1",
          specialInstructionsPrefix: "Second Borrower's relationship to Primary Borrower is ",
        },
        vehicle: {
          groupName: "Vehicle Information",
          relationship: "Relationship between Buyer and Seller",
        },
        lienHolderGroupName: "Lien Holder Information",
        lienHolderDisplayName: "Lender Information",
        lienHolderHardcodedData: {
          name: "Auto Approve",
          address: "6200 Shingle Creek Parkway",
          state: "Minnesota",
          city: "Minneapolis",
          zip: "55345",
          eltNum: "000000",
        },
        specialInstructions: {
          groupName: "Estimate Special Instructions",
          additionalSpecialInstructions: "Any additional special instructions",
          titleRegistrationLeaseBuyOut: {
            isLboWithinExpirationDays: "Is our LBO letter good within 5 days of expiration to request?",
            haveBreakdown: "Do we have the breakdown?",
            isSalesTaxIncluded: "Is sales tax included in the breakdown?",
            salesTaxAmount: "Sales tax amount",
            purchaseType: "Type of purchase",
            purchasePrice: "Purchase price (Total purchase price listed minus sales tax)",
            nadaTradeValue: "NADA trade value",
          },
          titleRegistrationStateChange: {
            originalState: "Original State",
            newState: "New State",
            dateOfMove: "Date of move",
            dateOfVehiclePurchase: "Date of vehicle purchase",
            nadaTradeValue: "NADA Trade Value",
            salesTaxesIncludedAtPurchase: "Was sales tax included at the time of purchase?",
            isRegistrationCurrent: "Is registration current?",
          },
          lienAddRemoveSpouse: {
            nadaTradeValue: "NADA Trade Value",
          },
          additionalInstructionsPrefix: "Additional Instructions: ",
        },
      },
      errorPage: {
        header: "We’re sorry, something went wrong",
        message: "Please try again or you can request and estimate directly from WKLS",
        backToApproveEngineButton: "BACK TO APPROVE ENGINE",
        backToTitlesEngineButton: "BACK TO TITLE ENGINE",
      },
    },
    reporting: {
      manualReview: {
        missingLeadInfoPlaceholder: "No AE Lead Found",
      },
    },
    errorPage: {
      message: "Uh oh! Looks like there is an issue with our system right now. Don’t worry, we’re working on it.",
      prompt: "Please check back shortly",
    },
    baseDrawer: {
      tabLabels: {
        details: "Details",
        tasks: "Tasks",
        activity: "Activity",
        documents: "Documents",
        notes: "Notes",
      },
      notes: {
        addNoteButton: "ADD NEW NOTE",
        newNoteTitle: "ADD NEW NOTE",
        newNotePlaceHolderText: "Type here...",
        saveNoteButton: {
          label: "SAVE NOTE",
          loadingLabel: "SAVING...",
        },
      },
      details: {
        actionsList: {
          header: "Actions",
          openInAEButtonText: "Open Record in AE",
          saveButtonText: "Save Actions",
          escalate: {
            buttonText: "Escalate",
          },
          actions: {
            [ActionListType.initialPacketReviewedCompletedAndSubmitted]: "Initial Packet Reviewed - Completed and Submitted",
            [ActionListType.initialPacketReviewedMissingDocument]: "Initial Packet Reviewed - Missing Documentation",
            [ActionListType.initialContactedInternalTeamMember]: "Contacted internal team member",
            [ActionListType.initialContactedCustomer]: "Contacted customer",
            [ActionListType.initialContactedVendor]: "Contacted vendor",
            [ActionListType.researchedPendingOnHoldPacketAndSubmitted]: "Researched Pending or On Hold Packet and Submitted",
            [ActionListType.engagedInEscalationProcess]: "Engaged in Escalation Process",
            [ActionListType.engagedCustomerSentEmailText]: "Sent email/text to customer",
            [ActionListType.engagedCustomerCalledAndMadeContact]: "Called customer - made contact",
            [ActionListType.engagedCustomerCalledAndDidNotReach]: "Called customer - did not reach",
            [ActionListType.engagedCustomerSentL1]: "Sent L1",
            [ActionListType.engagedCustomerSentL2]: "Sent L2",
            [ActionListType.escalatedToLeadership]: "Escalated to Leadership",
            [ActionListType.followUpChecked]: "Requires Follow Up",
            [ActionListType.requiresFollowUpDate]: "Follow Up date",
            [ActionListType.otherChecked]: "Other",
            [ActionListType.otherActionDescription]: "Other Action Description",
            [ActionListType.notesUpdateChecked]: "Updated notes in AE"
          },
          refreshTooltip: "Refresh"
        },
      },
    },
  };
}
