import { useAuth } from "../../../Auth/AuthProvider";
import { DateTime } from "luxon";
import { getCopy } from "../../../../authoring/copy";
import { getActionListings } from "../../../../networking/actionListing/TitlesWorkflowActionListingApi";
import { ActionListingWithAssociations, ActionListType, ActionListValues } from "../../../../networking/actionListing/types";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import { TimelineComponent, TimelineItems } from "@auto-approve/auto-approve-ui-library";
import { formatDateWithoutTimestamp } from "../../helpers";

type ActionListActivityProps = {
    titleOrderId: number;
    triggerRefresh: number;
};

type ActionListingActivity = {
    actionListingId: number;
    key: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    description: string;
}

const copy = getCopy().baseDrawer.details.actionsList;

const convertActivityListingSingle = (actionListing: ActionListingWithAssociations, key: ActionListType, description: string): ActionListingActivity => (
    {
        key,
        actionListingId: actionListing.id,
        firstName: actionListing.user.firstName,
        lastName: actionListing.user.lastName,
        createdAt: actionListing.createdAt,
        description
    }
);

const convertActivityListing = (actionListing: ActionListingWithAssociations): ActionListingActivity[] => {
    const result: ActionListingActivity[] = [];

    ActionListValues.forEach(alv => {
        const value = _.get(actionListing, alv.key, false);
        if (value) {
            switch (alv.key) {
                case ActionListType.requiresFollowUpDate:
                    if (actionListing.requiresFollowUpDate) {
                        result.push(convertActivityListingSingle(actionListing,
                            alv.key,
                            `Requires follow up by ${formatDateWithoutTimestamp(`${actionListing.requiresFollowUpDate}`)}`));
                    }
                    break;
                case ActionListType.otherActionDescription:
                    if (actionListing.otherActionDescription) {
                        result.push(convertActivityListingSingle(actionListing, 
                            alv.key,
                            `Other: ${actionListing.otherActionDescription}`));
                    }
                    break;
                default:
                    result.push(convertActivityListingSingle(actionListing, alv.key, copy.actions[alv.key]));
            }
        }
    });

    result.push(convertActivityListingSingle(actionListing, ActionListType.notesUpdateChecked, `Acknowledged: ${copy.actions.notesUpdateChecked}`));

    return result;
};

export const mapActivityListing = (actionListings: ActionListingWithAssociations[]): ActionListingActivity[] => (
    actionListings.flatMap(convertActivityListing)
        .sort((a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis())
);

export function ActionListActivity(props: ActionListActivityProps) {
    const { user } = useAuth();
    const queryClient = useQueryClient();

    const getActionListWrapper = async (titleOrderId = props.titleOrderId, _skip = 0): Promise<TimelineItems> => {
        const actionListingResponse = await getActionListings(parseInt(titleOrderId.toString()), user?.token ?? "");
        const mappedActionListing = mapActivityListing(actionListingResponse);
        const actionListItems = mappedActionListing.map((value) => {
            return {
                id: `${value.actionListingId}.${value.key}`,
                createdAt: value.createdAt,
                description: value.description,
                createdByName: value.firstName + " " + value.lastName,
            };
        });
        return {
            id: "",
            data: actionListItems,
            any: null,
            skip: 0,
            take: actionListItems.length,
            count: actionListItems.length,
        };
    };

    return (
        <TimelineComponent
            queryClient={queryClient}
            get={(id, skip) => getActionListWrapper(parseInt(id), skip)}
            queryKey="actionListings"
            id={`${props.titleOrderId}`}
            triggerRefresh={props.triggerRefresh}
        />
    );
}
