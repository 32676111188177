import { Role } from "@auto-approve/auto-approve-ui-library";
import { isBoolean } from "lodash";
import { DateTime } from "luxon";

export enum ActionListType {
  initialPacketReviewedCompletedAndSubmitted = "initialPacketReviewedCompletedAndSubmitted",

  initialPacketReviewedMissingDocument = "initialPacketReviewedMissingDocument",
  initialContactedInternalTeamMember = "initialContactedInternalTeamMember",
  initialContactedCustomer = "initialContactedCustomer",
  initialContactedVendor = "initialContactedVendor",

  researchedPendingOnHoldPacketAndSubmitted = "researchedPendingOnHoldPacketAndSubmitted",

  engagedInEscalationProcess = "engagedInEscalationProcess",
  engagedCustomerSentEmailText = "engagedCustomerSentEmailText",
  engagedCustomerCalledAndMadeContact = "engagedCustomerCalledAndMadeContact",
  engagedCustomerCalledAndDidNotReach = "engagedCustomerCalledAndDidNotReach",
  engagedCustomerSentL1 = "engagedCustomerSentL1",
  engagedCustomerSentL2 = "engagedCustomerSentL2",

  escalatedToLeadership = "escalatedToLeadership",

  followUpChecked = "followUpChecked",
  requiresFollowUpDate = "requiresFollowUpDate",

  otherChecked = "otherChecked",
  otherActionDescription = "otherActionDescription",

  notesUpdateChecked = "notesUpdateChecked",
}

// TODO - improve this to have more concrete types
export type ActionListing = {
  titleOrderId: ActionListValueType;
  [ActionListType.initialPacketReviewedCompletedAndSubmitted]: ActionListValueType;
  [ActionListType.initialContactedInternalTeamMember]: ActionListValueType;
  [ActionListType.initialContactedCustomer]: ActionListValueType;
  [ActionListType.initialContactedVendor]: ActionListValueType;
  [ActionListType.researchedPendingOnHoldPacketAndSubmitted]: ActionListValueType;
  [ActionListType.engagedCustomerSentEmailText]: ActionListValueType;
  [ActionListType.engagedCustomerCalledAndMadeContact]: ActionListValueType;
  [ActionListType.engagedCustomerCalledAndDidNotReach]: ActionListValueType;
  [ActionListType.engagedCustomerSentL1]: ActionListValueType;
  [ActionListType.engagedCustomerSentL2]: ActionListValueType;
  [ActionListType.escalatedToLeadership]: ActionListValueType;
  [ActionListType.requiresFollowUpDate]?: ActionListValueType;
  [ActionListType.otherActionDescription]?: ActionListValueType;
};

export type ActionListingWithAssociations = ActionListing & {
  id: number;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number,
    aeUserId?: string,
    email: string,
    firstName: string,
    lastName: string,
    role?: Role;
  }
};

export type ActionListValueComponentType = "text" | "checkbox" | "date" | "radio";
export type ActionListValueType = string | boolean | DateTime | null | undefined | number;
export type ActionListValue = {
  key: ActionListType,
  parent?: ActionListType, // children can't be set without parents (birectional)
  children?: ActionListType[], // parents can't be without their siblings (bidirectional)
  siblings?: ActionListType[], // siblings are optional unless specified (unidirectional)
  type: ActionListValueComponentType,
  required?: boolean,
  default: ActionListValueType,
  value?: ActionListValueType,
  footer?: boolean,
  disabled?: boolean,
  disabledTooltip?: string
};

// currently not used but can be added to show by default if asked
export const DEFAULT_DISABLED_CHILD_TOOLTIP = "Select the action to enable.";

export const ActionListValues: ActionListValue[] = [
  {
    key: ActionListType.initialPacketReviewedCompletedAndSubmitted,
    type: "radio",
    default: false
  },
  // Missing Documentation Group
  {
    key: ActionListType.initialPacketReviewedMissingDocument,
    children: [
      ActionListType.initialContactedInternalTeamMember,
      ActionListType.initialContactedCustomer,
      ActionListType.initialContactedVendor
    ],
    type: "radio",
    default: false
  },
  {
    key: ActionListType.initialContactedInternalTeamMember,
    parent: ActionListType.initialPacketReviewedMissingDocument,
    type: "checkbox",
    default: false
  },
  {
    key: ActionListType.initialContactedCustomer,
    parent: ActionListType.initialPacketReviewedMissingDocument,
    type: "checkbox",
    default: false
  },
  {
    key: ActionListType.initialContactedVendor,
    parent: ActionListType.initialPacketReviewedMissingDocument,
    type: "checkbox",
    default: false
  },
  // Research Pending
  {
    key: ActionListType.researchedPendingOnHoldPacketAndSubmitted,
    type: "radio",
    default: false
  },
  // Escalation Process Group
  {
    key: ActionListType.engagedInEscalationProcess,
    children: [
      ActionListType.engagedCustomerSentEmailText,
      ActionListType.engagedCustomerCalledAndMadeContact,
      ActionListType.engagedCustomerCalledAndDidNotReach,
      ActionListType.engagedCustomerSentL1,
      ActionListType.engagedCustomerSentL2
    ],
    type: "radio",
    default: false
  },
  {
    key: ActionListType.engagedCustomerSentEmailText,
    parent: ActionListType.engagedInEscalationProcess,
    type: "checkbox",
    default: false
  },
  {
    key: ActionListType.engagedCustomerCalledAndMadeContact,
    parent: ActionListType.engagedInEscalationProcess,
    type: "checkbox",
    default: false
  },
  {
    key: ActionListType.engagedCustomerCalledAndDidNotReach,
    parent: ActionListType.engagedInEscalationProcess,
    type: "checkbox",
    default: false
  },
  {
    key: ActionListType.engagedCustomerSentL1,
    parent: ActionListType.engagedInEscalationProcess,
    type: "checkbox",
    default: false
  },
  {
    key: ActionListType.engagedCustomerSentL2,
    parent: ActionListType.engagedInEscalationProcess,
    type: "checkbox",
    default: false
  },
  // Escalated to Leadership
  {
    key: ActionListType.escalatedToLeadership,
    type: "radio",
    default: false
  },
  // Other w/ description
  {
    key: ActionListType.otherChecked,
    children: [ActionListType.otherActionDescription],
    type: "radio",
    default: false
  },
  {
    key: ActionListType.otherActionDescription,
    parent: ActionListType.otherChecked,
    type: "text",
    required: true,
    default: undefined
  },
  // Requires Follow Up
  {
    key: ActionListType.followUpChecked,
    children: [ActionListType.requiresFollowUpDate],
    type: "checkbox",
    default: true,
    required: true,
    disabled: true,
    disabledTooltip: "Follow Up Date is now required for all actions."
  },
  {
    key: ActionListType.requiresFollowUpDate,
    parent: ActionListType.followUpChecked,
    type: "date",
    default: undefined,
    required: true,
  },
  // updated notes
  {
    key: ActionListType.notesUpdateChecked,
    type: "checkbox",
    default: false,
    required: true,
    footer: true
  },
];

export const mapActionListValuesToActionListing = (actionListValues: ActionListValue[], titleOrderId?: number): ActionListing => {
  const actionListing: ActionListing = {
    titleOrderId,
    [ActionListType.initialPacketReviewedCompletedAndSubmitted]: null,
    [ActionListType.initialContactedInternalTeamMember]: null,
    [ActionListType.initialContactedCustomer]: null,
    [ActionListType.initialContactedVendor]: null,
    [ActionListType.researchedPendingOnHoldPacketAndSubmitted]: null,
    [ActionListType.engagedCustomerSentEmailText]: null,
    [ActionListType.engagedCustomerCalledAndMadeContact]: null,
    [ActionListType.engagedCustomerCalledAndDidNotReach]: null,
    [ActionListType.engagedCustomerSentL1]: null,
    [ActionListType.engagedCustomerSentL2]: null,
    [ActionListType.escalatedToLeadership]: null,
    [ActionListType.requiresFollowUpDate]: undefined,
    [ActionListType.otherActionDescription]: undefined
  };

  actionListValues.forEach(alv => {
    if (alv.key in actionListing)
      actionListing[alv.key as keyof ActionListing] = alv?.value ?? alv.default;
  });
  return actionListing;
};

export const isValuePopulated = (alv: ActionListValue): boolean => {
  if (alv.value != null) {
    return isBoolean(alv.value) ? alv.value : !!alv.value;
  }
  if (alv.default != null) {
    return isBoolean(alv.default) ? alv.default : !!alv.default;
  }
  return false;
};

export const isActionDisabled = (currentValues: ActionListValue[], parent: ActionListValue, child?: ActionListValue) => {
  // children cannot be set without parents
  if (child) return !isValuePopulated(parent);
  if (parent.siblings) {
    // unable to set value without at least one sibling
    if (!parent.siblings.some(siblingKey =>
      currentValues.some(potentialSibling => potentialSibling.key === siblingKey && potentialSibling.value)))
      return true;
  }
  return false;
};

export const getSelectedRootValue = (actionListValues: ActionListValue[]): ActionListValue | null => {
  const selected = actionListValues
    .filter(value => (value.type === "radio" && value.value) || (value.type === "checkbox" && value.value));

  // ensure one selected radio value
  if (selected.length === 0) return null;

  return selected[0];
};

export const isActionFormValid = (actionListValues: ActionListValue[]): boolean => {
  const selected = getSelectedRootValue(actionListValues);

  if (!selected) return false;

  // check at least one child field is valid for radio selection
  if (selected.children) {
    if (!actionListValues.some(value => selected.children?.includes(value.key) && value.value))
      return false;
  }

  // ensure all required parent fields have values
  const validFields = actionListValues.filter(value => {
    // ensure all required child fields are populated if parent is selected
    if (!value.parent && value.children && value.value) {
      if (!actionListValues
        .filter(potentialChild => potentialChild.parent === value.key && potentialChild.required)
        .every(child => child.value)) {
        return false;
      }
    }
    // ensure root required fields are set (i.e. notes) 
    if (value.required) {
      if (!value.children && !value.parent && !value.value) return false;
    }
    return true;
  });

  return validFields.length === actionListValues.length;
};

export const tryConvertDate = (value: unknown, defaultValue?: DateTime): DateTime | undefined => {
  try {
    switch (typeof value) {
      case "string": return DateTime.fromISO(value);
      case "number": return DateTime.fromSeconds(value);
      case "object": return DateTime.fromJSDate(new Date(`${value}`));
      case "bigint":
      case "symbol":
      case "function":
      case "boolean":
      case "undefined":
      default: return defaultValue;
    }
  } catch {
    return defaultValue;
  }
};


export const getDefaultActions = (followUpDate?: ActionListValueType) => [...ActionListValues].map((alv) => {
  if (alv.key === ActionListType.requiresFollowUpDate) return {...alv, value: followUpDate};
  return {...alv, value: alv.default};
});