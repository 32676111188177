import { DateTime } from "luxon";
import { getCopy } from "../../authoring/copy";
import { now } from "../../contexts/time/now";
import { nonNull } from "../../helpers/nulls";
import { CreateEstimateTitleOrder, DashboardTitleOrder } from "../core/types";
import { TitlesEngineCustomer, TitlesEngineTitleOrderWithAssociations } from "./types";
import { titleVendorDefault } from "../../helpers/defaults";

export const toCreateEstimateTitleOrder = (titleOrder: TitlesEngineTitleOrderWithAssociations)
: CreateEstimateTitleOrder => ({
  aeRecordId: titleOrder.aeRecordId,
  aeLeadId: titleOrder.aeLeadId,
  transactionType: titleOrder.transactionType,
  primaryCustomer: titleOrder.primaryCustomer ? toCreateEstimateCustomer(titleOrder.primaryCustomer) : undefined,
  secondaryCustomer: titleOrder.secondaryCustomer ? toCreateEstimateCustomer(titleOrder.secondaryCustomer) : undefined,
  lienHolder: toCreateEstimateLienHolder(),
  vehicle: titleOrder.vehicle,
  vendor: titleVendorDefault(titleOrder)
});

const lienHolderHardcodedData = getCopy().createEstimate.submitEstimate.lienHolderHardcodedData;
// Hard coding these current lienholder values below per this ticket: https://autoapprovetech.atlassian.net/browse/AAT-358
// They don't need actual current lienholder address and name at this stage of the application
// If these values are overridden in the UI it still defaults to these values on the backend so estimates submit with these hardcoaded values: https://gitlab.com/auto-approve/approve-engine/titles-engine/titles-workflow-server/-/blob/main/src/contexts/estimates/wk/api/woltersKluwersAPI.ts#L317
export const toCreateEstimateLienHolder = () => ({
  name: lienHolderHardcodedData.name,
  // TODO - determine if these are needed (currently unavailable on lender)
  lienDate: undefined,
  requestedDueDate: undefined,
  eltNum: lienHolderHardcodedData.eltNum,
  address: {
    street1: lienHolderHardcodedData.address,
    street2: "",
    state: lienHolderHardcodedData.state,
    city: lienHolderHardcodedData.city,
    zip: lienHolderHardcodedData.zip
  }
});

const toCreateEstimateCustomer = (customer: TitlesEngineCustomer) => ({
    firstName: customer.firstName,
    middleName: customer.middleName,
    lastName: customer.lastName,
    birthday: customer.birthday,
    phoneNumbers: customer.homePhone ? [customer.homePhone] : [],
    email: customer.email,
    type: customer.type,
    ssn: customer.ssn,
    driversLicenseNum: customer.driversLicenseNum,
    federalEmployerId: customer.federalEmployerId,
    address: {
      street1: customer.address.street1,
      street2: customer.address.street2,
      state: customer.address.state,
      city: customer.address.city,
      zip: customer.address.zip
    },
    relationshipToPrimary: customer.relationshipToPrimary,
});

export const toDashboardTitleOrder = (titleOrder: TitlesEngineTitleOrderWithAssociations)
: DashboardTitleOrder => ({
  titleOrderId: nonNull(titleOrder.id),
  recordId: titleOrder.aeRecordId,
  aeLeadId: titleOrder.aeLeadId,
  customer: {
    firstName: titleOrder.primaryCustomer?.firstName,
    middleName: titleOrder.primaryCustomer?.middleName,
    lastName: titleOrder.primaryCustomer?.firstName,
    birthday: titleOrder.primaryCustomer?.birthday,
    phone: titleOrder.primaryCustomer?.phone,
  },
  dealType: titleOrder.dealType,
  vin: titleOrder.vehicle.vin,
  state: titleOrder.state,
  lender: {
    id: titleOrder.lender?.id,
    name: titleOrder.lender?.name ?? "",
    phone: titleOrder.lender?.phone ?? ""
  },
  transactionType: titleOrder.transactionType ?? "",
  status: titleOrder.newAeStatus,
  aeStatusSeverity: titleOrder.statusSeverity,
  daysInStatus: getDaysInStatus(titleOrder),
  totalDays: getTotalDays(titleOrder),
  lienholder: {
    id: titleOrder.lienHolder.id,
    name: titleOrder.lienHolder.name,
    phone: titleOrder.lienHolder.phone ?? "",
  },
  fundedDate: titleOrder.fundedDate ?? null,
  aeStatus: titleOrder.newAeStatus,
  owner: {
    firstName: titleOrder.assignedTitleSpecialist?.firstName,
    lastName: titleOrder.assignedTitleSpecialist?.lastName,
  },
  lastTouched: titleOrder.lastTouched,
  followUpDate: titleOrder.followUpDate,
  // TODO - remove once ATS dashboard is completed
  aeComments: [],
  vendor: titleVendorDefault(titleOrder),
  vendorFee: titleOrder.vendorFee ?? null
});

const getDaysInStatus = (titleOrder: TitlesEngineTitleOrderWithAssociations): number => {
const date = DateTime.fromISO(titleOrder.aeStatusTimeStamp);
return Math.floor(now().diff(date, "days").days);
};

const getTotalDays = (titleOrder: TitlesEngineTitleOrderWithAssociations): number => {
const fundedDate = DateTime.fromISO(titleOrder.fundedDate ?? "");
return fundedDate?.isValid
  ? Math.floor(Math.abs(fundedDate.diffNow("days").days))
  : -1;
};
