import { useEffect, useState } from "react";
import { convertTitleOrderToDashboardRow, toColumnFilters } from "../types";
import { getManagerDashboardData } from "../../../networking/TitlesWorkflowAPI";
import { useAuth } from "../../Auth/AuthProvider";
import { useDebounce } from "../../../Hooks/customHooks";
import { useTitleSpecialistDashboardForManager } from "../context/TitleSpecialistDashboardForManagerContext";
import { useSharedDashboard } from "../context/SharedDashboardContext";
import { toAPIFilter } from "./core/converters";
import { getTitleSpecialistDashboardForManagerColumns } from "../TitleOrdersDashboard/columns";
import { TitleOrdersDashboard } from "../TitleOrdersDashboard/TitleOrdersDashboard";

export function TitleSpecialistDashboardForManager() {
  const { user } = useAuth();
  const { allRows, setLoading, sortOptions, setAllRows, filterModel, showCompleted } =
    useTitleSpecialistDashboardForManager();
  const { dashboardUpdate, searchTerm } = useSharedDashboard();
  const [totalRows, setTotalRows] = useState(0);
  const [totalFilteredRows, setTotalFilteredRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [skipRows, setSkipRows] = useState(0);
  const debouncedFilters = useDebounce(filterModel, 750);

  useEffect(() => {
    if (!user) return;

    const columnSortOrders = sortOptions.map((sortOption) => {
      return {
        column: sortOption.sortBy,
        order: sortOption.sortOrder,
      };
    });

    //Grouping for manager will be handled in TE-1027.

    // const totalDaysGroupings: TitleOrderTotalDaysGrouping[] | undefined =
    //   group === "group"
    //     ? [
    //         { minTotalDaysInclusive: 0, maxTotalDaysInclusive: 59 },
    //         { minTotalDaysInclusive: 60, maxTotalDaysInclusive: 90 },
    //         { minTotalDaysInclusive: 91, maxTotalDaysInclusive: Infinity },
    //       ]
    //     : undefined;

    setLoading(true);
    getManagerDashboardData({
      token: user.token,
      query: {
        searchString: searchTerm,
        columnFilters: toColumnFilters(debouncedFilters).flatMap((debouncedFilter) =>
          toAPIFilter({ column: debouncedFilter.columnName, filterValue: debouncedFilter.value })
        ),
        columnSortOrders,
        showCompleted,
        //totalDaysGroupings: totalDaysGroupings,
        take: rowsPerPage,
        skip: skipRows,
      },
    })
      .then((response) => {
        setAllRows(response.titleOrders.map((order) => convertTitleOrderToDashboardRow(order)));
        setTotalRows(response.countInDb);
        setTotalFilteredRows(response.countInDbWithFilters);
      })
      .finally(() => setLoading(false));
  }, [
    user,
    searchTerm,
    debouncedFilters,
    sortOptions,
    rowsPerPage,
    skipRows,
    dashboardUpdate,
    setLoading,
    setAllRows,
    showCompleted
  ]);
  return (
    <TitleOrdersDashboard
      rows={allRows}
      setRows={setAllRows}
      totalRowsFromServer={totalRows}
      totalFilteredRowsFromServer={totalFilteredRows}
      columns={getTitleSpecialistDashboardForManagerColumns}
      mode={"server"}
      useData={useTitleSpecialistDashboardForManager}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setSkipRows={setSkipRows}
    />
  );
}
