import React, { useState } from "react";
import {
  GridColumns,
  GridFilterModel,
  GridSortModel,
  gridVisibleRowCountSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { EscalationsDashboardContextType } from "../context/types";
import { OrderWithEscalation } from "./types";
import { fieldsWithDisabledCellClick } from "../helpers";
import { toGridSortModel, toSortOptions } from "../types";

export type EscalationDashboardTableProps = {
  rows: OrderWithEscalation[];
  totalRows: number;
  getColumns: () => GridColumns<OrderWithEscalation> &
    {
      field: keyof OrderWithEscalation | "escalation.acceptedAt" | "escalation.requestedAt" | "customerName" | "lenderName";
    }[];
  defaultPageSize: number;
  rowsPerPageOptions: number[];
  filterMode: "client" | "server";
  sortingMode: "client" | "server";
  paginationMode: "client" | "server";
  rowsPerPage?: number;
  setRowsPerPage?: (pageSize: number) => void;
  setSkipRows?: React.Dispatch<React.SetStateAction<number>>;
  setVisibleRowCount?: (count: number) => void;
  useData: () => EscalationsDashboardContextType;
  onCellClick?: (row: OrderWithEscalation) => void;
};

export const EscalationDashboardTable = React.memo(function EscalationDashboardTable(
  props: EscalationDashboardTableProps
) {
  const apiRef = useGridApiRef();
  const { setFilterModel, setSortOptions, sortOptions, loading, filterModel: filter } = props.useData();
  const [page, setPage] = useState(0);

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFilterModel(filterModel);
    setPage(0);
  };

  const onSortChange = (sortModel: GridSortModel) => {
    setSortOptions(toSortOptions(sortModel));
  };

  const onPageChange = (page: number) => {
    if (!props.setSkipRows || !props.rowsPerPage) return;
    setPage(page);
    props.setSkipRows(page * props.rowsPerPage);
  };

  const onPageSizeChange = (pageSize: number) => {
    if (!props.setRowsPerPage || !props.setSkipRows) return;
    props.setRowsPerPage(pageSize);
    props.setSkipRows(page * pageSize);
  };

  const sortModelValue = sortOptions.map((sortItem) => {
    return {
      field: sortItem.sortBy,
      sort: sortItem.sortOrder,
    };
  });

  return (
    <div className="my-4 font-roboto">
      <div className="min-h-full">
        <div className="flex h-full">
          <DataGridPremium
            pagination={true}
            disableRowGrouping={true}
            loading={loading}
            autoHeight={true}
            rows={props.rows}
            filterMode={props.filterMode}
            onFilterModelChange={onFilterChange}
            sortingMode={props.sortingMode}
            onSortModelChange={onSortChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            columns={getColumns()}
            initialState={{
              pagination: {
                pageSize: props.defaultPageSize,
              },
              filter: {
                filterModel: filter,
              },
              sorting: {
                sortModel: toGridSortModel(sortOptions),
              },
            }}
            filterModel={filter}
            sortModel={sortModelValue}
            rowsPerPageOptions={props.rowsPerPageOptions}
            paginationMode={props.paginationMode}
            rowCount={props.totalRows}
            onStateChange={
              props.setVisibleRowCount
                ? (state) => {
                    const count = gridVisibleRowCountSelector(state, apiRef.current.instanceId);
                    props.setVisibleRowCount?.(count);
                  }
                : undefined
            }
            onCellClick={(params) => {
              if (!props.onCellClick) return undefined;
              if (!fieldsWithDisabledCellClick.includes(params.field)) {
                props.onCellClick(params.row);
              }
            }}
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  function getColumns() {
    return [...props.getColumns()];
  }
});
