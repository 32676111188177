import { OrderWithEscalation } from "../../components/Dashboards/EscalationsDashboard/types";
import { isTitlesEngineEscalation, TitlesEngineEscalation, WORKFLOW_HOST_URL } from "../core/types";
import axios, { AxiosRequestConfig } from "axios";

export async function acceptEscalation(
  token: string,
  orderWithEscalation: OrderWithEscalation
): Promise<TitlesEngineEscalation> {
  const url = `${WORKFLOW_HOST_URL}/escalation-accepted`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const data = {
    aeLeadId: orderWithEscalation.aeLeadId,
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      if (isTitlesEngineEscalation(response.data)) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(
        new Error(
          `Expected response payload type to be a Titles Engine Escalation response.\nReceived: ${response?.data}`
        )
      );
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

export async function resolveEscalation(
  token: string,
  resolutionAttrs: {
    description: string;
    aeLeadId: string;
  }
): Promise<TitlesEngineEscalation> {
  const url = `${WORKFLOW_HOST_URL}/escalation-resolved`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios
    .post(url, resolutionAttrs, config)
    .then((response) => {
      if (isTitlesEngineEscalation(response.data)) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(
        new Error(
          `Expected response payload type to be a Titles Engine Escalation response.\nReceived: ${response?.data}`
        )
      );
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}
