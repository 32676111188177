import { Box } from "@mui/material";
import {
    DocumentsPanel as DocumentsPanelUI,
    DocumentsPanelProvider,
    ExecutionResultModel,
    RecipeCardWithDocument,
    DocumentHistoryModel,
    DocumentInformation,
    FileUploadRequest,
    DocumentEmbedLink,
    RecipeCardModel,
    EditDocumentMetadataModel,
    UploadDocumentMetadataModel,
    GetDownloadUrlRequest,
    GetDownloadUrlResponse,
    AeNote,
    AeNotes,
    getPermissionActionSet,
    TimelineItems,
    DocumentReviewTabEnum,
    ContractRequest,
    ContractStatusRequest,
    ContractStatusResponse,
    SendDocumentsToLenderRequest,
} from "@auto-approve/auto-approve-ui-library";
import {
    deleteDocument,
    getDocumentsHistory as getDocumentsHistoryFromService,
    getDocumentEmbedLink as getDocumentEmbedLinkFromService,
    getDownloadUrls as getDownloadUrlsFromService,
    patchDocumentMetadata,
    postGenerateDocumentUploadLink as generateDocumentUploadLinkFromService,
    postUploadDocumentFile as uploadDocumentFileFromService,
    putReplaceDocumentFile as replaceDocumentFileFromService,
    getDocumentActivity,
} from "../../networking/recipeCards/DocumentsService";
import {
    createRecipeCards,
    deleteRecipeCard,
    getRecipeCards
} from "../../networking/recipeCards/RecipeCardService";
import { AxiosError } from "axios";
import { useAuth } from "../Auth/AuthProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TitlesEngineTitleOrderWithAssociations } from "../../networking/title_orders/types";
import { toLeadModel } from "./converters";
import { environmentVariables } from "../../config";
import { createNote, getNotes } from "../../networking/aeLeadNotes/TitlesWorkflowLeadNotesApi";
import { useQueryClient } from "@tanstack/react-query";
import { PermissionAction } from "@auto-approve/auto-approve-ui-library";
import { generateFundingPacket } from "../../networking/fundingPacket/DocGenerationFundingPacketAPI";
import { contract, getContractStatus, sendDocuments } from "../../networking/loanApplcation/LoanApplicationService";
import { splitLenderDocument } from "../../networking/core/splitLenderDocument/SplitLenderDocumentAPI";
import { createElectronicSignatureEnvelopeView } from "../../networking/docuSign/DocGenerationDocuSignAPI";

export type DocumentsSectionProps = {
    leadOid: string;
    // this prop drives if header will show
    lead?: TitlesEngineTitleOrderWithAssociations;
};

const DocumentsSection = (props: DocumentsSectionProps) => {
    const queryClient = useQueryClient();
    const { leadOid, lead } = props;
    const { user } = useAuth();
    const flags = useFlags();
    const token = user?.token ?? "";
    const useStipsV2 = Boolean(flags.dmEnableStipulationServiceV2);

    const createStipulations = async (
        recipeCards: Array<RecipeCardModel>,
        abortController: AbortController
    ): Promise<ExecutionResultModel<undefined>> => {
        try {
            await createRecipeCards(leadOid, recipeCards, useStipsV2, abortController.signal, token);
            return { error: false, message: "", response: undefined };
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to create documents, please try again later",
            };
        }
    };

    const generateDocumentUploadLink = async (
        abortController: AbortController
    ): Promise<ExecutionResultModel<FileUploadRequest>> => {
        try {
            const result = await generateDocumentUploadLinkFromService(leadOid, abortController.signal, token);
            return { error: false, message: "", response: result };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to generate document upload link, please try again later",
            };
        }
    };

    const deleteRow = async (
        recipeCardWithDocument: RecipeCardWithDocument,
        abortController: AbortController,
    ): Promise<ExecutionResultModel<undefined>> => {
        const { recipeCard, document } = recipeCardWithDocument;
        try {
            if (recipeCard?.id) {
                await deleteRecipeCard(recipeCard.id, useStipsV2, abortController.signal, token, document?.id);
            } else if (document) {
                await deleteDocument(document.id, abortController.signal, token);
            } else {
                throw new Error("Must include custom recipe card id and/or document when deleting");
            }
            return { error: false, message: "" };
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to delete the document.",
            };
        }
    };

    const getDocumentEmbedLink = async (
        documentId: string,
        abortController: AbortController
    ): Promise<ExecutionResultModel<DocumentEmbedLink>> => {
        try {
            const result = await getDocumentEmbedLinkFromService(documentId, abortController.signal, token);
            return { error: false, message: "", response: result };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to retrieve the document preview from server, please try again later",
            };
        }
    };

    const getDownloadUrls = async (
        request: GetDownloadUrlRequest,
        abortController: AbortController
    ): Promise<ExecutionResultModel<GetDownloadUrlResponse>> => {
        try {
            const response = await getDownloadUrlsFromService(request, abortController.signal, token);
            return { error: false, message: "", response };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to retrieve the download url from server, please try again later",
            };
        }
    };

    const getDocuments = async (
        abortController: AbortController
    ): Promise<ExecutionResultModel<Array<RecipeCardWithDocument>>> => {
        try {
            const result = await getRecipeCards(leadOid, lead?.lender?.name ?? "", useStipsV2, abortController.signal, token);
            return { error: false, message: "", response: result };
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 404) {
                return {
                    error: true,
                    message: "",
                };
            }
            return {
                error: true,
                message:
                    "An error occurred while trying to retrieve documents from server, please try again later",
            };
        }
    };

    const getDocumentsHistory = async (
        documentIds: Array<string>,
        abortController: AbortController
    ): Promise<ExecutionResultModel<Array<DocumentHistoryModel>>> => {
        try {
            const result = await getDocumentsHistoryFromService(documentIds, abortController.signal, token);
            return { error: false, message: "", response: result };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to retrieve documents history from server, please try again later",
            };
        }
    };

    const replaceDocumentFile = async (
        documentId: string,
        file: File,
        abortController: AbortController
    ): Promise<ExecutionResultModel<DocumentInformation>> => {
        try {
            const result = await replaceDocumentFileFromService(documentId, file, abortController.signal, token);
            return { error: false, message: "", response: result };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to upload file document to the server, please try again later",
            };
        }
    };

    const updateDocumentMetadata = async (
        documentId: string,
        updatedMetadata: EditDocumentMetadataModel,
        abortController: AbortController,
    ): Promise<ExecutionResultModel<undefined>> => {
        try {
            await patchDocumentMetadata(
                documentId,
                updatedMetadata,
                abortController.signal,
                token,
            );
            return { error: false, message: "" };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to update the document metadata, please try again later",
            };
        }
    };

    const uploadDocumentFile = async (
        file: File,
        initialMetadata: UploadDocumentMetadataModel,
        abortController: AbortController,
    ): Promise<ExecutionResultModel<DocumentInformation>> => {
        try {
            const result = await uploadDocumentFileFromService(
                file,
                leadOid,
                initialMetadata,
                token,
                abortController.signal,
            );
            return { error: false, message: "", response: result };
        } catch (error) {
            return {
                error: true,
                message:
                    "An error occurred while trying to upload file document to the server, please try again later",
            };
        }
    };

    const getNotesWrapper = async (leadId: string, skip = 0): Promise<AeNotes> => {
        return await getNotes(token, leadId, skip);
    };

    const createNoteWrapper = async (leadId: string, note: string): Promise<AeNote> => {
        return await createNote(token, leadId, note);
    };

    const getPermissions = () => {
        if (flags.documentManagementPermissions) {
            return getPermissionActionSet(user?.permissions ?? []);
        } else {
            return new Set(Object.values(PermissionAction));
        }
    };

    const getDocumentActivityWrapper = async (documentId: string, skip = 0): Promise<TimelineItems> => {
        const response = await getDocumentActivity(token, documentId, skip);
        return {
            id: `${response.id}`,
            data: response.data.map(activity => ({
                id: activity.id,
                createdAt: activity.createdAt,
                description: activity.description,
                createdBy: activity.createdBy.id,
                createdByName: activity.createdBy.name
            })),
            any: null,
            skip: response.skip,
            take: response.take,
            count: response.count
        };
    };

    const generateFundingPacketWrapper = async (leadId: string, abortController: AbortController): Promise<ExecutionResultModel<{ status: number }>> => {
        try {
            const result = await generateFundingPacket(leadId, abortController.signal, token);
            return { error: false, message: "Funding Packet generation has started", response: result };
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to generate a funding packet",
            };
        }
    };
    const splitLenderDocumentWrapper = async (leadId: string, abortController: AbortController): Promise<ExecutionResultModel<{ status: number }>> => {
        try {
            const result = await splitLenderDocument(leadId, abortController.signal, token);
            return { error: false, message: "Signed Lender Documents have been successfully split.", response: result };
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to split lender document",
            };
        }
    };

    // TODO - figure out error and success messages
    const contractWithLender = async (request: ContractRequest, abortController: AbortController): Promise<ExecutionResultModel<undefined>> => {
        try {
            const result = await contract(request, abortController.signal, token);
            return result;
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to contract with lender",
            };
        }
    };

    const sendDocumentsToLender = async (request: SendDocumentsToLenderRequest, abortController: AbortController): Promise<ExecutionResultModel<undefined>> => {
        try {
            const result = await sendDocuments(request, abortController.signal, token);
            return result;
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to send documents to lender",
            };
        }
    };

    // TODO - figure out error and success messages
    const getContractStatusForDeal = async (request: ContractStatusRequest, abortController: AbortController): Promise<ExecutionResultModel<ContractStatusResponse>> => {
        try {
            const result = await getContractStatus(request, abortController.signal, token);
            return result;
        } catch (error) {
            return {
                error: true,
                message: "An error occurred while trying to retrieve contract status",
            };
        }
    };
    const createElectronicSignatureEnvelopeViewWrapper = async (request: { leadId: string, documentIds: string[] }, abortController: AbortController) => {
         try {
           const result = await createElectronicSignatureEnvelopeView(request.leadId, request.documentIds, abortController.signal, token);
             return { error: false, message: "DocuSign envelope view generation has started", response: result };
         }
         catch (error) {
             return {
                 error: true,
                 message: error as string,
             };
         }
    };

    const supportedLenderDocs = Array.isArray(flags.documentManagementSplitLenderDocs) ? flags.documentManagementSplitLenderDocs : [];

    return (
        <Box sx={{
            /* TODO - add style override to shared library */
            margin: "-24px"
        }}>
            <DocumentsPanelProvider
                createStipulations={createStipulations}
                deleteRow={deleteRow}
                generateDocumentUploadLink={generateDocumentUploadLink}
                generateFundingPacket={generateFundingPacketWrapper}
                getEmbedLink={getDocumentEmbedLink}
                getDownloadUrls={getDownloadUrls}
                getDocuments={getDocuments}
                getDocumentsHistory={getDocumentsHistory}
                replaceDocumentFile={replaceDocumentFile}
                updateDocumentMetadata={updateDocumentMetadata}
                uploadDocumentFile={uploadDocumentFile}
                embedLinkShowDownload={true}
                embedLinkShowAnnotations={false}
                // support feature flag
                showBoxLinks={flags.documentManagementBoxAccess ? true : false}
                viewHistoryTab={flags.documentManagementViewHistory ? true : false}
                baseBoxUrl={environmentVariables.boxBaseUrl}
                lead={lead ? toLeadModel(lead) : undefined}
                leadOid={leadOid}
                baseAeUrl={environmentVariables.aeUrl}
                getNotes={getNotesWrapper}
                createNote={createNoteWrapper}
                queryClient={queryClient}
                allowedActions={getPermissions()}
                getDocumentActivity={getDocumentActivityWrapper}
                showGenerateFundingPacket={flags.documentManagementShowGenerateFundingPacket ? true : false}
                inactiveReviewTabs={flags.documentManagementCustomerInfoTab ? [] : [DocumentReviewTabEnum.CUSTOMER_INFO]}
                sourceConfiguration={flags.documentManagementSourceConfiguration}
                // TODO - add type safety check and pass in default if this fails
                submitToLenderConfiguration={flags.documentManagementSubmitToLenderConfiguration}
                // loan contracting props
                contractWithLender={contractWithLender}
                getContractStatus={getContractStatusForDeal}
                sendDocumentsToLender={sendDocumentsToLender}
                splitLenderDocuments={supportedLenderDocs.includes(props.lead?.lenderName) ? splitLenderDocumentWrapper : undefined}
                // Won't show through details panel DM tab
                sendToDocuSign={props.lead ? createElectronicSignatureEnvelopeViewWrapper : undefined}
                showSendToDocuSign={flags.documentManagementSendToDocusign ? true : false}
            >
                <DocumentsPanelUI />
            </DocumentsPanelProvider>
        </Box>
    );
};

export default DocumentsSection;
