import { TitleOrderField, UiEstimateTemplateField } from "./types";
import { EstimateTemplateField, EstimateTemplateFieldType } from "../../../../contexts/estimates/core/types";
import { CreateEstimateTitleOrder } from "../../../../networking/core/types";
import { getCopy } from "../../../../authoring/copy";
import { DateTime } from "luxon";
import { now } from "../../../../contexts/time/now";
import { getIsTypeOfPurchasePrivateParty, isPrivatePartyOverrideField } from "../../../../helpers/customRequiredFields";

const copy = getCopy().createEstimate.submitEstimate.validation;

export type CreateEstimateValidationResult =
  | { valid: true } //
  | { valid: false; message: string };

type FieldValidators = {
  [type in EstimateTemplateFieldType]: (field: EstimateTemplateField, value: string) => CreateEstimateValidationResult;
};

export const validator: FieldValidators = {
  text(field: EstimateTemplateField, value: string): CreateEstimateValidationResult {
    if (!value) {
      if (!field.required) {
        return { valid: true };
      } else {
        return { valid: false, message: copy.genericRequired };
      }
    }
    if (field.regex) {
      if (isValidByRegex(field.regex, value)) {
        return { valid: true };
      } else {
        return { valid: false, message: copy.genericInvalid };
      }
    }
    return { valid: true };
  },
  number(field: EstimateTemplateField, value: string): CreateEstimateValidationResult {
    if (!value) {
      if (!field.required) {
        return { valid: true };
      } else {
        return { valid: false, message: copy.genericRequired };
      }
    }
    if (field.regex) {
      if (isValidByRegex(field.regex, value)) {
        return { valid: true };
      } else {
        return { valid: false, message: copy.genericInvalid };
      }
    }
    if (!Number.isNaN(Number(value))) {
      return { valid: true };
    }
    return { valid: false, message: copy.genericInvalid };
  },
  boolean(field: EstimateTemplateField, value: string): CreateEstimateValidationResult {
    if (!value) {
      if (field.required) {
        return {
          valid: false,
          message: copy.genericRequired,
        };
      }
      return { valid: true };
    }
    if (["true", "false", "yes", "no"].includes(value.toLowerCase())) {
      return { valid: true };
    }
    return {
      valid: false,
      message: copy.genericInvalid,
    };
  },
  date(field: EstimateTemplateField, isoDateStr: string): CreateEstimateValidationResult {
    if (field.fieldInfo.type !== "date") {
      throw new Error("A field who's type is not date should not be passed into validator.date()");
    }
    if (!isoDateStr) {
      if (field.required) {
        return {
          valid: false,
          message: copy.genericRequired,
        };
      }
      return {
        valid: true,
      };
    }
    const date = DateTime.fromISO(isoDateStr);
    if (!date.isValid) {
      return {
        valid: false,
        message: copy.genericInvalid,
      };
    }
    if (field.fieldInfo.restrict === "past" && now() < date) {
      return {
        valid: false,
        message: copy.dateMustBeInPast,
      };
    } else if (field.fieldInfo.restrict === "future" && now() > date) {
      return {
        valid: false,
        message: copy.dateMustBeInFuture,
      };
    }
    return { valid: true };
  },
  choice(field: EstimateTemplateField, value: string): CreateEstimateValidationResult {
    if (field.fieldInfo.type !== "choice") {
      throw new Error("A field who's type is not choice should not be passed into validator.choice()");
    }
    if (!value) {
      if (field.required) {
        return {
          valid: false,
          message: copy.genericRequired,
        };
      }
      return { valid: true };
    }
    if (!field.fieldInfo.options.includes(value)) {
      return {
        valid: false,
        message: copy.genericInvalid,
      };
    }
    return { valid: true };
  },
};

export function setValidOrInvalid(
  titleOrder: CreateEstimateTitleOrder,
  titleOrderFields: TitleOrderField[],
  unknownFields: UiEstimateTemplateField[]
) {
  titleOrderFields.forEach((field) => {
    if (field.isValid === undefined) {
      const validationResults = validator[field.estimateTemplateField.fieldInfo.type](
        field.estimateTemplateField,
        field.getValue(titleOrder)
      );
      field.isValid = validationResults.valid;
    }
  });
  unknownFields.forEach((field) => {
    if (field.isValid === undefined) {
      const validationResults = validator[field.fieldInfo.type](field, field.value ?? "");
      field.isValid = validationResults.valid;
    }
  });
}

export function validateAllFields(
  titleOrderFields: TitleOrderField[],
  unknownFields: UiEstimateTemplateField[],
  excludedGroups: string[] = [],
  titleOrder: CreateEstimateTitleOrder,
  typeOfPurchaseWklsTemplateFlag?: boolean
): boolean {
  return (
    titleOrderFields
      .filter(
        (field) => {
        if (typeOfPurchaseWklsTemplateFlag && getIsTypeOfPurchasePrivateParty(titleOrderFields, titleOrder) && isPrivatePartyOverrideField(field)) {
          if (!field.getValue(titleOrder)) {
            return false;
          }
          return true;
        }
        return !excludedGroups.includes(field.estimateTemplateField.group) &&
        !excludedGroups.includes(field.estimateTemplateField.teGroup);
      })
      .every((field) => field.isValid) &&
    unknownFields
      .filter((field) => {
        const isGroupIncluded = !excludedGroups.includes(field.group) && !excludedGroups.includes(field.teGroup);
        if (!isGroupIncluded) {
           return false;
         }
        return true;
      })
      .every((field) => field.isValid)
  );
}

function isValidByRegex(regexStr: string, value: string) {
  const regex = new RegExp(regexStr);
  return regex.test(value);
}