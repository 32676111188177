/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: "21b141d2-5dfe-4228-969c-c7e7d314dd99", // This is the ONLY mandatory field that you need to supply.
    authority: "https://login.microsoftonline.com/b1216cae-dd3f-4b38-a386-8ecfbe5cc07a", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "http://localhost:3001/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
    // postLogoutRedirectUri: "h/signout", // Simply remove this line if you would like navigate to index page after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["openid", "profile", "api://21b141d2-5dfe-4228-969c-c7e7d314dd99/access_as_user"],
};

// exporting config object for jest
if (typeof exports !== "undefined") {
  module.exports = {
    msalConfig: msalConfig,
  };
}
