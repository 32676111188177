// Taken from titles-workflow-server commit# 5b06f668f....

import { Static, Type } from "@sinclair/typebox";

export type EstimateTemplateFieldType = "text" | "number" | "boolean" | "date" | "choice";
export type EstimateTemplateFieldInfo = Static<typeof EstimateTemplateFieldInfoSchema>;
const EstimateTemplateDateSchema = Type.Object({
  type: Type.Literal("date"),
  restrict: Type.Optional(Type.Union([Type.Literal("past"), Type.Literal("future")])),
});
export type EstimateTemplateDateField = Static<typeof EstimateTemplateDateSchema>;

const EstimateTemplateChoiceSchema = Type.Object({
  type: Type.Literal("choice"),
  options: Type.Array(Type.String()),
});

export type EstimateTemplateChoiceField = Static<typeof EstimateTemplateChoiceSchema>;
// TO DO: May need to update the "text" type to include regex
export const EstimateTemplateFieldInfoSchema = Type.Union([
  Type.Object({
    type: Type.Literal("text"),
  }),
  Type.Object({
    type: Type.Literal("number"),
  }),
  Type.Object({
    type: Type.Literal("boolean"),
  }),
  EstimateTemplateDateSchema,
  EstimateTemplateChoiceSchema,
]);

export type EstimateTemplateField = Static<typeof EstimateTemplateFieldSchema>;
export const EstimateTemplateFieldSchema = Type.Object({
  group: Type.String(),
  teGroup: Type.String(),
  name: Type.String(),
  required: Type.Boolean(),
  regex: Type.Optional(Type.String()),
  fieldInfo: EstimateTemplateFieldInfoSchema,
});

export type UIEstimateTemplate = Static<typeof UIEstimateTemplateSchema>;
export const UIEstimateTemplateSchema = Type.Object({
  fields: Type.Array(EstimateTemplateFieldSchema),
  estimateId: Type.Number(),
});
