import { DashboardTitleOrder, TitlesEngineEscalation } from "../../../networking/core/types";
import { useState } from "react";
import { Button, Drawer } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { formatDate } from "../shared/FieldFormats";
import { ResolveEscalationModal } from "./ResolveEscalationModal";
import { CustomButton } from "../shared/Button";
import { useAuth } from "../../Auth/AuthProvider";
import { useEscalationsDashboard } from "../context/EscalationsDashboardContext";
import { OrderWithEscalation } from "./types";
import { DateTime } from "luxon";
import { getCopy } from "../../../authoring/copy";
import { acceptEscalation } from "../../../networking/escalations/TitleWorkflowEscalationsApi";
import { formatAeLeadStatus } from "@auto-approve/auto-approve-ui-library";

const { escalationsDashboard } = getCopy();

export function EscalationsDetailsDrawer(drawerProps: {
  row: OrderWithEscalation | undefined;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { row, isOpen, onClose } = drawerProps;
  const { user } = useAuth();
  const { allRows, setAllRows } = useEscalationsDashboard();

  async function handleAcceptEscalation() {
    if (!user || !row) return;

    setLoading(true);
    acceptEscalation(user.token, row)
      .then((escalation: TitlesEngineEscalation) => {
        updateTitleOrderRow(row, escalation);
        onClose();
      })
      .catch((err) => {
        alert(`Failed to accept the escalation. Error: ${err}`);
      })
      .finally(() => setLoading(false));
  }

  function updateTitleOrderRow(titleOrder: DashboardTitleOrder, escalation: TitlesEngineEscalation) {
    const row = allRows.find((row) => row.aeLeadId === titleOrder.aeLeadId);
    if (!row) return;

    row.escalation.acceptedAt = escalation.acceptedAt;

    setAllRows((rows) => rows);
  }

  return (
    <Drawer open={isOpen} anchor={"right"} ModalProps={{ onBackdropClick: onClose }}>
      <div className={"py-4"}>
        <div className={"flex justify-end"}>
          <Button className={"mt-12"} onClick={onClose}>
            <KeyboardTabIcon titleAccess="close icon" />
          </Button>
        </div>
        <div className={"pl-12 pr-4 pb-4"}>
          <div className={"text-2xl font-bold"}>
            {row?.customer ? `${row.customer.lastName}, ${row.customer.lastName}` : ""}
          </div>
        </div>
        <div className={"w-full pl-12 bg-gray-100 py-8"}>
          <p className={"mb-6"}>
            {row?.escalation.requestedAt && DateTime.fromISO(row?.escalation.requestedAt).toFormat("m/d/y - h:ma")}
          </p>
          <p className={"mb-6 w-80 break-words"}>{row?.escalation.description}</p>

          <div className={"flex"}>
            <CustomButton
              variant="contained"
              sx={{ marginRight: "1rem" }}
              onClick={handleAcceptEscalation}
              disabled={loading}
            >
              {escalationsDashboard.acceptEscalation.buttonText}
            </CustomButton>
            <CustomButton variant="text" onClick={() => setModalOpen(true)} disabled={loading}>
              {escalationsDashboard.resolveEscalation.buttonText}
            </CustomButton>
          </div>
        </div>
        <div className={"grid grid-cols-2 my-4 gap-4 py-2 pl-12 pr-4"}>
          <div>
            <b>{escalationsDashboard.titleOrderInformation.existingLienholder.label}</b>
          </div>
          <div>
            {row?.lienholder.name ?? escalationsDashboard.titleOrderInformation.existingLienholder.missingValue}
          </div>
          <div>
            <b>{escalationsDashboard.titleOrderInformation.fundedDate.label}</b>
          </div>
          <div>
            {row?.fundedDate === "Missing"
              ? escalationsDashboard.titleOrderInformation.fundedDate.missingValue
              : formatDate(row?.fundedDate)}
          </div>
          <div>
            <b>{escalationsDashboard.titleOrderInformation.aeStatus.label}</b>
          </div>
          <div>{formatAeLeadStatus(row?.aeStatus)}</div>
          <div>
            <b>{escalationsDashboard.titleOrderInformation.state.label}</b>
          </div>
          <div>{row?.state}</div>
          <div>
            <b>{escalationsDashboard.titleOrderInformation.daysInFlagStatus.label}</b>
          </div>
          <div>{row?.daysInFlagStatus}</div>
          <div>
            <b>{escalationsDashboard.titleOrderInformation.numberOfTimesOnHold.label}</b>
          </div>
          <div>{row?.totalNumberOfHolds}</div>
        </div>
        {row && <ResolveEscalationModal row={row} open={modalOpen} setOpen={setModalOpen} closeDrawer={onClose} />}
      </div>
    </Drawer>
  );
}
