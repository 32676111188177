import DocumentsSection from "../../../Documents/DocumentsSection";
import { DashboardRow } from "../../types";

type DocumentsPanelProps = {
    row: DashboardRow;
};

const DocumentsPanel = (props: DocumentsPanelProps) => (
    <DocumentsSection leadOid={props.row.aeLeadId} />
);

export default DocumentsPanel;
