import { Box } from "@mui/material";

type FlagProps = {
  flag: string;
};

export function FlagCell(props: FlagProps) {
  if (props.flag === "") return null;

  return (
    <Box
      sx={{
        backgroundColor: "#E0E0E0",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "5px 12px",
      }}
    >
      {props.flag}
    </Box>
  );
}
