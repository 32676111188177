/**
 * Returns `value`, or throws if it's `null` or `undefined`
 */
export function nonNull<T>(value: T): NonNullable<T> {
  if (value == null) {
    throw new Error(`Expected value to be non-null, but was ${value}`);
  }

  return value as NonNullable<T>;
}
