import { Navigate, useNavigate } from "react-router-dom";
import { SignInCredentials, SignInForm } from "../SignInForm/SignInForm";
import { getUser, useAuth } from "../Auth/AuthProvider";
import Page from "../shared/Page/Page";
import { useState } from "react";
import ErrorPage from "../ErrorPage/ErrorPage";
import { getHome } from "../Auth/AuthHelper";

function LoginPage() {
  const { signIn, loginSucceeded, showErrorPage } = useAuth();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  function handleOnClick(value: SignInCredentials) {
    setLoading(true);
    signIn(value.username, value.password, () => {
      setLoading(false);
      const user = getUser();
      navigateHome(user);
    });
  }

  if (showErrorPage) {
    return <ErrorPage />;
  }

  const user = getUser();
  if (user && loginSucceeded) {
    return <Navigate to={getHome(user)} />;
  }

  function navigateHome(user: string | null) {
    nav(getHome(user));
  }

  return (
    <div
      style={{ background: "linear-gradient(to bottom, #1d0d4f 0%, #5230a2 100%)", height: "100vh", minHeight: "100%", marginTop: "-132px" }}
    >
      <Page>
        <SignInForm onSubmit={handleOnClick} loading={loading} showInvalidCredentialsError={!loginSucceeded} />
      </Page>
    </div>
  );
}

export default LoginPage;
