import { Dispatch, SetStateAction, useState } from "react";
import { SortOption } from "../components/Dashboards/types";
import { GridFilterModel } from "@mui/x-data-grid";

// Sorting

type UseStateResult<T> = [T, Dispatch<SetStateAction<T>>];
type SortHook = UseStateResult<SortOption[]>;

const useSortState = (initialValue: SortOption[] = []): SortHook => useState(initialValue);

export function useAssociateTitleSpecialistDashboardSort(): SortHook {
  return useSortState();
}

export function useAssociateTitleSpecialistMyQueueDashboardSort(): SortHook {
  return useSortState();
}

export function useEscalationsDashboardSort(): SortHook {
  return useSortState();
}

export function useTitleSpecialistDashboardSort(): SortHook {
  return useSortState([
    { sortBy: "aeStatusSeverity", sortOrder: "desc" },
    { sortBy: "lastTouched", sortOrder: "asc" },
  ]);
}

export function useTopPrioritySort(): SortHook {
  return useSortState([{ sortBy: "followUpDate", sortOrder: "asc" }]);
}

// Filtering

type FilterHook = UseStateResult<GridFilterModel>;
const useFilterState = (initialValue: GridFilterModel = { items: [] }): FilterHook => useState(initialValue);

export function useAssociateTitleSpecialistDashboardFilter(): FilterHook {
  return useFilterState();
}

export function useAssociateTitleSpecialistMyQueueDashboardFilter(): FilterHook {
  return useFilterState();
}

export function useEscalationsDashboardFilter(): FilterHook {
  return useFilterState();
}

export function useTitleSpecialistDashboardFilter(): FilterHook {
  return useFilterState();
}