import { TaskOutlined, RestartAlt } from "@mui/icons-material";
import { Box, CircularProgress, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { get, isEmpty, isUndefined, sortBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  DashboardTitleOrder,
  StatusHistoryEvent,
  TaskContact,
  TaskGroup,
  TaskGroupType,
  TitleOrderDocEta,
  TitleOrderEtaType,
  TitleOrderTask,
} from "../../../../networking/core/types";
import {
  createTitleOrderDocEta,
  getAllTitleOrderDocEtas,
  createDocsEventReceived,
  createDocsEventReopenTasks,
  updateTitleOrderTask,
  getNewestDocEvent,
  getOrderWithStatusHistory,
  getTasks,
} from "../../../../networking/TitlesWorkflowAPI";
import { useAuth } from "../../../Auth/AuthProvider";
import { nonTitleHoldingStates, titleHoldingStates } from "../../../types";
import { CustomButton } from "../Button";
import { formatState } from "../FieldFormats";
import { DashboardRow } from "../../types";
import { now } from "../../../../contexts/time/now";
import { getCopy } from "../../../../authoring/copy";
import { DateTime } from "luxon";
import { User } from "../../../Auth/Auth";
import { TaskNotes } from "../../AssociateTitleSpecialistDrawer/TaskNotes/TaskNotes";
import { UndoTaskModal } from "./UndoTaskModal";
import { DocsReceivedModal } from "./DocsReceivedModal";
import { AreYouSureModal } from "./AreYouSureModal";
import { TaskItem } from "./TaskItem";
import { AeLeadStatus, Role } from "@auto-approve/auto-approve-ui-library";

const {
  associateTitleSpecialistDashboard: {
    taskPanel: { taskGroupHeadings, docsReceived },
  },
  titleSpecialistDashboard: {
    drawer: {
      tasks: { duplicateTitleRequired },
    },
  },
} = getCopy();

export function TasksPanel(props: { row: DashboardRow | undefined }) {
  const { user } = useAuth();
  const { row } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [rowStatusHistory, setRowStatusHistory] = useState<Array<StatusHistoryEvent>>([]);
  const showCustomerCoreTasks = useCallback(
    () => shouldShowCustomerCoreTasks({ row, user, rowStatusHistory }),
    [row, user, rowStatusHistory]
  );
  const [activeIndex, setActiveIndex] = useState(showCustomerCoreTasks() ? 0 : 1);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveIndex(newValue);
  };

  const getColor = (index: number) => (index === activeIndex ? "#5230A2" : "#767676");

  const showLienholderCoreTasks =
    row?.state && new Set([...titleHoldingStates, ...nonTitleHoldingStates]).has(formatState(row.state));

  useEffect(() => {
    if (user?.token && row) {
      getOrderWithStatusHistory({ titleOrderId: row.titleOrderId }, user.token)
        .then(({ titleOrder }) => {
          setRowStatusHistory(titleOrder.statusChangeEvents);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, row, setRowStatusHistory, setLoading]);

  useEffect(() => {
    setActiveIndex(showCustomerCoreTasks() ? 0 : 1);
  }, [showCustomerCoreTasks]);

  if (!row || (!showCustomerCoreTasks() && !showLienholderCoreTasks)) {
    return <>No Tasks Available for an order from {row?.state ?? "State Info Missing from Order"}</>;
  }

  if (loading) {
    return (
      <div className="flex w-full h-96 justify-center items-center">
        <CircularProgress className="m-auto" size={40} thickness={8} />
      </div>
    );
  } else if (
    user?.role === Role.TITLE_SPECIALIST &&
    row.totalDays >= 30 &&
    isEmpty(rowStatusHistory.find(({ newStatus }) => newStatus === AeLeadStatus.TitleReceived))
  ) {
    return <div className="flex w-full h-48 p-8 justify-center content-center text-lg">{duplicateTitleRequired}</div>;
  }

  return (
    <>
      <Box sx={{ px: "12px", boxShadow: "0px 3px 3px rgba(217, 217, 217, 0.25)" }}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              background: "#5230A2",
            },
          }}
        >
          <Tab
            disabled={!showCustomerCoreTasks()}
            label={
              <Typography
                sx={{
                  color: getColor(0),
                  fontSize: 14,
                  textDecoration: showCustomerCoreTasks() ? "" : "line-through",
                }}
              >
                Customer contact tasks
              </Typography>
            }
          />
          <Tab
            disabled={!showLienholderCoreTasks}
            label={<Typography sx={{ color: getColor(2), fontSize: 14 }}>Lienholder contact tasks</Typography>}
          />
        </Tabs>
      </Box>

      <div role="tabpanel" aria-labelledby="tasks-panel">
        <Box sx={{ p: 5 }}>
          <Box>
            {activeIndex === 0 && (
              <TitleOrderTaskList contact="customer" aeLeadId={row.aeLeadId} titleOrderId={row.titleOrderId} />
            )}
            {activeIndex === 1 && (
              <TitleOrderTaskList contact="lienholder" aeLeadId={row.aeLeadId} titleOrderId={row.titleOrderId} />
            )}
          </Box>
        </Box>
      </div>

      <Box sx={{ px: 5, pb: 5 }}>
        <TaskNotes titleOrderId={row.titleOrderId} />
      </Box>
    </>
  );
}

export function TitleOrderTaskList(props: { contact: TaskContact; aeLeadId: string; titleOrderId: number }) {
  const { contact, aeLeadId, titleOrderId } = props;
  const { user } = useAuth();
  const [isDocsReceivedModalOpen, setIsDocsReceivedModalOpen] = useState<
    "areYouSureModal" | "confirmationModal" | false
  >(false);
  const [areDocsReceived, setAreDocsReceived] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [undoModalTask, setUndoModalTask] = useState<
    (TitleOrderTask & { loading: boolean; selectedEtaDate: DateTime | null }) | undefined
  >(undefined);
  const [tasks, setTasks] = useState<Array<TitleOrderTask & { loading: boolean; selectedEtaDate: DateTime | null }>>(
    []
  );
  const [taskGroup, setTaskGroup] = useState<TaskGroup | null>(null);
  const [titleOrderDocEta, setTitleOrderDocEta] = useState<TitleOrderDocEta | undefined>(undefined);

  useEffect(() => {
    const hydrateTasks = async () => {
      if (user?.token) {
        const tasksToUpdate = await getTasks(aeLeadId, contact, user.token);
        setTaskGroup(tasksToUpdate.group);
        const taskGroupType = tasksToUpdate.group.type;

        Promise.all([
          getAllTitleOrderDocEtas({ association: "TITLE_ORDER", titleOrderId }, user.token),
          getNewestDocEvent({ titleOrderId, taskGroupType }, user.token),
        ])
          .then(([titleOrderEtas, docEventResponse]) => {
            setTitleOrderDocEta(titleOrderEtas.find((eta) => eta.type === etaTypeForTaskGroup(taskGroupType)));
            setTasks(
              tasksToUpdate.tasks.map((task) => ({
                ...task,
                loading: false,
                selectedEtaDate: null,
              }))
            );
            if (get(docEventResponse.event, "receivedAt")) {
              setAreDocsReceived(true);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    hydrateTasks();
  }, [contact, aeLeadId, titleOrderId, user]);

  const setTaskAttrs = (task: TitleOrderTask & { loading: boolean; selectedEtaDate: DateTime | null }) =>
    setTasks([...tasks.filter((x) => x.id !== task.id), { ...task }]);

  const undoTask = async (args: {
    task: TitleOrderTask & { loading: boolean; selectedEtaDate: DateTime | null };
    changeReason: string;
    user: User;
  }) => {
    return updateTitleOrderTask(
      {
        completed: false,
        titleOrderTaskId: args.task.id,
        changeReason: args.changeReason,
      },
      args.user.token
    )
      .then((result) => {
        if (result.updated && result.invalidatedEta) {
          setTitleOrderDocEta(undefined);
        }
        setTaskAttrs({ ...args.task, loading: false, completed: false, updatedAt: now().toLocal() });
      })
      .catch(() => {
        setTaskAttrs({ ...args.task, loading: false });

        alert("An error occurred while attempting to update the task. Task completion was NOT saved.");
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {!loading && (
          <>
            <Typography fontSize={18} sx={{ mb: 3, fontWeight: "bold" }}>
              {taskGroup === null ? null : taskGroupHeadings[taskGroup.type]}
            </Typography>
            <Box
              sx={{
                borderRadius: "100%",
                background: areDocsReceived ? "inherit" : "#FAFAFA",
                height: "2.25rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {areDocsReceived ? (
                <>
                  <TaskOutlined className="text-aa-dark-green mr-1" />
                  <span className="text-sm text-aa-dark-green min-w-max font-medium mr-5">
                    {docsReceived.success.label}
                  </span>
                  <CustomButton
                    variant="outlined"
                    onClick={() => {
                      setIsDocsReceivedModalOpen("areYouSureModal");
                    }}
                  >
                    <RestartAlt className="mr-2" /> {docsReceived.success.button.label}
                  </CustomButton>
                </>
              ) : (
                <Tooltip
                  title={docsReceived.confirmation.tooltipLabel}
                  arrow
                  PopperProps={{ style: { padding: "0 1rem" } }}
                >
                  <TaskOutlined
                    onClick={() => {
                      setIsDocsReceivedModalOpen("confirmationModal");
                    }}
                    className="cursor-pointer"
                  />
                </Tooltip>
              )}
            </Box>
          </>
        )}
      </Box>
      {loading ? (
        <div className="flex w-full h-64 ">
          <CircularProgress className="m-auto" size={40} thickness={8} />
        </div>
      ) : (
        <>
          {user && undoModalTask && (
            <UndoTaskModal
              isOpen={!isUndefined(undoModalTask)}
              onClose={() => setUndoModalTask(undefined)}
              onSubmit={(changeReason: string) => undoTask({ changeReason, task: undoModalTask, user })}
              task={undoModalTask}
            />
          )}
          {user && taskGroup && isDocsReceivedModalOpen === "confirmationModal" && (
            <DocsReceivedModal
              isOpen={isDocsReceivedModalOpen === "confirmationModal"}
              onClose={() => setIsDocsReceivedModalOpen(false)}
              onSubmit={() =>
                createDocsEventReceived(
                  {
                    titleOrderId,
                    taskGroupType: taskGroup.type,
                    // stubbing `userId` when not authed with TE b/c feature for alternate auth was pushed back
                    // so requests for those users are currently not supported, and will fail
                    userId: user.teUserId ?? 0,
                  },
                  user.token
                ).then(() => {
                  setAreDocsReceived(true);
                })
              }
            />
          )}
          {user && taskGroup && isDocsReceivedModalOpen === "areYouSureModal" && (
            <AreYouSureModal
              isOpen={isDocsReceivedModalOpen === "areYouSureModal"}
              onClose={() => setIsDocsReceivedModalOpen(false)}
              onSubmit={(changeReason: string) =>
                createDocsEventReopenTasks(
                  {
                    titleOrderId,
                    taskGroupType: taskGroup.type,
                    // stubbing `userId` when not authed with TE b/c feature for alternate auth was pushed back
                    // so requests for those users are currently not supported, and will fail
                    userId: user.teUserId ?? 0,
                    changeReason,
                  },
                  user.token
                ).then(() => {
                  setAreDocsReceived(false);
                })
              }
            />
          )}
          {sortBy(tasks, (task) => task.precedence).map((task, index) => (
            <TaskItem
              key={index}
              task={{
                ...task,
                setSelectedEtaDate: setSelectedEtaDateForTask(task),
              }}
              handleAddEta={handleAddEta}
              titleOrderDocEta={titleOrderDocEta}
              showDivider={index !== tasks.length - 1}
              onToggle={(checked: boolean) => handleTaskToggle(task, checked)}
              areDocsReceived={areDocsReceived}
            />
          ))}
        </>
      )}
    </>
  );

  function setSelectedEtaDateForTask(task: TitleOrderTask & { loading: boolean }): (val: DateTime | null) => void {
    return (newDate: DateTime | null) => setTaskAttrs({ ...task, selectedEtaDate: newDate });
  }

  async function handleAddEta(task: TitleOrderTask & { selectedEtaDate: DateTime | null }) {
    if (!user?.token || !taskGroup) {
      return;
    }

    if (task.selectedEtaDate?.isValid) {
      setTaskAttrs({ ...task, loading: true });

      createTitleOrderDocEta(
        { date: task.selectedEtaDate, type: etaTypeForTaskGroup(taskGroup.type), titleOrderTaskId: task.id },
        user.token
      )
        .then((eta) => {
          setTaskAttrs({ ...task, loading: false, selectedEtaDate: null });
          setTitleOrderDocEta(eta);
        })
        .catch((err) => {
          alert(
            "An error occurred while attempting to save the ETA. The new ETA was NOT saved." +
              "\n" +
              `Error: ${typeof err !== "string" ? JSON.stringify(err) : err}`
          );
        });
    }
  }

  async function handleTaskToggle(task: TitleOrderTask & { selectedEtaDate: DateTime | null }, checked: boolean) {
    if (!user?.token) {
      return;
    }

    if (checked) {
      setTaskAttrs({ ...task, loading: true });

      updateTitleOrderTask(
        {
          completed: true,
          titleOrderTaskId: task.id,
        },
        user.token
      )
        .then(() => {
          setTaskAttrs({ ...task, loading: false, completed: true, updatedAt: now().toLocal() });
        })
        .catch(() => {
          setTaskAttrs({ ...task, loading: false });

          alert("An error occurred while attempting to update the task. Task completion was NOT saved.");
        });
    } else {
      setUndoModalTask({ ...task, loading: false });
    }
  }
}

function shouldShowCustomerCoreTasks(args: {
  row?: DashboardTitleOrder;
  user: User | null;
  rowStatusHistory: Array<StatusHistoryEvent>;
}) {
  const { row, user, rowStatusHistory } = args;

  if (!row?.state) return false;
  if (!titleHoldingStates.has(formatState(row.state))) return false;

  if (user?.role === Role.TITLE_SPECIALIST) {
    if (row.totalDays < 30) return true;
    if (row.totalDays === 30 && row.aeStatus === AeLeadStatus.LienReleaseReceived) return false;
    if (
      row.totalDays >= 30 &&
      Boolean(rowStatusHistory.find(({ newStatus }) => newStatus === AeLeadStatus.LienReleaseReceived))
    ) {
      return false;
    }
  }

  return true;
}

/*
If days since funded i.e total days is < 30 days and the title order is in Dealer track or paper check payoff status

display Normal reminder level 1 tasks within the first 4 daily attempts of contacting the customer

Note that total days since funded could be greater, less than or equal to 30 days when the 1st contact happens

for example: if the title order is in day 29 of been in that status when the ATS performed the core tasks, they will be required to escalate the next day to a manager for awareness
 */
function _shouldShowCustomerReminderTasks(args: {
  row?: DashboardTitleOrder;
  user: User | null;
  rowStatusHistory: Array<StatusHistoryEvent>;
}) {
  const { row, user } = args;

  if (!row?.state) return false;
  if (!titleHoldingStates.has(formatState(row.state))) return false;

  if (user?.role === Role.TITLE_SPECIALIST) {
    if (row.totalDays < 30 && isPayoffStatus(row.aeStatus)) return true;
    // TODO TE-819: Check if the Normal Reminders have been displayed for > 4 days using the task history
  }
}

function isPayoffStatus(aeStatus: AeLeadStatus): boolean {
  return aeStatus === AeLeadStatus.DealertrackPayoff || aeStatus === AeLeadStatus.PaperCheckPayoff;
}
function etaTypeForTaskGroup(taskGroup: TaskGroupType): TitleOrderEtaType {
  return taskGroup === TaskGroupType.CUSTOMER_CORE ||
    taskGroup === TaskGroupType.CUSTOMER_REMINDER ||
    taskGroup === TaskGroupType.CUSTOMER_URGENT_REMINDER
    ? TitleOrderEtaType.CUSTOMER
    : TitleOrderEtaType.LIENHOLDER;
}
