import LogoSvg from "../../assets/auto_approve_logo_full_color.svg";
import CarAndPersonSvg from "../../assets/auto_approve_login_error_page_icon.svg";

import { getCopy } from "../../authoring/copy";

function ErrorPage() {
  const copy = getCopy().errorPage;

  return (
    <div
      style={{ background: "linear-gradient(to bottom, #1d0d4f 0%, #5230a2 100%)", height: "100%", minHeight: "100%" }}
    >
      <div
        data-testid="errorPageBlock"
        style={{ width: "450px", height: "418px", padding: "0px 64px" }}
        className="bg-white py-8 flex justify-center flex-col gap-1 m-auto absolute inset-x-0 mt-44"
      >
        <img src={LogoSvg} className="pb-5 w-48" />
        <p className="pb-5">
          <b>{copy.message}</b>
        </p>
        <p className="pb-5">{copy.prompt}</p>
        <img src={CarAndPersonSvg} className="w-40 mx-auto" />
      </div>
    </div>
  );
}

export const UnauthorizedPage = (props: { message?: string }) => {
  return (
    <div
      style={{ background: "linear-gradient(to bottom, #1d0d4f 0%, #5230a2 100%)", height: "100%", minHeight: "100%" }}
    >
      <div
        data-testid="errorPageBlock"
        style={{ width: "450px", height: "418px", padding: "0px 64px" }}
        className="bg-white py-8 flex justify-center flex-col gap-1 m-auto absolute inset-x-0 mt-44"
      >
        <img src={LogoSvg} className="pb-5 w-48" />
        <p className="pb-5">
          <b>{props.message ?? "You do not have access to this page."}</b>
        </p>
        <p className="pb-5">Please contact your manager to confirm you should have access.<br/><br/>If you believe you should have access, please contact IT: <a href="mailto:servicedesk@autoapprove.com"><i>servicedesk@autoapprove.com</i></a></p>
        <img src={CarAndPersonSvg} className="w-40 mx-auto" />
      </div>
    </div>
  );
};

export default ErrorPage;
