import { Router } from "react-router-dom";
import { ReactNode, useLayoutEffect, useState } from "react";
import { BrowserHistory } from "history";

const CustomRouter = ({ history, ...props }: { history: BrowserHistory; children: ReactNode }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

export default CustomRouter;
