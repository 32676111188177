type DetailsPanelSectionField = {
    label: string,
    value1?: string,
    value2?: string,
};

type DetailsPanelSectionProps = {
    title: string,
    fields: Array<DetailsPanelSectionField>,
    direction?: "column" | "row"
    missingDataLabel?: string
    className?: string,
    style?: React.CSSProperties
};

type DetailsPanelSectionRowProps = {
    fields: Array<DetailsPanelSectionField>,
    missingDataLabel?: string
    className?: string,
    style?: React.CSSProperties
};


export const DetailsPanelSectionRow = (props: DetailsPanelSectionRowProps) => (
    <div className={props.className}
        style={{ display: "flex", flexWrap: "wrap", gap: 10, ...props.style }}>
        {props.fields.length > 0 ? props.fields.map((field, i) => (<div key={i} style={{ flexGrow: 50 }}>
            <b>{field.label}</b>
            <div>{field.value1 ?? ""}</div>
        </div>)) : props.missingDataLabel ?? "No Data Avaiable"}
    </div>
);

export const DetailsPanelSection = (props: DetailsPanelSectionProps) => {
    return (
        <div style={props.style} className={props.className}>
            <div className={"grid grid-cols-1 gap-2"}>
                <div className={"text-xl font-bold"}>{props.title}</div>
            </div>
            {props.direction === "column"
                ? <DetailsPanelSectionRow fields={props.fields} className="my-4" />
                : <div
                    className={"grid grid-cols-2 py-4 gap-y-2 gap-x-6"}
                    style={{ gridTemplateColumns: "max-content auto" }}>
                    {props.fields.length > 0
                        ? props.fields.map((field, i) => (
                            <>
                                <div key={`dps-1-${i}`} style={{ minWidth: "60px" }}>
                                    <b>{field.label}</b>
                                </div>
                                <div key={`dps-2-${i}`}>
                                    {field.value1 ?? ""}{field.value2 && <><br />{field.value2}</>}
                                </div>
                            </>
                        )) : props.missingDataLabel ?? `${props.title} not available`}
                </div>}
        </div>
    );
};