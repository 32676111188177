import { WklsEstimateTransactionDisplayType } from "../../../../../types";
import { DefaultSpecialInstructionsForm } from "./DefaultSpecialInstructionsForm";
import { SpecialInstructions } from "../../types";
import { CreateEstimateTitleOrder } from "../../../../../../networking/core/types";

export function SpecialInstructionsSection(props: {
  transactionType: WklsEstimateTransactionDisplayType | undefined;
  specialInstructions: SpecialInstructions;
  titleOrder: CreateEstimateTitleOrder | undefined;
}) {
  const { transactionType } = props;

  if (!transactionType) return null;

  switch (transactionType) {
    case WklsEstimateTransactionDisplayType.TitleAndRegistrationStateChange:
    case WklsEstimateTransactionDisplayType.TitleAndRegistrationLeaseBuyout:
    case WklsEstimateTransactionDisplayType.LienAddAddRemoveSpouse:
    default:
      return (
        <DefaultSpecialInstructionsForm specialInstructions={props.specialInstructions} titleOrder={props.titleOrder} />
      );
  }
}
