import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { Role } from "@auto-approve/auto-approve-ui-library";

// TODO(AAT-257) - move to shared library
// Role and AE Role should Match TWS: https://gitlab.com/auto-approve/approve-engine/titles-engine/titles-workflow-server/-/blob/main/src/contexts/auth/auth.ts


export function isRole(role: unknown): role is Role {
  if (typeof role !== "string") return false;
  return Object.values<unknown>(Role).includes(role);
}

export type RoleRequiredProps = {
  roles: Role[];
  children: JSX.Element;
};

export function RoleRequired(props: RoleRequiredProps) {
  const { user } = useAuth();
  const location = useLocation();

  if (user !== null && user.role && props.roles.includes(user.role)) {
    return props.children;
  }

  return <Navigate to={"/"} state={{ from: location }} />;
}
