import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  SxProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { createActionListing } from "../../../../networking/actionListing/TitlesWorkflowActionListingApi";
import { useAuth } from "../../../Auth/AuthProvider";
import {
  ActionListing,
  ActionListType,
  ActionListValue,
  ActionListValueType,
  getDefaultActions,
  getSelectedRootValue,
  isActionDisabled,
  isActionFormValid,
  isValuePopulated,
  mapActionListValuesToActionListing,
} from "../../../../networking/actionListing/types";
import { getCopy } from "../../../../authoring/copy";
import { Dispatch, SetStateAction, useState } from "react";
import { DashboardRow } from "../../types";
import { isUndefined } from "lodash";
import { ActionListActivity } from "./ActionListActivity";
import { CustomButton } from "../Button";
import { refreshDashboardRowFromDb } from "../../refreshDashboardRow";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface ActionListProps {
  row?: DashboardRow;
  allRows: DashboardRow[];
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
  isInitialPacketReviewedMissingDocumentExpandedDefault?: boolean;
  isEngagedInEscalationProcessExpandedDefault?: boolean;
  handleClose?: () => void;
  showEscalateButton: boolean;
  setRequestEscalationModalOpen?: (state: boolean) => void;
  handleNewAction: (action: ActionListing) => void;
}

export const defaultProps: ActionListProps = {
  showEscalateButton: false,
  isInitialPacketReviewedMissingDocumentExpandedDefault: false,
  isEngagedInEscalationProcessExpandedDefault: false,
  allRows: [],
  setAllRows: () => {
    /* no op */
  },
  handleNewAction: (_) => {
    /* no op */
  },
  row: undefined
};

const copy = getCopy().baseDrawer.details.actionsList;

type ValueComponentProps = {
  actionListValue: ActionListValue;
  disabled?: boolean;
  sx?: SxProps;
  error?: boolean;
};

export function ActionList(props: ActionListProps) {
  const queryClient = useQueryClient();
  const [actionListValues, setActionListValues] = useState(getDefaultActions(props.row?.followUpDate));
  const [triggerRefresh, setTriggerRefresh] = useState<number>(0);
  const { user } = useAuth();
  const flags = useFlags();

  const { isFetching, refetch } = useQuery(["createAction"],
    () => createActionListing(mapActionListValuesToActionListing(actionListValues, props.row?.titleOrderId), user?.token ?? ""), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSettled: async () => {
      // refresh the action list on success
      queryClient.refetchQueries(["actionListings", `${props.row?.titleOrderId}`]);
      props.handleNewAction(mapActionListValuesToActionListing(actionListValues, props.row?.titleOrderId));
      setActionListValues(getDefaultActions(actionListValues.find(alv => alv.key === ActionListType.requiresFollowUpDate)?.value));
      setTriggerRefresh(triggerRefresh + 1);
      await refreshDashboardRowFromDb(
        user?.token ?? "",
        props.row?.titleOrderId ?? 0,
        props.allRows,
        props.setAllRows
      );
    }
  });

  if (!user || props.row === undefined) {
    return <>Error while loading title order details.</>;
  }

  const valueChangeHandler = (action: ActionListValue, newValue: ActionListValueType) =>
    setActionListValues(actionListValues
      .map(currentValue => {
        if (action.type === "radio") {
          if (currentValue.key === action.key) return { ...currentValue, value: newValue };
          if (currentValue.type === "radio") return { ...currentValue, value: false };
          // default radio child values - easy to persist but depends on decision to select one or more actions
          if (currentValue.parent) {
            // only reset radio button values
            if (actionListValues.find(alv => alv.key === currentValue.parent)?.type === "checkbox")
              return currentValue;
            else return { ...currentValue, value: currentValue.default };
          }
        }
        // make sure to unset child values if unchecked
        if (currentValue.parent === action.key && action.type === "checkbox") {
            return { ...currentValue, value: currentValue.default };
        }
        if (currentValue.key === action.key) {
          return { ...currentValue, value: newValue };
        }
        return currentValue;
      }));

  const getTooltipText = (actionListValue: ActionListValue, disabled?: boolean,): string | undefined =>
    disabled ? actionListValue.disabledTooltip : "";

  const ValueComponent = (props: ValueComponentProps) => {
    // only checkboxes are using this in order to color label
    const colorStyle = props.error ? { color: "#df304a" } : {color: ""};
    const disabled = props.actionListValue.disabled || props.disabled;
    switch (props.actionListValue.type) {
      case "radio":
        return <Tooltip title={getTooltipText(props.actionListValue, disabled)} placement="left">
          <FormControlLabel
            disabled={disabled}
            sx={{ ...props.sx }}
            control={<Radio
              sx={{ padding: 0.5 }}
              checked={isValuePopulated(props.actionListValue)}
              onChange={() => valueChangeHandler(props.actionListValue, !props.actionListValue.value)}
            />}
            label={copy.actions[props.actionListValue.key] ?? ""}
          /></Tooltip>;
      case "checkbox":
        return <Tooltip title={getTooltipText(props.actionListValue, disabled)} placement="left">
          <FormControlLabel
            sx={{ ...props.sx }}
            control={<Checkbox
              sx={{ padding: 0.5, ...colorStyle }}
              disabled={disabled}
              checked={isValuePopulated(props.actionListValue)} />}
            onChange={() => valueChangeHandler(props.actionListValue, !props.actionListValue.value)}
            label={<p style={colorStyle}>
            {copy.actions[props.actionListValue.key] ?? ""}
          </p>}
          /></Tooltip>;
      default:
        return <>{props.actionListValue.type} is not a valid value type.</>;
    }
  };

  return (
    // unsure why I need pb-16 here (not taking wrapping pb-8)
    <div className="flex h-full pb-16">
      <div className="w-1/2 pr-8 overflow-auto" style={{ borderRight: "1px dashed #DEDEDE" }}>
        <FormGroup sx={{ display: "flex", flexDirection: "row", paddingLeft: "5px" }}>
          {actionListValues.filter(v => !v.footer).map(actionListValue => {
            if (!actionListValue.parent && !actionListValue.children)
              return <ValueComponent
                sx={{ width: "100%" }}
                key={actionListValue.key}
                actionListValue={actionListValue}
                disabled={isActionDisabled(actionListValues, actionListValue)} />;
            if (actionListValue.children) {
              const children = actionListValues.filter(v => v.parent == actionListValue.key);
              return (
                <>
                  <ValueComponent
                    sx={{ width: "100%" }}
                    actionListValue={actionListValue}
                    key={actionListValue.key}
                    disabled={isActionDisabled(actionListValues, actionListValue)}
                  />
                  {children.map(child =>
                    <div key={child.key} className="w-full pl-8">
                      {child.type === "date" ? <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Tooltip
                          title={getTooltipText(actionListValue, isActionDisabled(actionListValues, actionListValue, child) || isFetching)}
                          placement="left">
                          <div className="pt-2 w-full">
                            <DesktopDatePicker
                              className="w-full"
                              disablePast={true}
                              disabled={isActionDisabled(actionListValues, actionListValue, child) || isFetching}
                              label={copy.actions[child.key] ?? ""}
                              value={child.value ?? ""}
                              onChange={newValue => {
                                valueChangeHandler(child, newValue);
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    error={!isValuePopulated(child) || params.error}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Tooltip>
                      </LocalizationProvider>
                        : child.type === "text" ?
                          <Tooltip title={getTooltipText(actionListValue, isActionDisabled(actionListValues, actionListValue, child))} placement="left">
                            <div className="pt-2 pb-2"><TextField
                              sx={{ width: "100%" }}
                              label={copy.actions[child.key] ?? ""}
                              variant="outlined"
                              disabled={isActionDisabled(actionListValues, actionListValue, child) || isFetching}
                              value={child.value ?? ""}
                              onChange={e => valueChangeHandler(child, e.target.value)}
                              error={isValuePopulated(actionListValue) && !child.value}
                            />
                            </div></Tooltip>
                          : <ValueComponent
                            key={child.key}
                            sx={{ width: "100%" }}
                            actionListValue={child}
                            disabled={isActionDisabled(actionListValues, actionListValue, child) || isFetching} />}
                    </div>)}
                </>
              );
            }
          })}
          <div className="pt-4 w-full"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              background: "white",
              bottom: 0,
              zIndex: 1
            }}>
            <div>
              {actionListValues.filter(alv => alv.footer).map(alv =>
                <ValueComponent key={alv.key}
                  actionListValue={alv}
                  error={!!getSelectedRootValue(actionListValues) && !isValuePopulated(alv)}/>)}
            </div>
            <div>
              {flags.titlesEscalationsFeature && props.showEscalateButton && !isUndefined(props.setRequestEscalationModalOpen) &&
                <CustomButton
                  sx={{ marginRight: "0.5em" }}
                  variant="outlined"
                  onClick={() => {
                    if (!isUndefined(props.setRequestEscalationModalOpen)) {
                      props.setRequestEscalationModalOpen(true);
                    }
                  }}>
                  <>
                    {copy.escalate.buttonText}
                    <ArrowForward />
                  </>
                </CustomButton>}
              <CustomButton
                disabled={!isActionFormValid(actionListValues) || isFetching}
                variant="outlined"
                tooltip={isActionFormValid(actionListValues) ? "" : "Select one action, ensure Follow Up Date is present, and acknowledge AE Notes are updated to enable."}
                tooltipPlacement="right"
                onClick={() => refetch()}
              >
                {copy.saveButtonText}
              </CustomButton>
            </div>
          </div>
        </FormGroup>
      </div>
      <div className="w-1/2 pl-8 overflow-auto">
        <ActionListActivity titleOrderId={props.row.titleOrderId} triggerRefresh={triggerRefresh}/>
      </div>
    </div>);
}

ActionList.defaultProps = defaultProps;
