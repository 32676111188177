import {
  InsertDriveFileOutlined,
  KeyboardTab,
  MonitorHeartOutlined,
  Notes,
  Person,
} from "@mui/icons-material";
import { Drawer, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isUndefined } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { DashboardRow } from "../types";
import { DetailsPanel } from "./DetailsPanel/DetailsPanel";
import { TasksPanel } from "./TasksPanel/TasksPanel";
import { environmentVariables } from "../../../config";
import { getCopy } from "../../../authoring/copy";
import { ActivityPanel } from "./ActivityPanel/ActivityPanel";
import DocumentsPanel from "./DocumentsPanel/DocumentsPanel";
import { LDFlagKeyMap, useFlags } from "launchdarkly-react-client-sdk";
import { NotesPanel } from "./NotesPanel/NotesPanel";

type BaseDrawerProps = {
  isOpen: boolean;
  handleClose: () => void;
  row: DashboardRow | undefined;
  allRows: DashboardRow[];
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
  setRequestEscalationModalOpen?: (value: boolean) => void;
  setUpdateProgressModalOpen?: (value: boolean) => void;
  showUpdateButton: boolean;
  showEscalateButton: boolean;
};

export type BaseDrawerTab = { icon: JSX.Element; label: string };

export function BaseDrawer(props: BaseDrawerProps) {
  ;
  const flags = useFlags();

  if (!props.row) return null;

  const renderPanel = (activeTab: string, setActiveTab: Dispatch<SetStateAction<string | null>>) => {
    if (isUndefined(props.row)) return null;
    switch (activeTab) {
      case "Details":
        return (
          <DetailsPanel
            row={props.row}
            allRows={props.allRows}
            setAllRows={props.setAllRows}
            showUpdateButton={props.showUpdateButton}
            showEscalateButton={props.showEscalateButton}
            setRequestEscalationModalOpen={props.setRequestEscalationModalOpen}
            setUpdateProgressModalOpen={props.setUpdateProgressModalOpen}
            handleClose={props.handleClose}
            setActiveTab={setActiveTab}
          />
        );
      case "Tasks":
        return <TasksPanel row={props.row} />;
      case "Documents":
        return <DocumentsPanel row={props.row} />;
      case "Activity":
        return <ActivityPanel
          row={props.row}
          allRows={props.allRows}
          setAllRows={props.setAllRows}
          showEscalateButton={props.showEscalateButton}
          setRequestEscalationModalOpen={props.setRequestEscalationModalOpen}
          handleClose={props.handleClose}
        />;
      case "Notes":
        return <NotesPanel row={props.row} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        open={props.isOpen}
        anchor="right"
        ModalProps={{ onBackdropClick: props.handleClose }}
        PaperProps={{ sx: { width: "70vw" }, id: "base-drawer" }}
      >
        <TitleBar handleClose={props.handleClose} recordId={props.row.recordId} aeLeadId={props.row.aeLeadId} />
        <TabsAndPanels
          row={props.row}
          tabs={getSharedBaseDrawerTabs(flags)}
          renderPanel={renderPanel}
        />
      </Drawer>
    </>
  );
}
export const getSharedBaseDrawerTabs = (_flags?: LDFlagKeyMap): BaseDrawerTab[] => {
  const { details, activity, documents, notes } = getCopy().baseDrawer.tabLabels;

  return [
    {
      icon: <Person />,
      label: details,
    },
    {
      icon: <InsertDriveFileOutlined />,
      label: documents,
    },
    {
      icon: <MonitorHeartOutlined />,
      label: activity,
    },
    {
      icon: <Notes />,
      label: notes,
    },
  ];
};

type TitleBarProps = {
  handleClose: () => void;
  recordId: number;
  aeLeadId: string;
};

function TitleBar(props: TitleBarProps) {
  return (
    <Box sx={{ minWidth: 1.0 }}>
      <Box
        sx={{
          background: "#5132A2",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
          pl: 3,
          pr: 5,
          color: "#ffffff",
        }}
      >
        <Typography fontSize={14} alignSelf="center" sx={{ color: "#B1B1B1" }}>
          Record:{" "}
          <a href={`${environmentVariables.aeUrl}/loan-consultants/all-leads/id=${props.aeLeadId}`} target="_blank">
            <span className="underline">{props.recordId}</span>
          </a>
        </Typography>
        <KeyboardTab onClick={props.handleClose} />
      </Box>
    </Box>
  );
}

type TabsAndPanelProps = Omit<
  BaseDrawerProps,
  | "isOpen"
  | "handleClose"
  | "showUpdateButton"
  | "showEscalateButton"
  | "setRequestEscalationModalOpen"
  | "setUpdateProgressModalOpen"
  | "allRows"
  | "setAllRows"
> & {
  tabs: BaseDrawerTab[];
  renderPanel: (activeTab: string, setActiveTab: Dispatch<SetStateAction<string | null>>) => JSX.Element | null;
};

function TabsAndPanels(props: TabsAndPanelProps) {
  const [activeTab, setActiveTab] = useState(props.tabs?.length > 0 ? props.tabs[0].label : null);
  const handleChange = (_: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const getColor = (newTab: string) => (newTab === activeTab ? "#ffffff" : "#B1B1B1");
  const renderPanel = () => {
    if (activeTab) {
      return (
        <div role="tabpanel" aria-labelledby="tab-panel" className="overflow-hidden pl-16 pr-16 pt-8 pb-8" style={{ height: "100vh" }}>
          <div className="h-full">
            {props.renderPanel(activeTab, setActiveTab)}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        sx={{
          background: "#5132A2",
          columnGap: 2,
        }}
        TabIndicatorProps={{
          style: {
            background: "transparent",
          },
        }}
      >
        {props.tabs.map((tab, index) => (
          <Tab
            key={index}
            label={
              <Typography sx={{ color: getColor(tab.label), fontSize: 14 }}>
                {tab.icon} {tab.label}
              </Typography>
            }
            value={tab.label}
            sx={{ px: 3 }}
          />
        ))}
      </Tabs>
      {renderPanel()}
    </>
  );
}
