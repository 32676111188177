import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

export function GroupEnabledCheckbox(props: {
  groupName: string;
  label: string;
  groupEnabled: boolean;
  onChangeCheckboxHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={!props.groupEnabled}
            onChange={props.onChangeCheckboxHandler}
            data-testid={`${props.groupName}-enabled-checkbox`}
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
}
