import { TitleSpecialistDashboardForManager } from "../TitleSpecialistDashboard/TitleSpecialistDashboardForManager";
import ErrorPage from "../../ErrorPage/ErrorPage";
import { TitleSpecialistDashboardForManagerProvider } from "../context/TitleSpecialistDashboardForManagerContext";
import { SharedDashboardProvider } from "../context/SharedDashboardContext";
import { TitleSpecialistDashboardProvider } from "../context/TitleSpecialistDashboardContext";
import { TitleSpecialistDashboard } from "../TitleSpecialistDashboard/TitleSpecialistDashboard";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../App";

export function TitleOrdersDashboardRouter() {
  const location = useLocation();

  if (location.pathname === ROUTES.MANAGER_DASHBOARD) {
    return (
      <TitleSpecialistDashboardForManagerProvider>
        <SharedDashboardProvider>
          <TitleSpecialistDashboardForManager />
        </SharedDashboardProvider>
      </TitleSpecialistDashboardForManagerProvider>
    );
  } else if (location.pathname === ROUTES.TITLE_SPECIALIST_DASHBOARD) {
    return (
      <TitleSpecialistDashboardProvider>
        <SharedDashboardProvider>
          <TitleSpecialistDashboard />
        </SharedDashboardProvider>
      </TitleSpecialistDashboardProvider>
    );
  } else {
    return <ErrorPage />;
  }
}
