import { Dispatch, SetStateAction, useState } from "react";
import { DashboardTitleOrder } from "../../../../networking/core/types";
import { BaseDrawer } from "../../shared/BaseDrawer";
import { convertTitleOrderToDashboardRow, DashboardRow } from "../../types";
import { RequestEscalationModal } from "../RequestEscalationModal";
import { UpdateProgressModal } from "../UpdateProgressModal";

export function TitleSpecialistDetailsDrawer(props: {
  row: DashboardTitleOrder | undefined;
  isOpen: boolean;
  onClose: () => void;
  allRows: DashboardRow[];
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
}) {
  const { row, isOpen, onClose } = props;

  const [updateProgressModalOpen, setUpdateProgressModalOpen] = useState(false);
  const [requestEscalationModalOpen, setRequestEscalationModalOpen] = useState(false);

  if (!row) return null;
  const dashboardRow = convertTitleOrderToDashboardRow(row);

  return (
    <>
      <BaseDrawer
        isOpen={isOpen}
        handleClose={onClose}
        row={dashboardRow}
        allRows={props.allRows}
        setAllRows={props.setAllRows}
        setRequestEscalationModalOpen={setRequestEscalationModalOpen}
        setUpdateProgressModalOpen={setUpdateProgressModalOpen}
        showEscalateButton={true}
        showUpdateButton={true}
      />
      {row && (
        <UpdateProgressModal
          row={row}
          setAllRows={props.setAllRows}
          open={updateProgressModalOpen}
          setOpen={setUpdateProgressModalOpen}
          closeDrawer={onClose}
        />
      )}
      {row && (
        <RequestEscalationModal
          row={row}
          open={requestEscalationModalOpen}
          setOpen={setRequestEscalationModalOpen}
          allRows={props.allRows}
          setAllRows={props.setAllRows}
          closeDrawer={onClose}
        />
      )}
    </>
  );
}
