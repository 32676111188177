import GreenCheck from "../../../../assets/green_check.svg";
import RedWarning from "../../../../assets/red_warning.svg";
import GreyCheck from "../../../../assets/grey_check.svg";
import { environmentVariables } from "../../../../config";
import { DashboardRow } from "../../types";
import { inTransitStatuses, transitBlockedStatuses } from "../../TitleSpecialistDashboard/core/types";

export type TitleSpecialistDashboardLinkCellProps = {
  row: DashboardRow;
};

export function TitleSpecialistDashboardLinkCell(props: TitleSpecialistDashboardLinkCellProps) {
  const { row } = props;
  const { aeStatus } = row;

  let icon;
  if (inTransitStatuses.includes(aeStatus)) {
    icon = GreenCheck;
  } else if (transitBlockedStatuses.includes(aeStatus)) {
    icon = RedWarning;
  } else {
    icon = GreyCheck;
  }

  return (
    <>
      <img className="pr-1" style={{ marginLeft: "-4px" }} src={icon} alt="Progress icon" />
      <a
        className="underline text-aa-purple"
        href={`${environmentVariables.aeUrl}/loan-consultants/all-leads/id=${row?.aeLeadId}`}
        target="_blank"
      >
        {row?.recordId}
      </a>
    </>
  );
}
