import CircularProgress from "@mui/material/CircularProgress";
import { FormikErrors, useFormik } from "formik";
import autoApproveLogoMonochrome from "../../assets/auto_approve_logo_monochrome.svg";
import { environmentVariables } from "../../config";
import { noInitialErrorsButInvalid } from "../../validation/formik";

const AUTO_APPROVE_APP_URL = `${environmentVariables.aeUrl}`;

export type SignInCredentials = {
  username: string;
  password: string;
};

type SignInFormProps = {
  onSubmit: (value: SignInCredentials) => void;
  loading: boolean;
  showInvalidCredentialsError: boolean;
};

export function SignInForm(props: SignInFormProps) {
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    initialErrors: noInitialErrorsButInvalid(),
    onSubmit: props.onSubmit,
    validate: ({ username, password }) => {
      const errors: FormikErrors<{ username: string; password: string }> = {};

      if (username === "") {
        errors.username = "Bad username";
      }
      if (password === "") {
        errors.password = "Bad password";
      }
      return errors;
    },
  });

  return (
    <div className="bg-white py-8 flex justify-center items-center flex-col gap-1 sm:w-11/12 md:w-5/12 lg:w-1/4 m-auto absolute inset-x-0 mt-44">
      <img className="w-1/2 m-auto mb-4" src={autoApproveLogoMonochrome} alt="Auto Approve Logo" />

      <div className="w-3/5">
        <h2 className="mb-4">Welcome to Titles Engine. Please sign in to continue. 👇</h2>
      </div>

      <form className="w-3/5 flex flex-col gap-2 justify-center items-center" onSubmit={formik.handleSubmit}>
        <div className="w-full flex flex-col justify-center items-center">
          <input
            className={`py-1 px-2 border-2 w-full rounded-md ${conditionalInputClassNames(
              Boolean(formik.errors.username),
              props.showInvalidCredentialsError
            )}`}
            type="text"
            id="username"
            name="username"
            data-cy="username"
            placeholder="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </div>

        <div className="w-full flex flex-col mt-5 gap-2 justify-center items-center">
          <input
            className={`py-1 px-2 border-2 w-full rounded-md ${conditionalInputClassNames(
              Boolean(formik.errors.password),
              props.showInvalidCredentialsError
            )}`}
            type="password"
            id="password"
            name="password"
            data-cy="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </div>

        {displayInvalidCredentialsError(props.showInvalidCredentialsError)}

        <button
          data-cy="submit"
          className="m-6 py-3 px-2 bg-gray-200 active:bg-gray-400 rounded-3xl w-4/5 text-white text-13"
          type="submit"
          style={{
            background: "#5230A2",
            boxShadow: "0px 3px 1px -2px #BDBDBD, 0px 2px 2px #BDBDBD, 0px 1px 5px #BDBDBD",
          }}
          data-testid="sign-in-button"
        >
          <div className="relative">
            SIGN IN
            {props.loading && (
              <CircularProgress
                data-testid="loading-indicator"
                className="absolute right-0"
                size={20}
                thickness={8.0}
                color={"inherit"}
              />
            )}
          </div>
        </button>
      </form>
      {/* Taking this button out for the time being, so that users cannot log in with azure until feature is complete */}
      {/* <button
        data-cy="submit"
        className="py-3 px-2 bg-gray-200 active:bg-gray-400 rounded-3xl text-white text-13 w-2/4"
        type="submit"
        style={{background: "#5230A2", boxShadow: "0px 3px 1px -2px #BDBDBD, 0px 2px 2px #BDBDBD, 0px 1px 5px #BDBDBD"}}
        data-testid="sign-in-with-azure-button"
        onClick={signInWithAzure}
      >
        SIGN IN WITH AZURE
      </button> */}
      <a className="text-center" style={{ color: "#5230A2" }} href={`${AUTO_APPROVE_APP_URL}/auth/forgot-password`}>
        Forgot your password?
      </a>
    </div>
  );
}

function conditionalInputClassNames(isInvalidInput: boolean, showError: boolean) {
  return isInvalidInput || showError ? "border-2 border-red-500" : "";
}

function displayInvalidCredentialsError(showInvalidCredentialsError: boolean) {
  if (showInvalidCredentialsError) {
    return <p className="text-red-500">Invalid Credentials</p>;
  }
}
