import { Box } from "@mui/material";
import { UiEstimateTemplateField, FieldGroup, TitleOrderField } from "../types";
import { GroupEnabledCheckbox } from "./GroupEnabledCheckbox";
import React, { useState } from "react";
import { CreateEstimateTitleOrder, ExcludedFieldGroup } from "../../../../../networking/core/types";
import { DynamicEstimateTemplateField } from "./DynamicEstimateTemplateField";
import { getCopy } from "../../../../../authoring/copy";
import { isPrivatePartyOverrideFieldRequired } from "../../../../../helpers/customRequiredFields";
import { useFlags } from "launchdarkly-react-client-sdk";

const borrowerCopy = getCopy().createEstimate.submitEstimate.borrower;

export function DynamicFieldGroup(props: {
  group: FieldGroup;
  knownFields: TitleOrderField[];
  unknownFields: UiEstimateTemplateField[];
  titleOrder: CreateEstimateTitleOrder | undefined;
  setExcludedFieldGroup: (value: ExcludedFieldGroup) => void;
}) {
  const [groupEnabled, setGroupEnabled] = useState<boolean>(true);
  const group = props.group;
  const [knownFields, setKnownFields] = useState(props.group.knownFields);
  const flags = useFlags();

  const onChangeCheckboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupEnabled(!e.target.checked);
    props.setExcludedFieldGroup({ secondaryBorrower: e.target.checked });
  };
  
  const fieldValueChange = (field: TitleOrderField, titleOrder: CreateEstimateTitleOrder) => {
    if (flags.typeOfPurchaseWklsTemplateField && field.displayLabel === "Type of Purchase") {
      const value = field.getValue(titleOrder);
      if (value === "Private Party") {
        setKnownFields(knownFields.map(knownField => {
          return {
            ...knownField,
            estimateTemplateField: {
              ...knownField.estimateTemplateField,
              required: isPrivatePartyOverrideFieldRequired(knownField),
            }
          };
        }));
      } else {
        setKnownFields(props.group.knownFields);
      }
    }
  };

  return (
    <Box boxShadow={2} sx={{ width: 878, margin: "auto", borderRadius: 5, mb: 3 }}>
      <div data-testid={`submit-estimate-${group.groupName}`}>
        <div className="px-14 py-11">
          {group && (
            <p className="font-weight-500 text-2xl pb-8" style={{ color: "#200A52" }}>
              {group.groupName}
            </p>
          )}

          {shouldShowGroupEnabledCheckbox(group) && (
            <GroupEnabledCheckbox
              groupName={group.groupName}
              label={borrowerCopy.secondaryCheckBoxLabel}
              groupEnabled={groupEnabled}
              onChangeCheckboxHandler={onChangeCheckboxHandler}
            />
          )}

          {groupEnabled &&
            knownFields.map((field, index) => (
              <div className="flex items-center mt-4" key={index}>
                <span className="font-bold text-base text-aa-dark-grey w-56">{field.displayLabel}</span>
                <DynamicEstimateTemplateField
                  field={field.estimateTemplateField}
                  disabled={!groupEnabled}
                  knownFields={knownFields}
                  unknownFields={props.unknownFields}
                  titleOrder={props.titleOrder}
                  onValueChange={fieldValueChange}
                />
              </div>
            ))}
          {groupEnabled &&
            group.unknownFields.map((field, index) => (
              <div className="flex items-center mt-4" key={index}>
                <span className="font-bold text-base text-aa-dark-grey w-56">{field.name}</span>
                <DynamicEstimateTemplateField
                  field={field}
                  disabled={!groupEnabled}
                  knownFields={knownFields}
                  unknownFields={props.unknownFields}
                  titleOrder={props.titleOrder}
                />
              </div>
            ))}
        </div>
      </div>
    </Box>
  );
}

function shouldShowGroupEnabledCheckbox(group: FieldGroup) {
  return group.groupName === borrowerCopy.secondaryGroupName;
}