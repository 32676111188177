import { WORKFLOW_HOST_URL } from "../core/types";
import axios, { AxiosRequestConfig } from "axios";
import { isUserToken, UserToken } from "./types";

export async function getUserToken(username: string, password: string): Promise<UserToken> {
  const path = "/login";
  const payload = {
    username,
    password,
  };
  const config = getUnauthorizedConfig();
  try {
    const resp = await axios.post(path, payload, config);

    if (!isUserToken(resp.data)) {
      return Promise.reject(
        new Error(`Expected response payload type to be a UserToken response.\nReceived: ${resp.data}`)
      );
    }

    return resp.data;
  } catch (e: unknown) {
    return Promise.reject(e);
  }
}

function getUnauthorizedConfig(): AxiosRequestConfig {
  return {
    baseURL: WORKFLOW_HOST_URL,
  };
}

export async function validateToken(token: string, expiresIn: number): Promise<UserToken> {
  const path = "/validate-ae-token";
  const payload = {
    token,
    expiresIn,
  };
  const config = getUnauthorizedConfig();
  const resp = await axios.post(path, payload, config);

  if (!isUserToken(resp.data)) {
    return Promise.reject(
      new Error(`Expected response payload type to be a UserToken response.\nReceived: ${resp.data}`)
    );
  }
  return resp.data;
}
