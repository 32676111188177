import axios, { AxiosRequestConfig } from "axios";
import { environmentVariables } from "../../config";

export async function generateFundingPacket(leadId: string, signal: AbortSignal, token: string): Promise<{ status: number }> {
  const url = `${environmentVariables.titlesWorkflowApi}/document-generation/funding-packet`;
  const config: AxiosRequestConfig = {
    signal,
    headers: {
      Authorization: token,
    }
  };

  const body = {
    leadId,
  };

  const response = await axios.post(url, body, config);
  if (response) {
    return response.data;
  }
  return Promise.reject("Unable to generate funding packet");
}
