import {
    NotesPanel as NotesPanelUI,
    NotesPanelProvider,
    AeNotes,
    AeNote
} from "@auto-approve/auto-approve-ui-library";
import { createNote, getNotes } from "../../../../networking/aeLeadNotes/TitlesWorkflowLeadNotesApi";
import { environmentVariables } from "../../../../config";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../../Auth/AuthProvider";

export type NotesSectionProps = {
    leadOid: string;
};

export const NotesSection = (props: NotesSectionProps) => {
    const { leadOid } = props;
    const { user } = useAuth();
    const token = user?.token ?? "";
    const queryClient = useQueryClient();

    const getNotesWrapper = async (leadId: string, skip = 0): Promise<AeNotes> => {
        return await getNotes(token, leadId, skip);
    };

    const createNoteWrapper = async (leadId: string, note: string): Promise<AeNote> => {
        return await createNote(token, leadId, note);
    };

    return (
        <NotesPanelProvider
            createNote={createNoteWrapper}
            getNotes={getNotesWrapper}
            leadId={leadOid}
            aeBaseUrl={environmentVariables.aeUrl}
            queryClient={queryClient}
            refresh
            retryOptions={{
                enabled: true
            }}
            >
            <NotesPanelUI />
        </NotesPanelProvider>
    );
};
