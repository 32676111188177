import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import {
  GridCallbackDetails,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRowParams,
  GridSelectionModel,
  GridSortModel,
  gridVisibleRowCountSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { AdditionalDashboardColumn, DashboardRow, SortOption, toGridSortModel, toSortOptions } from "./types";
import { useAuth } from "../Auth/AuthProvider";
import { getCopy } from "../../authoring/copy";
import {
  AssociateTitleSpecialistDashboardContextType,
  TitleSpecialistDashboardContextType,
  TitleSpecialistDashboardForManagerContextType,
} from "./context/types";
import { fieldsWithDisabledCellClick } from "./helpers";
import { useSharedDashboard } from "./context/SharedDashboardContext";
import { refreshDashboardRowFromAe } from "./refreshDashboardRow";

export type DashboardTableProps = {
  rows: DashboardRow[];
  totalRows: number;
  getColumns: (sortOptions?: SortOption[]) => GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | AdditionalDashboardColumn }[];
  hasRowRefreshButtons: boolean;
  defaultPageSize: number;
  rowsPerPageOptions: number[];
  filterMode: "client" | "server";
  sortingMode: "client" | "server";
  paginationMode: "client" | "server";
  rowsPerPage?: number;
  setRowsPerPage?: (pageSize: number) => void;
  setSkipRows?: React.Dispatch<React.SetStateAction<number>>;
  setVisibleRowCount?: (count: number) => void;
  useData: () =>
    | TitleSpecialistDashboardForManagerContextType
    | TitleSpecialistDashboardContextType
    | AssociateTitleSpecialistDashboardContextType;
  checkboxSelection?: boolean;
  onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
  onCellClick?: (row: DashboardRow) => void;
  isRowSelectable?: (row: GridRowParams) => boolean;
  accordionFlag?: boolean;
};

export const DashboardTable = React.memo(function DashboardTable(props: DashboardTableProps) {
  const { user } = useAuth();
  const apiRef = useGridApiRef();
  const { setFilterModel, setSortOptions, sortOptions, loading, filterModel: filter, setAllRows } = props.useData();
  const { checkboxSelection = false } = props;
  const { page, setPage } = useSharedDashboard();

  const onFilterChange = (filterModel: GridFilterModel) => {
    const filterOption = filterModel.items[0];
    if (!filterOption) return;

    const value = filterOption.value === undefined ? "" : filterOption.value;
    setFilterModel(filterModel);
    if (value !== "") {
      setPage(0);
    }
  };

  const onSortChange = (sortModel: GridSortModel) => {
    setSortOptions(toSortOptions(sortModel));
    setPage(0);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    if (!props.setSkipRows || !props.rowsPerPage) return;
    props.setSkipRows(page * props.rowsPerPage);
  };

  const onPageSizeChange = (pageSize: number) => {
    if (!props.setRowsPerPage || !props.setSkipRows) return;
    props.setRowsPerPage(pageSize);
    props.setSkipRows(page * pageSize);
  };

  const sortModelValue = toGridSortModel(sortOptions);

  return (
    <Box height={`calc(100vh - ${props.accordionFlag ? 452 : 202}px)`} sx={{overflowX: "auto"}}>
      <DataGridPremium
        pagination={true}
        disableRowGrouping={true}
        page={page}
        isRowSelectable={props.isRowSelectable}
        loading={loading}
        rows={props.rows}
        onCellClick={(params: GridCellParams) => {
          if (!props.onCellClick) return undefined;
          if (!fieldsWithDisabledCellClick.includes(params.field)) {
            props.onCellClick(params.row);
          }
        }}
        filterMode={props.filterMode}
        onFilterModelChange={onFilterChange}
        sortingMode={props.sortingMode}
        onSortModelChange={onSortChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        columns={getColumns(sortOptions)}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        onSelectionModelChange={props.onSelectionModelChange}
        initialState={{
          pagination: {
            page: page,
            pageSize: props.defaultPageSize,
          },
          filter: {
            filterModel: filter,
          },
          sorting: {
            sortModel: toGridSortModel(sortOptions),
          },
          columns: {
            columnVisibilityModel: {
              lienholderName: false,
              lienholderPhone: false,
              customerBirthday: false,
              customerPhone: false,
              fundedDate: false,
              dealType: false,
              lenderPhone: false,
            },
          },
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            excelOptions: { disableToolbarButton: true }
          },
        }}
        filterModel={filter}
        sortModel={sortModelValue}
        rowsPerPageOptions={props.rowsPerPageOptions}
        paginationMode={props.paginationMode}
        rowCount={props.totalRows}
        onStateChange={
          props.setVisibleRowCount
            ? (state) => {
              const count = gridVisibleRowCountSelector(state, apiRef.current.instanceId);
              props.setVisibleRowCount?.(count);
            }
            : undefined
        }
        sx={{
          "& .MuiDataGrid-columnHeader": {
            borderTop: "1px solid rgb(224, 224, 224)",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid rgb(224, 224, 224)",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid rgb(224, 224, 224)"
          },
          "& .MuiDataGrid-row": {
            borderBottom: "1px solid rgb(224, 224, 224)",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </Box>
  );

  function getColumns(sortOptions?: SortOption[]) {
    const columns = [...props.getColumns(sortOptions)];
    if (props.hasRowRefreshButtons) {
      columns.push({
        disableColumnMenu: true,
        field: "refreshButton",
        filterable: false,
        flex: 1,
        headerName: "",
        renderCell: (params) => <RefreshButton row={params.row} />,
        sortable: false,
      });
    }
    return columns;
  }

  function RefreshButton(props: { row: DashboardRow }) {
    const { titleOrdersDashboard } = getCopy();
    const [isDisabled, setIsDisabled] = useState(false);

    return (
      <Button
        disabled={isDisabled}
        onClick={() => {
          setIsDisabled(true);
          refreshCallback(props.row).finally(() => setIsDisabled(false));
        }}
        data-testid={`refresh-titleOrderId-${props.row.titleOrderId}`}
      >
        {titleOrdersDashboard.refreshButton.label}
      </Button>
    );
  }

  async function refreshCallback(row: DashboardRow) {
    if (!user) {
      return;
    }

    await refreshDashboardRowFromAe(user.token, row.aeLeadId, props.rows, setAllRows);
  }
});
