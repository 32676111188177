import { DateTime } from "luxon";
import { Navigate, useLocation } from "react-router-dom";
import { now } from "../../contexts/time/now";
import { useAuth } from "./AuthProvider";
import { clearUserFromLocalStorage } from "./AuthHelper";

export function AuthRequired({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  const location = useLocation();

  function isTokenExpired(expirationTime: string) {
    return now() > DateTime.fromISO(expirationTime);
  }

  if ((!user || isTokenExpired(user.expirationTime)) && !localStorage.getItem("azureAuthResult")) {
    clearUserFromLocalStorage();
    return <Navigate to={"/"} state={{ from: location }} />;
  }
  return children;
}
