import { Role } from "@auto-approve/auto-approve-ui-library";
import { ROUTES } from "../../App";
import { isRole } from "./RoleRequired";

export function clearUserFromLocalStorage() {
  localStorage.removeItem("user");
}

export function getHome(user: string | null): string {
  if (!user) {
    return ROUTES.ROOT;
  }

  const role = JSON.parse(user).role;
  if (!isRole(role)) {
    return ROUTES.ROOT;
  }

  switch (role) {
    case Role.ASSOCIATE_TITLE_SPECIALIST:
      return ROUTES.ASSOCIATE_TITLE_SPECIALIST_DASHBOARD;

    case Role.LOAN_CONSULTANT:
    case Role.SENIOR_LOAN_SUPPORT_SPECIALIST:
    case Role.LOAN_SUPPORT_SPECIALIST:
      return ROUTES.CREATE_ESTIMATE;

    case Role.MANAGER:
      return ROUTES.MANAGER_DASHBOARD;
    case Role.TITLE_SPECIALIST:
      return ROUTES.TITLE_SPECIALIST_DASHBOARD;
    case Role.LOAN_PROCESSOR:
    case Role.ADMIN:
    case Role.SUPER_ADMIN:
    case Role.UNDERWRITER:
    case Role.LOAN_QUALITY_SPECIALIST:
    case Role.SENIOR_LOAN_PROCESSOR:
      return ROUTES.DOCUMENT_MANAGEMENT;

    default: {
      const _unhandledRole: never = role;
      return ROUTES.ROOT;
    }
  }
}
