import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

type TeQueryParams = "recordId" | "leadId";
export const useQueryParams = (key: TeQueryParams): string | null => {
  const getValueFromQueryParams = (search: string, key: string) => {
    const query = new URLSearchParams(search);
    return query.get(key);
  };
  return getValueFromQueryParams(useLocation().search, key);
};