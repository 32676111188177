import { Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TitleOrderDocEta, TitleOrderTask } from "../../../../networking/core/types";
import { CustomButton } from "../Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateTime } from "luxon";
import { isUndefined } from "lodash";
import { getCopy } from "../../../../authoring/copy";

const isDefined = (arg: unknown) => !isUndefined(arg);
const { eta, datePicker } = getCopy().associateTitleSpecialistDashboard.taskPanel;

type TaskItemProps = {
  task: TitleOrderTask & { loading: boolean } & DatePickerControls;
  showDivider: boolean;
  onToggle: (checked: boolean) => void;
  titleOrderDocEta: TitleOrderDocEta | undefined;
  handleAddEta: (task: TitleOrderTask & { selectedEtaDate: DateTime | null }) => Promise<void>;
  areDocsReceived: boolean;
};

type DatePickerControls = {
  selectedEtaDate: DateTime | null;
  setSelectedEtaDate: (val: DateTime | null) => void;
};

export function TaskItem({
  task,
  onToggle,
  showDivider,
  titleOrderDocEta,
  handleAddEta,
  areDocsReceived,
}: TaskItemProps) {
  return (
    <>
      <div className={`${showDivider && "border-b-2 border-dashed border-gray-200"} pt-4`}>
        <div className={"py-5 flex flex-row justify-between"}>
          <ul className="list-disc">
            <li>
              <span className={`text-sm font-bold ${areDocsReceived ? "italic text-gray-500" : "not-italic"}`}>
                {task.label}
              </span>
              <ol className="list-decimal">
                {task.instructions.map((instruction, index) => (
                  <li
                    key={index}
                    className={`ml-4 mt-1 w-64 text-sm ${areDocsReceived ? "italic text-gray-400" : "not-italic"}`}
                  >
                    {instruction}
                  </li>
                ))}
              </ol>
            </li>
          </ul>
          {task.loading ? (
            <CircularProgress size={20} thickness={8} />
          ) : (
            <div>
              {task.completed && <span className="text-aa-dark-purple text-sm italic mr-2">Done</span>}
              <Checkbox
                checked={task.completed || isDefined(titleOrderDocEta) || areDocsReceived}
                disabled={
                  (isDefined(titleOrderDocEta) && !isEtaRelatedToTask(titleOrderDocEta, task)) || areDocsReceived
                }
                sx={{ alignSelf: "flex-start", p: 0 }}
                onChange={(event) => onToggle(event.target.checked)}
              />
            </div>
          )}
        </div>

        {/* When docs are received flow */}
        {areDocsReceived && titleOrderDocEta && isEtaRelatedToTask(titleOrderDocEta, task) && (
          <div className="flex justify-between align-middle bg-gray-50 py-4 px-8">
            {isEtaRelatedToTask(titleOrderDocEta, task) ? (
              <Typography sx={{ marginLeft: "8px" }}>
                {eta.datePrefix + titleOrderDocEta.date.toLocaleString()}
              </Typography>
            ) : null}
          </div>
        )}

        {/* Existent flow */}
        {task.completed && !areDocsReceived && (
          <div
            className={`flex ${
              isDefined(titleOrderDocEta) && !isEtaRelatedToTask(titleOrderDocEta, task)
                ? "justify-end"
                : "justify-between"
            } align-middle bg-gray-50 py-4 px-8`}
          >
            {titleOrderDocEta ? (
              isEtaRelatedToTask(titleOrderDocEta, task) ? (
                <Typography sx={{ marginLeft: "8px" }}>
                  {eta.datePrefix + titleOrderDocEta.date.toLocaleString()}
                </Typography>
              ) : null
            ) : (
              <div>
                {
                  <div data-testid={"datePickerTestid"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={datePicker.label}
                        value={task.selectedEtaDate}
                        getOpenDialogAriaText={(_date, _utils) => datePicker.openDialogAriaLabel}
                        onChange={(etaDate) => {
                          if (etaDate) {
                            const etaFromMillis = DateTime.fromMillis(etaDate.valueOf());
                            task.setSelectedEtaDate(etaFromMillis.isValid ? etaFromMillis : null);
                            if (!etaFromMillis.isValid) {
                              alert("Date conversion resulted in an invalid date - Please file a bug report.");
                            }
                          } else {
                            task.setSelectedEtaDate(null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>

                    {task.selectedEtaDate && (
                      <div>
                        <CustomButton
                          onClick={() => {
                            handleAddEta(task);
                          }}
                          variant={"text"}
                        >
                          {eta.saveButton.label}
                        </CustomButton>
                        <CustomButton
                          onClick={() => {
                            task.setSelectedEtaDate(null);
                          }}
                          variant={"text"}
                        >
                          {eta.cancelButton.label}
                        </CustomButton>
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
            <div className="text-center self-center flex-row flex">
              <CalendarMonthIcon />
              <Typography sx={{ marginLeft: "8px" }}>{task.updatedAt.toFormat("MM/dd/yyyy, h:mm a")}</Typography>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function isEtaRelatedToTask(titleOrderDocEta: TitleOrderDocEta | undefined, task: TitleOrderTask): boolean {
  return titleOrderDocEta?.titleOrderTaskId === task.id;
}
