import { AddOutlined, ArrowBackIos } from "@mui/icons-material";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useLayoutEffect, useState } from "react";
import { getCopy } from "../../../../authoring/copy";
import { TaskNote } from "../../../../networking/core/types";
import { createTitleOrderTaskNote, getTitleOrderTaskNotes } from "../../../../networking/TitlesWorkflowAPI";
import { useAuth } from "../../../Auth/AuthProvider";
import { CustomButton } from "../../shared/Button";
import { formatDate } from "../../shared/FieldFormats";

export function TaskNotes(props: { titleOrderId: number }) {
  const { titleOrderId } = props;
  const [notes, setNotes] = useState<TaskNote[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [newNoteText, setNewNoteText] = useState("");

  const { user } = useAuth();
  const { taskNotes } = getCopy().associateTitleSpecialistDashboard;

  useLayoutEffect(() => {
    if (!isNoteModalOpen) return;

    const drawerPaperElement = document.querySelector<HTMLDivElement>("#base-drawer");
    if (!drawerPaperElement) return;

    const originalStyle = drawerPaperElement.style.overflow;
    drawerPaperElement.style.overflow = "hidden";
    drawerPaperElement.scroll(0, 0);

    return () => {
      drawerPaperElement.style.overflow = originalStyle;
    };
  }, [isNoteModalOpen]);

  useEffect(() => {
    if (user?.token) {
      getTitleOrderTaskNotes(titleOrderId, user.token)
        .then((response) => {
          setNotes(response.notes);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [titleOrderId, user?.token]);

  async function saveNewNote() {
    if (!user) return;

    setIsSaving(true);
    createTitleOrderTaskNote(
      {
        text: newNoteText,
        titleOrderId,
        user,
      },
      user.token
    )
      .then((newNote) => {
        setNotes((notes) => [newNote, ...notes]);
        resetModal();
      })
      .finally(() => setIsSaving(false));
  }

  function resetModal() {
    setIsNoteModalOpen(false);
    setNewNoteText("");
  }

  return (
    <>
      {isNoteModalOpen && (
        <div className="absolute top-28 right-0 bg-white w-full h-full overflow-hidden z-10 flex items-center flex-col">
          <div className="w-full border-b-2 border-gray-200 h-14 flex justify-center items-center relative pb-2">
            <div className="absolute top-3 left-7">
              <ArrowBackIos fontSize="small" onClick={() => setIsNoteModalOpen(false)} sx={{ cursor: "pointer" }} />
            </div>
            <span className="text-sm font-bold leading-4 text-gray-700">{taskNotes.newNoteTitle}</span>
          </div>
          <TextareaAutosize
            value={newNoteText}
            onChange={(event) => setNewNoteText(event.target.value)}
            minRows={4}
            maxRows={4}
            aria-label="maximum height"
            placeholder={taskNotes.newNotePlaceHolderText}
            style={{
              width: "93%",
              height: "63%",
              marginTop: "1.1rem",
              outline: "none",
              borderBottom: "2px solid rgb(229 231 235)",
              resize: "none",
            }}
          />
          <div className="self-end h-20 flex items-center mr-12">
            <CustomButton variant="text" disabled={newNoteText === ""} onClick={saveNewNote}>
              {isSaving ? (
                <>
                  <CircularProgress className="m-auto mr-2" size={20} thickness={8} />
                  {taskNotes.saveNoteButton.loadingLabel}
                </>
              ) : (
                taskNotes.saveNoteButton.label
              )}
            </CustomButton>
          </div>
        </div>
      )}
      <div className="flex justify-between w-full pb-5">
        <p className="font-bold text-lg text-aa-dark-purple">{taskNotes.notesTitle}</p>
        <CustomButton variant="text" onClick={() => setIsNoteModalOpen(true)}>
          <AddOutlined /> {taskNotes.addNewNoteButton.label}
        </CustomButton>
      </div>
      <div className="w-full grid grid-cols-1 gap-11">
        {isLoading && (
          <div className="flex w-full h-64 ">
            <CircularProgress className="m-auto" size={40} thickness={8} />
          </div>
        )}
        {notes.map((note) => (
          <Note note={note} key={note.id} />
        ))}
      </div>
    </>
  );
}

export function Note(props: { note: TaskNote }) {
  const { text, user, createdAt } = props.note;
  return (
    <div className="bg-aa-light-grey h-32 w-full p-7 relative">
      <p className="text-sm break-normal" style={{ letterSpacing: "0.15px" }}>
        {text}
      </p>
      <span className="absolute right-0 -bottom-5 text-aa-text-grey text-xs">
        {user.firstName} {user.lastName} at {formatDate(createdAt, { formatOptions: DateTime.DATETIME_SHORT })}
      </span>
    </div>
  );
}
