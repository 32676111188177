import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../App";
import { getCopy } from "../../authoring/copy";
import { AppHeader } from "../AppHeader/AppHeader";
import { useAuth } from "../Auth/AuthProvider";
import { canCreateEstimate, isAssociateTitleSpecialist, isManager, isTitleSpecialist } from "../Auth/Auth";
import { useFlags } from "launchdarkly-react-client-sdk";

const {
  titleOrdersDashboard,
  escalationsDashboard,
  associateTitleSpecialistDashboard,
  associateTitleSpecialistMyQueue,
  titleSpecialistDashboard,
  createEstimate,
  documentManagement
} = getCopy();

export function NavBar() {
  const { user } = useAuth();
  const flags = useFlags();

  if (!user) {
    return <></>;
  }

  // TODO - TE-1024 Manager view of ATS dashboard
  // <NavLink
  //     to={ROUTES.ASSOCIATE_TITLE_SPECIALIST_DASHBOARD}
  //     label={associateTitleSpecialistDashboard.navLabel}
  // />
  return (
    <nav className="w-full p-0 border-b-2 border-gray-100 flex-2">
      <ul className="flex gap-6">
        {isManager(user) && (
          <>
            {flags.titlesEscalationsFeature && <NavLink to={ROUTES.ESCALATIONS} label={escalationsDashboard.navLabel} />}
            <NavLink to={ROUTES.MANAGER_DASHBOARD} label={titleSpecialistDashboard.nav.managerTabLabel} />
          </>
        )}
        {isTitleSpecialist(user) && (
          <>
            <NavLink to={ROUTES.TITLE_SPECIALIST_DASHBOARD} label={titleSpecialistDashboard.nav.tsTabLabel} />
            <NavLink to={ROUTES.MANAGER_DASHBOARD} label={titleSpecialistDashboard.nav.managerTabLabel} />
            <NavLink to={ROUTES.TITLE_SPECIALIST_IN_TRANSIT_DASHBOARD} label={titleOrdersDashboard.inTransitNavLabel} />
          </>
        )}
        {isAssociateTitleSpecialist(user) && (
          <>
            <NavLink
              to={ROUTES.ASSOCIATE_TITLE_SPECIALIST_DASHBOARD}
              label={associateTitleSpecialistDashboard.navLabel}
            />
            <NavLink to={ROUTES.ASSOCIATE_TITLE_SPECIALIST_MY_QUEUE} label={associateTitleSpecialistMyQueue.navLabel} />
          </>
        )}
        {(canCreateEstimate(user)) && (
          <NavLink to={ROUTES.CREATE_ESTIMATE} label={createEstimate.navLabel} />
        )}
        <NavLink to={ROUTES.DOCUMENT_MANAGEMENT} label={documentManagement.navLabel} />
      </ul>
    </nav>
  );
}

export function NavBarWithHeader() {
  const { user } = useAuth();
  const location = useLocation();

  if (location.pathname === ROUTES.ROOT) return null;

  return (
    <div className="px-14" style={{ position: "fixed", top: 0, background: "white", zIndex: 100, width: "100%" }}>
      <AppHeader></AppHeader>
      {user && <NavBar />}
    </div>
  );
}

function NavLink(props: { to: string; label: string }) {
  const { pathname } = useLocation();
  const { to, label } = props;

  const active = pathname === to;

  return (
    <Link to={to}>
      <li className={`py-4 text-lg font-medium ${active ? "text-aa-purple border-b-4 border-aa-purple" : ""}`}>
        {label}
      </li>
    </Link>
  );
}
