import { IconButton, Tooltip } from "@mui/material";

export function IconBarButton(props: {
  children: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipText?: string;
  dataTestId?: string;
  dataCy?: string;
}) {
  return (
    <Tooltip title={<>{props.tooltipText}</>}>
      <IconButton onClick={props.onClick} data-testid={props.dataTestId} data-cy={props.dataCy}>
        {props.children}
      </IconButton>
    </Tooltip>
  );
}
