import Page from "../shared/Page/Page";
import { useRef, useState } from "react";
import { LookupByRecordId } from "./steps/0_LookupByRecordId";
import { ConfirmEstimate } from "./steps/1_ConfirmEstimate";
import { SubmitForEstimate } from "./steps/submitEstimate/2_SubmitEstimate";
import { NextSteps } from "./steps/3_NextSteps";
import { CreateEstimateStep } from "./types";
import { CreateEstimateCustomer, CreateEstimateTitleOrder } from "../../networking/core/types";
import { getRefreshedTitleOrderByLeadId, getRefreshedTitleOrderByRecordId } from "../../networking/TitlesWorkflowAPI";
import { getEstimateTemplate } from "../../networking/estimates/TitlesWorkflowEstimatesApi";
import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { getCopy } from "../../authoring/copy";
import { UIEstimateTemplate } from "../../contexts/estimates/core/types";
import { useAuth } from "../Auth/AuthProvider";
import { ROUTES } from "../../App";
import { wklsEstimateTransactionDisplayTypeTo } from "../converters";
import { WklsEstimateTransactionDisplayType } from "../types";
import { toCreateEstimateTitleOrder } from "../../networking/title_orders/converters";
import { useQueryParams } from "../../Hooks/customHooks";

export function CreateEstimate() {
  const navigate = useNavigate();

  const { createEstimate } = getCopy();

  const recordId = useQueryParams("recordId");
  const leadId = useQueryParams("leadId");

  const [step, setStep] = useState(CreateEstimateStep.Lookup);
  const [estimateTemplate, setTemplate] = useState<UIEstimateTemplate | null>(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState("");
  const titleOrder = useRef<CreateEstimateTitleOrder | undefined>(undefined);
  const transactionType = useRef<WklsEstimateTransactionDisplayType | undefined>(undefined);
  const [currentRecordId, setCurrentRecordId] = useState("");
  const [currentLeadId, setCurrentLeadId] = useState("");
  const [ lookupMethodUsed, setLookupMethodUsed] = useState("");
  const [nextStepIsLoading, setNextStepIsLoading] = useState(false);

  const uncheckedUser = useAuth().user;

  if (!uncheckedUser) {
    return <Navigate to={"/"} state={{ from: location }} />;
  }
  const user = uncheckedUser;

  const handleClose = () => {
    setErrorDialogOpen(false);
  };
  return (
    <div className={"w-full"}>
      <Page>
        <div>
          <CreateEstimateStepComponent />
        </div>
        <Dialog
          open={errorDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{createEstimate.errorHeader}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorDialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {createEstimate.errorOkayButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    </div>
  );

  function lookupRecordIdStep() {
    return (
      <LookupByRecordId
        onSubmit={async ({ recordId }) => {
          setNextStepIsLoading(true);
          setCurrentRecordId(recordId);
          const successful = await lookupTitleOrderByRecordId(parseInt(recordId));
          if (successful) {
            setStep(CreateEstimateStep.Confirm);
            setNextStepIsLoading(false);
          }
          setLookupMethodUsed("lookupByRecordId");
          setNextStepIsLoading(false);
        }}
        nextStepIsLoading={nextStepIsLoading}
        defaultRecordId={recordId}
      />
    );
  }

  function confirmEstimateStep() {
    return (
      <ConfirmEstimate
        onGoBack={() => {
          if (lookupMethodUsed === "lookupByRecordId") {
            setStep(CreateEstimateStep.Lookup);
          } 
          if (lookupMethodUsed === "lookupByLeadId") {
            navigate(ROUTES.LANDING);
          }
        }}
        onConfirmEstimate={async (selectedTransactionType, state, county, aeRecordId: string) => {
          transactionType.current = selectedTransactionType;

          await getEstimateTemplate(
            user.token,
            wklsEstimateTransactionDisplayTypeTo(selectedTransactionType),
            state,
            county,
            aeRecordId
          )
            .then((template) => {
              setTemplate(template);
              setStep(CreateEstimateStep.Submit);
            })
            .catch((e) => {
              console.error(e);
              navigate(ROUTES.CREATE_ESTIMATE_ERROR);
            });
        }}
        titleOrder={titleOrder.current}
      />
    );
  }

  function submitEstimateStep() {
    return (
      <SubmitForEstimate
        estimateTemplate={estimateTemplate}
        titleOrder={titleOrder.current}
        transactionType={transactionType.current}
        setCreateEstimateStep={setStep}
      />
    );
  }

  function nextStepsStep() {
    return <NextSteps recordId={currentRecordId} aeLeadId={currentLeadId} />;
  }

  function CreateEstimateStepComponent() {
    if (step === CreateEstimateStep.Lookup) {
      if (leadId && !errorDialogText) {
        getRefreshedTitleOrderByLeadId(user.token, leadId)
        .then((order) => {
            titleOrder.current = titleOrderWithSecondaryCustomer(toCreateEstimateTitleOrder(order));
            setStep(CreateEstimateStep.Confirm);
            setCurrentLeadId(leadId);
            setCurrentRecordId(order.aeRecordId.toString());
            setLookupMethodUsed("lookupByLeadId");
          })
          .catch((err) => {
            console.log(
              `Unable to retrieve lead using the given lead ID: ${leadId}. Defaulting to looking up by record ID. Error: ${err}`
            );
            setErrorDialogText(`Unable to retrieve lead using the given lead ID: ${leadId}.`);
          });
          return (
            <Box
              display="flex"
              margin="auto"
              width="100%"
              justifyContent="center"
              position="absolute"
              top="50%"
              sx={{ transform: "translate(0, -50%)" }}
            >
              {<CircularProgress />}
            </Box>
          );
      } else {
        return lookupRecordIdStep();
      }
    } else if (step === CreateEstimateStep.Confirm) {
      return confirmEstimateStep();
    } else if (step === CreateEstimateStep.Submit) {
      return submitEstimateStep();
    } else if (step === CreateEstimateStep.NextSteps) {
      return nextStepsStep();
    } else {
      return null;
    }
  }

  async function lookupTitleOrderByRecordId(recordId: number): Promise<boolean> {
    return getRefreshedTitleOrderByRecordId(user.token, recordId)
      .then((order) => {
        titleOrder.current = titleOrderWithSecondaryCustomer(toCreateEstimateTitleOrder(order));
        setCurrentLeadId(order.aeLeadId ?? "");
        return true;
      })
      .catch((e) => {
        console.log(`Error while looking up title order by record ID: ${JSON.stringify(e)}`);
        setErrorDialogText(createEstimate.lookupRecordId.apiErrorText);
        setErrorDialogOpen(true);
        return false;
      });
  }
}

export function customerPlaceholder(): CreateEstimateCustomer {
  return {
    firstName: "",
    middleName: "",
    lastName: "",
    birthday: undefined,
    phoneNumbers: [],
    email: undefined,
    type: undefined,
    ssn: undefined,
    driversLicenseNum: undefined,
    federalEmployerId: undefined,
    address: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
    },
    relationshipToPrimary: undefined,
  };
}

function titleOrderWithSecondaryCustomer(order: CreateEstimateTitleOrder): CreateEstimateTitleOrder {
  return {
    ...order,
    primaryCustomer: {
      ...customerPlaceholder(),
      ...order?.primaryCustomer,
    },
    secondaryCustomer: {
      ...customerPlaceholder(),
      ...order?.secondaryCustomer,
    },
  };
}
