import { User } from "../Auth/Auth";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../Auth/AuthProvider";
import { useNavigate } from "react-router";
import { ROUTES } from "../../App";
import { IconBarButton } from "../shared/IconBarButton/IconBarButton";
import { Box, Divider, Typography } from "@mui/material";

export type UserProfileProps = {
  user: User | null;
};

export function UserProfile(props: UserProfileProps) {
  const { user } = props;
  const { logout } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography>{user.username}</Typography>
      <Box sx={{ mx: "6px" }}>
        <Divider
          flexItem
          variant={"middle"}
          orientation={"vertical"}
          sx={{ borderRightWidth: "2px", borderRightColor: "#6F6F6F", height: "16px", margin: "auto" }}
        />
      </Box>
      <Typography display={"inline"} component={"span"} color={"#6F6F6F"}>
        {user.role}
      </Typography>
      <IconBarButton
        onClick={() => {
          logout(() => {
            navigate(ROUTES.ROOT, { replace: true });
          });
        }}
        dataCy="logout-button"
        dataTestId="logout-button"
        tooltipText="Logout"
      >
        <LogoutIcon />
      </IconBarButton>
    </Box>
  );
}
