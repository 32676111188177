import { useEffect } from "react";
import { convertTitleOrderToDashboardRow, DashboardRow } from "../types";
import { useAuth } from "../../Auth/AuthProvider";
import { getTitleSpecialistDashboardData } from "../../../networking/TitlesWorkflowAPI";
import { useTitleSpecialistDashboard } from "../context/TitleSpecialistDashboardContext";
import { useSharedDashboard } from "../context/SharedDashboardContext";
import { getTitleSpecialistDashboardColumns } from "../TitleOrdersDashboard/columns";
import { isCompleted, isInTransitStatus } from "./core/types";
import { TitleOrdersDashboard } from "../TitleOrdersDashboard/TitleOrdersDashboard";
import { formatAeDealType } from "../shared/FieldFormats";
import { formatAeLeadStatus } from "@auto-approve/auto-approve-ui-library";

export function TitleSpecialistDashboard() {
  const {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    setLoading,
    showCompleted
  } = useTitleSpecialistDashboard();
  const { dashboardUpdate, searchTerm } = useSharedDashboard();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    getTitleSpecialistDashboardData(user.token, "all")
      .then((response) => {
        const rows = response.titleOrders.map((order) => convertTitleOrderToDashboardRow(order));
        setAllRows(rows);
      })
      .finally(() => setLoading(false));
  }, [user, dashboardUpdate, setLoading, setAllRows]);

  useEffect(() => {
    if (!allRows.length) {
      return;
    }
    const defaultFilteredRows = allRows.filter(
      (row) => !isInTransitStatus(row) && (showCompleted || !isCompleted(row))
    );
    if (searchTerm === "") {
      setDashboardRows(defaultFilteredRows);
      return;
    }

    const filteredRows = getFilteredRows(searchTerm, showCompleted, defaultFilteredRows);
    setDashboardRows(filteredRows);
  }, [searchTerm, allRows, setDashboardRows, showCompleted]);

  return (
    <TitleOrdersDashboard
      rows={dashboardRows}
      setRows={setDashboardRows}
      columns={getTitleSpecialistDashboardColumns}
      mode={"client"}
      useData={useTitleSpecialistDashboard}
    />
  );
}

export function getFilteredRows(searchTerm: string, showCompleted: boolean, rows: DashboardRow[]) {
  const loweredSearchTerm = searchTerm.toLowerCase();

  return rows.filter(
    (row) =>
      (
        (row.customer && row.customer.firstName?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.customer && row.customer.lastName?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.customer && row.customer.phone?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.lender && row.lender.name?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.lender && row.lender.phone?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.lienholder && row.lienholder.name?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.lienholder && row.lienholder.phone?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.owner && row.owner.firstName?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.owner && row.owner.lastName?.toLowerCase().includes(loweredSearchTerm)) ||
        (row.aeStatus && formatAeLeadStatus(row.aeStatus).toLowerCase().includes(loweredSearchTerm)) ||
        (row.dealType && formatAeDealType(row.dealType).toLowerCase().includes(loweredSearchTerm)) ||
        row.vin?.toLowerCase().includes(loweredSearchTerm) ||
        row.recordId?.toString().toLowerCase().includes(loweredSearchTerm)
      ) && (showCompleted || !isCompleted(row))
  );
}
