import { createContext, ReactNode, useContext, useState } from "react";
import { AssociateTitleSpecialistMyQueueDashboardContextType } from "./types";
import { useOperations } from "../hook/useOperations";
import { DashboardRow } from "../types";
import { GridRowId, GridRowParams } from "@mui/x-data-grid";
import {
  useAssociateTitleSpecialistMyQueueDashboardSort,
  useAssociateTitleSpecialistMyQueueDashboardFilter,
} from "../../../Hooks/GridCollation";

const AssociateTitleSpecialistMyQueueDashboardContext =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- TODO
  createContext<AssociateTitleSpecialistMyQueueDashboardContextType>(null!);

export function useAssociateTitleSpecialistMyQueueDashboard(): AssociateTitleSpecialistMyQueueDashboardContextType {
  return useContext(AssociateTitleSpecialistMyQueueDashboardContext);
}

export function AssociateTitleSpecialistMyQueueDashboardProvider({ children }: { children: ReactNode }) {
  const {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    setDashboardUpdate,
    searchTerm,
    loading,
    setLoading,
    dashboardUpdate,
  } = useOperations();
  const [filterModel, setFilterModel] = useAssociateTitleSpecialistMyQueueDashboardFilter();
  const [sortOptions, setSortOptions] = useAssociateTitleSpecialistMyQueueDashboardSort();

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [assignAtsLoading, setAssignAtsLoading] = useState<boolean>(false);
  const [showCompleted, setShowCompleted] = useState(false);

  const isRowSelectable = (params: GridRowParams<DashboardRow>) => !params.row.workedOnBy;

  const value = {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    searchTerm,
    setDashboardUpdate,
    filterModel,
    setFilterModel,
    sortOptions,
    setSortOptions,
    loading,
    setLoading,
    dashboardUpdate,
    selectedRows,
    setSelectedRows,
    assignAtsLoading,
    setAssignAtsLoading,
    isRowSelectable,
    showCompleted,
    setShowCompleted,
  };

  return (
    <AssociateTitleSpecialistMyQueueDashboardContext.Provider value={value}>
      {children}
    </AssociateTitleSpecialistMyQueueDashboardContext.Provider>
  );
}
