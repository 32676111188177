import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { estimatesStates } from "../../../../../../helpers/states";
import { booleanToYesNoString } from "../../autopopulateSubmitEstimate";

export function EstimateUnitedStatesSelectField(props: {
  label: string;
  groupName: string;
  value: string;
  onChange: ((event: SelectChangeEvent, child: React.ReactNode) => void) | undefined;
}) {
  const { label, groupName, value, onChange } = props;

  return (
    <FormItemWrapper label={label}>
      <FormControl>
        <InputLabel id="special-instructions-${label}">{label}</InputLabel>
        <Select
          labelId="special-instructions-${label}"
          label={label}
          aria-label={label}
          data-testid={`estimate-choice-field-${groupName}-${label}`}
          className="w-56"
          onChange={onChange}
          value={value}
        >
          {estimatesStates.map(({ state, name }) => (
            <MenuItem key={state} value={name} data-testid="select-option">
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormItemWrapper>
  );
}

export function EstimateDateField(props: {
  label: string;
  groupName: string;
  value: string;
  onChange: (value: string | null, keyboardInputValue?: string | undefined) => void;
}) {
  const { label, groupName, value, onChange } = props;
  const monthDayYearFormat = "MM/dd/yyyy";

  return (
    <FormItemWrapper label={label}>
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={label}
            aria-label={label}
            inputFormat={monthDayYearFormat}
            renderInput={(params) => (
              <TextField {...params} data-testid={`estimate-date-field-${groupName}-${label}`} />
            )}
            onChange={(value) => {
              if (!value) {
                onChange(value);
                return;
              }
              onChange(DateTime.fromJSDate(new Date(value), { zone: "UTC" }).startOf("day").toISO());
            }}
            value={value ?? ""}
          />
        </LocalizationProvider>
      </FormControl>
    </FormItemWrapper>
  );
}

export function EstimateTextField(props: {
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  groupName: string;
  value: string | undefined;
  multine?: boolean;
  rows?: string | number;
  fullWidth?: boolean;
}) {
  const { label, groupName, value, onChange, multine, rows, fullWidth } = props;

  return (
    <FormItemWrapper label={label}>
      <FormControl sx={{ width: fullWidth ? "100%" : "auto" }}>
        <TextField
          id={`text-field-test-id-${groupName}-${label}`}
          aria-label={label}
          label={label}
          variant="outlined"
          onChange={onChange}
          value={value ?? ""}
          inputProps={{
            "data-testid": `estimate-text-field-${groupName}-${label}`,
          }}
          multiline={multine}
          rows={rows}
        />
      </FormControl>
    </FormItemWrapper>
  );
}

export function EstimateNumberField(props: {
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  groupName: string;
  value: string | undefined;
}) {
  const { label, onChange, groupName, value } = props;

  return (
    <FormItemWrapper label={label}>
      <FormControl>
        <TextField
          id={`text-field-number-test-id-${groupName}-${label}`}
          aria-label={label}
          label={label}
          variant="outlined"
          onChange={onChange}
          value={value ?? ""}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            "data-testid": `text-field-number-test-id-${groupName}-${label}`,
          }}
          type="number"
        />
      </FormControl>
    </FormItemWrapper>
  );
}

export function EstimateRadioField(props: {
  label: string;
  groupName: string;
  value: boolean | undefined;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined;
}) {
  const { label, groupName, value, onChange } = props;

  return (
    <FormItemWrapper label={label}>
      <FormControl>
        <RadioGroup
          value={booleanToYesNoString(value)}
          aria-label={label}
          data-testid={`estimate-boolean-field-${groupName}-${label}`}
          onChange={onChange}
          defaultChecked={false}
        >
          <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
          <FormControlLabel value={"No"} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </FormItemWrapper>
  );
}

export function FormItemWrapper(props: { label: string; children: ReactNode }) {
  return (
    <div className="flex items-center mt-4">
      <span className="font-bold text-base text-aa-dark-grey w-56 flex-shrink-0">{props.label}</span>
      {props.children}
    </div>
  );
}
