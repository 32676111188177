import Page from "../../shared/Page/Page";
import { AccordionTable } from "../TitleSpecialistDashboard/AccordionTable";
import { useEffect, useMemo, useState } from "react";
import { filterByTotalDays } from "../helpers";
import { GridRowId } from "@mui/x-data-grid";
import { convertTitleOrderToDashboardRow, DashboardRow } from "../types";
import { useAuth } from "../../Auth/AuthProvider";
import { getAssociateTitleSpecialistMyQueueDashboardData } from "../../../networking/TitlesWorkflowAPI";
import { useSharedDashboard } from "../context/SharedDashboardContext";
import { DashboardHeader } from "../shared/DashboardHeader";
import { getCopy } from "../../../authoring/copy";
import { AssociateTitleSpecialistDrawer } from "../AssociateTitleSpecialistDrawer/AssociateTitleSpecialistDrawer";
import { useAssociateTitleSpecialistMyQueueDashboard } from "../context/AssociateTitleSpecialistMyQueueDashboardContext";
import { isUndefined } from "lodash";
import { getAtsMyQueueColumns, getAtsMyQueueColumnsForTopPriority } from "../TitleOrdersDashboard/columns";
import { getFilteredRows } from "../TitleSpecialistDashboard/TitleSpecialistDashboard";

export function AssociateTitleSpecialistMyQueue() {
  const {
    dashboardRows,
    showCompleted,
    allRows,
    setAllRows,
    setDashboardRows,
    setLoading,
    setSelectedRows,
    isRowSelectable,
  } = useAssociateTitleSpecialistMyQueueDashboard();
  const { dashboardUpdate, searchTerm } = useSharedDashboard();
  const { user } = useAuth();

  const rowsWithTopPriority = useMemo(
    () =>
      dashboardRows
        .filter((row) => !isUndefined(row.isTopPriority) && row.isTopPriority)
        .map((row) => ({
          ...row,
          etaSource: row.topPriorityType,
        })),
    [dashboardRows]
  );

  const nonPriorityRows = useMemo(() => dashboardRows.filter((row) => !row.isTopPriority), [dashboardRows]);

  const rowsLessThan60Days = useMemo(
    () => filterByTotalDays(nonPriorityRows, [Number.NEGATIVE_INFINITY, 59]),
    [nonPriorityRows]
  );
  const rowsBetween60And90Days = useMemo(() => filterByTotalDays(nonPriorityRows, [59, 90]), [nonPriorityRows]);
  const rowsOver90Days = useMemo(
    () => filterByTotalDays(nonPriorityRows, [90, Number.POSITIVE_INFINITY]),
    [nonPriorityRows]
  );

  const [selectedRow, setSelectedRow] = useState<DashboardRow | undefined>(undefined);

  const { accordion } = getCopy().associateTitleSpecialistMyQueue;
  const [expandedGroup, setExpandedGroup] = useState<string>(accordion.topPriorityHeaderText);

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    getAssociateTitleSpecialistMyQueueDashboardData(user.token)
      .then((response) => {
        const rows = response.titleOrders.map((order) => convertTitleOrderToDashboardRow(order));
        setAllRows(rows);
        setDashboardRows(rows);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, dashboardUpdate, setLoading, setAllRows, setDashboardRows]);

  useEffect(() => {
    if (!allRows.length) return;
    if (searchTerm === "") {
      setDashboardRows(allRows);
    }

    // TODO: adjust contract so that _NO_ values are optional; the back-end should null coalesce everything
    const filteredRows = getFilteredRows(searchTerm, showCompleted, allRows);

    setDashboardRows(filteredRows);
  }, [searchTerm, showCompleted, allRows, setDashboardRows]);

  const onSelectRow = (rowIds: GridRowId[]) => {
    setSelectedRows(rowIds);
  };

  const onCellClick = (row: DashboardRow) => {
    setSelectedRow(row);
  };

  return (
    <Page>
      <DashboardHeader showCompletedContext={useAssociateTitleSpecialistMyQueueDashboard} />
      <AccordionTable
        headerText={accordion.topPriorityHeaderText}
        expanded={accordion.topPriorityHeaderText === expandedGroup}
        setExpanded={setExpandedGroup}
        rows={rowsWithTopPriority}
        getColumns={getAtsMyQueueColumnsForTopPriority}
        useData={useAssociateTitleSpecialistMyQueueDashboard}
        checkboxSelection={true}
        onSelectionModelChange={onSelectRow}
        onCellClick={onCellClick}
        isRowSelectable={isRowSelectable}
      />
      <AccordionTable
        headerText={accordion.overNinetyDaysHeaderText}
        expanded={accordion.overNinetyDaysHeaderText === expandedGroup}
        setExpanded={setExpandedGroup}
        rows={rowsOver90Days}
        getColumns={getAtsMyQueueColumns}
        useData={useAssociateTitleSpecialistMyQueueDashboard}
        checkboxSelection={true}
        onSelectionModelChange={onSelectRow}
        onCellClick={onCellClick}
        isRowSelectable={isRowSelectable}
      />
      <AccordionTable
        headerText={accordion.sixtyToNinetyDaysHeaderText}
        expanded={accordion.sixtyToNinetyDaysHeaderText === expandedGroup}
        setExpanded={setExpandedGroup}
        rows={rowsBetween60And90Days}
        getColumns={getAtsMyQueueColumns}
        useData={useAssociateTitleSpecialistMyQueueDashboard}
        checkboxSelection={true}
        onSelectionModelChange={onSelectRow}
        onCellClick={onCellClick}
        isRowSelectable={isRowSelectable}
      />
      <AccordionTable
        headerText={accordion.underSixtyHeaderText}
        expanded={accordion.underSixtyHeaderText === expandedGroup}
        setExpanded={setExpandedGroup}
        rows={rowsLessThan60Days}
        getColumns={getAtsMyQueueColumns}
        useData={useAssociateTitleSpecialistMyQueueDashboard}
        checkboxSelection={true}
        onSelectionModelChange={onSelectRow}
        onCellClick={onCellClick}
        isRowSelectable={isRowSelectable}
      />
      <AssociateTitleSpecialistDrawer
        handleClose={() => setSelectedRow(undefined)}
        row={selectedRow}
        allRows={allRows}
        setAllRows={setAllRows}
      />
    </Page>
  );
}
