import { flow, toLower } from "lodash";
import { AeDealTypeEnum, StatusSeverity, TitleOrderFilter } from "../../../../networking/core/types";
import { getCopy } from "../../../../authoring/copy";
import { AeLeadStatus } from "@auto-approve/auto-approve-ui-library";

const { severities } = getCopy().titleOrdersDashboard;

const normalize = flow(toLower, (str: string) => str.replace(/\s/g, ""));

export function toAPIFilter(args: { column: string; filterValue: string }): Array<TitleOrderFilter> {
  if (args.filterValue.length === 0) {
    return [];
  }
  if (args.column === "aeStatus") {
    return Object.entries(AeLeadStatus)
      .filter(([key, _value]) => normalize(key).includes(normalize(args.filterValue)))
      .map(([_key, value]) => ({ column: args.column, value: String(value) }));
  }   if (args.column === "dealType") {
    return Object.entries(AeDealTypeEnum)
      .filter(([key, _value]) => normalize(key).includes(normalize(args.filterValue)))
      .map(([_key, value]) => ({ column: args.column, value: String(value) }));
  } else if (args.column === "aeStatusSeverity") {
    if (normalize(args.filterValue) === normalize(severities[StatusSeverity.Green])) {
      return [{ column: args.column, value: StatusSeverity.Green.valueOf().toString() }];
    } else if (normalize(args.filterValue) === normalize(severities[StatusSeverity.Yellow])) {
      return [{ column: args.column, value: StatusSeverity.Yellow.valueOf().toString() }];
    } else if (normalize(args.filterValue) === normalize(severities[StatusSeverity.Red])) {
      return [{ column: args.column, value: StatusSeverity.Red.valueOf().toString() }];
    }
  }
  return [{ column: args.column, value: args.filterValue }];
}
