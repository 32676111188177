import { AccordionTable } from "../TitleSpecialistDashboard/AccordionTable";
import { getCopy } from "../../../authoring/copy";
import { AdditionalDashboardColumn, DashboardRow } from "../types";
import React, { useCallback, useMemo, useState } from "react";
import { DashboardContextType } from "../context/types";
import { DashboardTable } from "../DashboardTable";
import { GridColumns } from "@mui/x-data-grid";
import { filterByFollowUpDate, filterByTotalDays } from "../helpers";
import Page from "../../shared/Page/Page";
import { DashboardHeader } from "../shared/DashboardHeader";
import { TitleSpecialistDetailsDrawer } from "../TitleSpecialistDashboard/Drawer/TitleSpecialistDetailsDrawer";
import { useTitleSpecialistDashboardSort, useTopPrioritySort } from "../../../Hooks/GridCollation";

const { accordion } = getCopy().titleOrdersDashboard;

export function TitleOrdersDashboard(props: {
  rows: DashboardRow[];
  setRows: React.Dispatch<React.SetStateAction<DashboardRow[]>>;
  columns: () => GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | AdditionalDashboardColumn}[];
  mode: "client" | "server";
  useData: () => DashboardContextType;
  totalRowsFromServer?: number;
  totalFilteredRowsFromServer?: number;
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  setSkipRows?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { loading } = props.useData();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<DashboardRow | undefined>(undefined);
  const [grouped, setGrouped] = useState(false);

  const [expandedGroup, setExpandedGroup] = useState<string>(accordion.topPriority);
  const rowsTopPriority = useMemo(() => filterByFollowUpDate(props.rows), [props.rows]);
  const rowsLessThan60Days = useMemo(
    () => filterByTotalDays(props.rows, [Number.NEGATIVE_INFINITY, 59], true),
    [props.rows]
  );
  const rowsBetween60And90Days = useMemo(() => filterByTotalDays(props.rows, [59, 90], true), [props.rows]);
  const rowsOver90Days = useMemo(
    () => filterByTotalDays(props.rows, [90, Number.POSITIVE_INFINITY], true),
    [props.rows]
  );

  const [topPrioritySort, setTopPrioritySort] = useTopPrioritySort();
  const [over90DaysSort, setOver90DaysSort] = useTitleSpecialistDashboardSort();
  const [between60And90DaysSort, SetBetween60And90DaysSort] = useTitleSpecialistDashboardSort();
  const [lessThan60DaysSort, setLessThan60DaysSort] = useTitleSpecialistDashboardSort();

  if (accordion.topPriority === expandedGroup && !loading && rowsTopPriority.length === 0) {
    if (rowsOver90Days.length > 0) setExpandedGroup(accordion.overNinetyDaysHeaderText);
    else if (rowsBetween60And90Days.length > 0) setExpandedGroup(accordion.sixtyToNinetyDaysHeaderText);
    else if (rowsLessThan60Days.length > 0) setExpandedGroup(accordion.underSixtyHeaderText);
    else setExpandedGroup("");
  }

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  function onCellClick(row: DashboardRow) {
    setDrawerOpen(true);
    setSelectedRow(row);
  }

  function UnGroupedRows() {
    return (
      <div className="pt4">
        <DashboardTable
          getColumns={props.columns}
          hasRowRefreshButtons={false}
          defaultPageSize={15}
          rowsPerPageOptions={[10, 15, 20]}
          rows={props.rows}
          totalRows={props.totalRowsFromServer ?? props.rows.length}
          filterMode={props.mode}
          sortingMode={props.mode}
          paginationMode={props.mode}
          rowsPerPage={props.rowsPerPage}
          setRowsPerPage={props.setRowsPerPage}
          setSkipRows={props.setSkipRows}
          useData={props.useData}
          onCellClick={onCellClick}
        />
      </div>
    );
  }

  function RowsGroupedByTotalDays() {
    return (
      <>
        <AccordionTable
          headerText={accordion.topPriority}
          expanded={accordion.topPriority === expandedGroup}
          setExpanded={setExpandedGroup}
          rows={rowsTopPriority}
          getColumns={props.columns}
          hasRowRefreshButtons={false}
          onCellClick={onCellClick}
          filterMode={props.mode}
          sortingMode={props.mode}
          paginationMode={props.mode}
          useData={() => ({
            ...props.useData(),
            sortOptions: topPrioritySort,
            setSortOptions: setTopPrioritySort,
          })}
          setRowsPerPage={props.setRowsPerPage}
          setSkipRows={props.setSkipRows}
        />
        <AccordionTable
          headerText={accordion.overNinetyDaysHeaderText}
          expanded={accordion.overNinetyDaysHeaderText === expandedGroup}
          setExpanded={setExpandedGroup}
          rows={rowsOver90Days}
          getColumns={props.columns}
          hasRowRefreshButtons={false}
          onCellClick={onCellClick}
          filterMode={props.mode}
          sortingMode={props.mode}
          paginationMode={props.mode}
          useData={() => ({
            ...props.useData(),
            sortOptions: over90DaysSort,
            setSortOptions: setOver90DaysSort,
          })}
          setRowsPerPage={props.setRowsPerPage}
          setSkipRows={props.setSkipRows}
        />
        <AccordionTable
          headerText={accordion.sixtyToNinetyDaysHeaderText}
          expanded={accordion.sixtyToNinetyDaysHeaderText === expandedGroup}
          setExpanded={setExpandedGroup}
          rows={rowsBetween60And90Days}
          getColumns={props.columns}
          hasRowRefreshButtons={false}
          onCellClick={onCellClick}
          filterMode={props.mode}
          sortingMode={props.mode}
          paginationMode={props.mode}
          useData={() => ({
            ...props.useData(),
            sortOptions: between60And90DaysSort,
            setSortOptions: SetBetween60And90DaysSort,
          })}
          setRowsPerPage={props.setRowsPerPage}
          setSkipRows={props.setSkipRows}
        />
        <AccordionTable
          headerText={accordion.underSixtyHeaderText}
          expanded={accordion.underSixtyHeaderText === expandedGroup}
          setExpanded={setExpandedGroup}
          rows={rowsLessThan60Days}
          getColumns={props.columns}
          hasRowRefreshButtons={false}
          onCellClick={onCellClick}
          filterMode={props.mode}
          sortingMode={props.mode}
          paginationMode={props.mode}
          useData={() => ({
            ...props.useData(),
            sortOptions: lessThan60DaysSort,
            setSortOptions: setLessThan60DaysSort,
          })}
          setRowsPerPage={props.setRowsPerPage}
          setSkipRows={props.setSkipRows}
        />
      </>
    );
  }

  return (
    <Page>
      <DashboardHeader
        showCompletedContext={props.useData}
        onGroupToggle={setGrouped}
        grouped={props.mode === "client" ? grouped : undefined} />
      <div className="pt-4">
        {/*Grouping for manager (props.mode === "server) will be handled in TE-1027.*/}
        {grouped && props.mode === "client" ? RowsGroupedByTotalDays() : UnGroupedRows()}
      </div>

      <TitleSpecialistDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        row={selectedRow}
        allRows={props.rows}
        setAllRows={props.setRows}
      />
    </Page>
  );
}
