import { Box, Button, IconButton, MenuItem, Modal, Select, TextField, Typography, InputLabel, FormControl, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardTitleOrder } from "../../../networking/core/types";
import { Dispatch, SetStateAction, useState } from "react";
import { getUsersByRole, requestEscalation } from "../../../networking/TitlesWorkflowAPI";
import { useAuth } from "../../Auth/AuthProvider";
import { isEmpty } from "lodash";
import { getCopy } from "../../../authoring/copy";
import { DashboardRow } from "../types";
import { useQuery } from "@tanstack/react-query";
import { AeRole } from "@auto-approve/auto-approve-ui-library";

type RequestEscalationModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  row: DashboardTitleOrder;
  allRows: DashboardRow[];
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
  closeDrawer: () => void;
};

const { requestEscalation: authoring } = getCopy().escalationsDashboard;

export function RequestEscalationModal(props: RequestEscalationModalProps) {
  const { user } = useAuth();
  const { data, isLoading } = useQuery(["managers", AeRole.TE_MANAGER], () => getUsersByRole(AeRole.TE_MANAGER, user?.token ?? ""));
  const [manager, setManager] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const removeTitleOrderAfterEscalation = (titleOrderRow: DashboardTitleOrder) => {
    const newRows = props.allRows.filter((row) => row.aeLeadId !== titleOrderRow.aeLeadId);
    props.setAllRows(newRows);
  };

  const handleClose = () => { 
    props.setOpen(false);
    setDescription("");
    setManager("");
  };

  async function handleEscalation() {
    if (!user?.token || isFormInvalid()) return;
    setIsSaving(true);
    await requestEscalation(user.token, manager, description, props.row.aeLeadId)
      .then(() => {
        removeTitleOrderAfterEscalation(props.row);
        handleClose();
        props.closeDrawer();
      })
      .catch((err) => {
        alert(`Failed to request the escalation. Error: ${err}`);
      })
      .finally(() => setIsSaving(false));
  }

  const styles = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    select: {
      margin: "10px 10px 10px 0",
      width: "40%",
    },
    textField: {
      width: "100%",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  };

  function isFormInvalid() {
    return description.trim().length === 0 || !manager;
  }

  return (
    <Modal open={props.open} onClose={handleClose} role="dialog">
      <Box sx={styles.box}>
        <div style={styles.header}>
        <Typography id="escalate-order-heading" variant="h6" component="h6">
          {authoring.modal.heading}
          {props.row?.recordId}
        </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <FormControl style={styles.select}>
          <InputLabel id="escalate-select-label">{authoring.modal.managerSelect.label}</InputLabel>
          <Select
            labelId="escalate-select-label"
            id="escalate-select"
            value={manager}
            label={authoring.modal.managerSelect.label}
            disabled={isLoading || isSaving}
            onChange={(e) => setManager(e.target.value)}
          >
            {(data ?? [])
              .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
              .map((user, i) => (
                <MenuItem key={i} value={user.aeUserId}>{user.firstName} {user.lastName}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          placeholder={authoring.modal.descriptionInput.placeHolder}
          style={styles.textField}
          multiline
          rows={4}
          value={description}
          disabled={isSaving}
          onChange={(e) => {
            const errorMsg = descriptionValidation(e.target.value);
            setErrorMessage(errorMsg);
            setDescription(e.target.value);
          }}
        />
        {!isEmpty(errorMessage) && <span className="text-red-500">{errorMessage}</span>}
        <div className="float-right mt-4">
          <Button color="secondary" onClick={handleClose} disabled={isSaving}>
            {authoring.modal.cancel.buttonText}
          </Button>
          <Button
            onClick={handleEscalation}
            disabled={isSaving || isFormInvalid()}
          >
            {isSaving ?
              <><CircularProgress className="mr-4" size={14} thickness={4} /><p>Saving...</p></>
              : authoring.modal.requestEscalation.buttonText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

function belowMinLength(input: string): string {
  return isEmpty(input) ? authoring.modal.errorMessages.emptyDescription : "";
}

function descriptionValidation(input: string): string {
  return [belowMinLength].reduce<string>((accumulator: string, currentFunc) => {
    return isEmpty(accumulator) ? currentFunc(input) : accumulator;
  }, "");
}
