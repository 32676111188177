import { Box } from "@mui/material";
import { useState } from "react";
import { EstimateTextField } from "./EstimateFields";
import { SpecialInstructions } from "../../types";
import { getCopy } from "../../../../../../authoring/copy";
import { CreateEstimateTitleOrder } from "../../../../../../networking/core/types";

const submitEstimateCopy = getCopy().createEstimate.submitEstimate;
const specialInstructionsCopy = submitEstimateCopy.specialInstructions;

type DefaultSpecialInstructionsFormData = {
  specialInstructions: string;
};

export function DefaultSpecialInstructionsForm(props: {
  specialInstructions: SpecialInstructions;
  titleOrder: CreateEstimateTitleOrder | undefined;
}) {
  const [form, setForm] = useState<DefaultSpecialInstructionsFormData>({
    specialInstructions: "",
  });

  return (
    <Box boxShadow={2} sx={{ width: 878, margin: "auto", borderRadius: 5, mb: 3 }}>
      <div className="px-14 py-11">
        <p className="font-weight-500 text-2xl pb-14" style={{ color: "#200A52" }}>
          {specialInstructionsCopy.groupName}
        </p>

        <EstimateTextField
          label={specialInstructionsCopy.additionalSpecialInstructions}
          groupName={specialInstructionsCopy.groupName}
          onChange={(event) => {
            const updatedForm = {
              ...form,
              specialInstructions: event.target.value,
            };
            props.specialInstructions.fields.set(
              specialInstructionsCopy.additionalSpecialInstructions,
              event.target.value
            );
            props.specialInstructions.text = updatedForm.specialInstructions;
            setForm(updatedForm);
          }}
          value={form.specialInstructions}
          multine
          rows={10}
          fullWidth
        />
      </div>
    </Box>
  );
}
