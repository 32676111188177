import { DashboardRow } from "../types";
import { BaseDrawer } from "../shared/BaseDrawer";
import { Dispatch, SetStateAction } from "react";

type AssociateTitleSpecialistDrawerProps = {
  handleClose: () => void;
  row: DashboardRow | undefined;
  allRows: DashboardRow[];
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
};

export function AssociateTitleSpecialistDrawer(props: AssociateTitleSpecialistDrawerProps) {
  return (
    <BaseDrawer
      row={props.row}
      allRows={props.allRows}
      setAllRows={props.setAllRows}
      isOpen={props.row !== undefined}
      handleClose={props.handleClose}
      showEscalateButton={false}
      showUpdateButton={false}
    />
  );
}
