import { CustomButton } from "../../../Dashboards/shared/Button";
import { BorrowerRelationship, CreateEstimateTitleOrder } from "../../../../networking/core/types";
import { EstimateTemplateField, UIEstimateTemplate } from "../../../../contexts/estimates/core/types";
import { FormEvent, useRef, useState } from "react";
import { useRequiredAuthUser } from "../../../Auth/AuthProvider";
import { autoPopulateFields } from "./autopopulateSubmitEstimate";
import { getEmptySpecialInstructions, SpecialInstructions, TitleOrderField, UiEstimateTemplateField } from "./types";
import { DynamicFields } from "./subComponents/DyanmicFields";
import { WklsEstimateTransactionDisplayType } from "../../../types";
import { SpecialInstructionsSection } from "./subComponents/SpecialInstructions/SpecialInstructions";
import { submitEstimate } from "../../../../networking/estimates/TitlesWorkflowEstimatesApi";
import { CreateEstimateStep } from "../../types";
import { setValidOrInvalid, validateAllFields } from "./validateFields";
import { excludedFieldGroupToGroupNames } from "../../../../networking/core/converters";
import { BaseModal } from "../../../shared/BaseModal/BaseModal";
import { getCopy } from "../../../../authoring/copy";
import { Box } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

type SubmitForEstimateProps = {
  titleOrder: CreateEstimateTitleOrder | undefined;
  estimateTemplate: UIEstimateTemplate | null;
  transactionType: WklsEstimateTransactionDisplayType | undefined;
  setCreateEstimateStep: (value: number) => void;
};

export function SubmitForEstimate(props: SubmitForEstimateProps) {
  const user = useRequiredAuthUser();
  const { titleOrderFieldsPopulated, unknownFieldsPopulated } = autoPopulateFields(
    [...(props.estimateTemplate?.fields ?? []), getBorrowerRelationshipField()],
    props.titleOrder,
    user.role
  );
  const [loading, setLoading] = useState(false);
  const [excludedFieldGroup, setExcludedFieldGroup] = useState({ secondaryBorrower: false });
  const [openValidationModal, setOpenValidationModal] = useState<boolean>(false);
  const knownFields = useRef<TitleOrderField[]>(titleOrderFieldsPopulated);
  const unknownFields = useRef<UiEstimateTemplateField[]>(unknownFieldsPopulated);
  const specialInstructions = useRef<SpecialInstructions>(getEmptySpecialInstructions());
  const copy = getCopy().createEstimate.submitEstimate;
  const flags = useFlags();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!props.titleOrder || !props.estimateTemplate) {
      return;
    }

    setValidOrInvalid(props.titleOrder, titleOrderFieldsPopulated, unknownFields.current);
    if (
      !validateAllFields(
        titleOrderFieldsPopulated,
        unknownFields.current,
        excludedFieldGroupToGroupNames(excludedFieldGroup),
        props.titleOrder,
        flags.typeOfPurchaseWklsTemplateField
      )
    ) {
      setOpenValidationModal(true);
      return;
    }

    setLoading(true);
    await submitEstimate(
      user.token,
      props.titleOrder,
      unknownFields.current,
      specialInstructions.current,
      props.estimateTemplate.estimateId,
      excludedFieldGroup,
    )
      .then(() => {
        props.setCreateEstimateStep(CreateEstimateStep.NextSteps);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mt-10 space-y-6">
      <form onSubmit={(event: FormEvent) => handleSubmit(event)}>
        {props.estimateTemplate && (
          <>
            <DynamicFields
              knownFields={knownFields.current}
              unknownFields={unknownFields.current}
              titleOrder={props.titleOrder}
              setExcludedFieldGroup={setExcludedFieldGroup}
            />
            <SpecialInstructionsSection
              transactionType={props.transactionType}
              specialInstructions={specialInstructions.current}
              titleOrder={props.titleOrder}
            />
          </>
        )}
        <Box sx={{ width: 878, margin: "auto", borderRadius: 5, mb: 3 }}>
          <hr className="mb-8 mt-8"/>
          <div className="pb-8 text-right flex justify-between">
            <CustomButton variant="text" sx={{ mr: "12px", fontWeight: "400" }} onClick={() => props.setCreateEstimateStep(CreateEstimateStep.Confirm)}>
              Go Back
            </CustomButton>
            <CustomButton
              sx={{ fontWeight: "400" }}
              type={"submit"}
              variant={"outlined"}
              data-testid="submit-for-estimate"
              loading={loading}
              disabled={loading}
            >
              Submit
            </CustomButton>
          </div>
        </Box>
      </form>
      <BaseModal
        isOpen={openValidationModal}
        onClose={() => {
          setOpenValidationModal(false);
        }}
        actions={[
          <CustomButton
            key={"Submit Estimate Validation Error Dialog Button"}
            variant="outlined"
            sx={{ fontWeight: "400" }}
            onClick={() => {
              setOpenValidationModal(false);
            }}
          >
            {copy.validation.validationErrorButtonText}
          </CustomButton>,
        ]}
        title={copy.validation.validationErrorDialogTitle}
        description={copy.validation.validationErrorDialogText}
      />
    </div>
  );
}

const getBorrowerRelationshipField = (): EstimateTemplateField => {
  const borrower = getCopy().createEstimate.submitEstimate.borrower;
  return {
    group: borrower.groupName,
    teGroup: borrower.secondaryGroupName,
    name: borrower.relationshipToBorrowerLabel,
    required: true,
    fieldInfo: {
      type: "choice",
      options: Object.values(BorrowerRelationship),
    },
  };
};
