import { Button, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import { getCopy } from "../../../../authoring/copy";
import { TitleOrderTask } from "../../../../networking/core/types";
import { useAuth } from "../../../Auth/AuthProvider";
import { BaseModal } from "../../../shared/BaseModal/BaseModal";

const { undoTaskModal } = getCopy().associateTitleSpecialistDashboard.taskPanel;

export function UndoTaskModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (changeReason: string) => Promise<void>;
  task: TitleOrderTask;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [changeReason, setChangeReason] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { user } = useAuth();

  return (
    <BaseModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`${undoTaskModal.headingPrefix} - ${props.task.label}?`}
      actions={[
        <Button
          key={undoTaskModal.confirmButton.label}
          variant="text"
          onClick={handleSubmit}
          style={{ color: "#5132A2" }}
          disabled={isSubmitDisabled()}
        >
          {undoTaskModal.confirmButton.label}
        </Button>,
      ]}
    >
      <TextField
        sx={{ width: "100%" }}
        placeholder={undoTaskModal.changeReasonTextField.placeHolder}
        multiline
        rows={4}
        value={changeReason}
        onChange={(e) => {
          const errorMsg = changeReasonValidation(e.target.value);
          setErrorMessage(errorMsg);
          setChangeReason(e.target.value);
        }}
      />
      {!isEmpty(errorMessage) && <span className="text-red-500">{errorMessage}</span>}
    </BaseModal>
  );

  async function handleSubmit() {
    const errorMsg = changeReasonValidation(changeReason);
    setErrorMessage(errorMsg);

    if (user?.token && isEmpty(errorMsg)) {
      setLoading(true);
      props
        .onSubmit(changeReason)
        .then(() => {
          props.onClose();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          alert(`Failed to undo the task. Error: ${err}`);
        });
    }
  }

  function isSubmitDisabled() {
    return loading || !isEmpty(errorMessage);
  }
}

function changeReasonValidation(input: string): string {
  return [belowMinLength].reduce<string>((accumulator: string, currentFunc) => {
    return isEmpty(accumulator) ? currentFunc(input) : accumulator;
  }, "");
}

function belowMinLength(input: string): string {
  return isEmpty(input.trim()) ? undoTaskModal.errorMessages.emptyChangeReason : "";
}
