import { AddressModel, CustomerModel, LeadModel } from "@auto-approve/auto-approve-ui-library";
import { formatAeDealType } from "../../components/Dashboards/shared/FieldFormats";
import { TitlesEngineAddress, TitlesEngineCustomer, TitlesEngineTitleOrderWithAssociations } from "../../networking/title_orders/types";

const toAddressModel = (address: TitlesEngineAddress): AddressModel => ({
  ...address
});

const toCustomerModel = (customer: TitlesEngineCustomer): CustomerModel => ({
  ae1Id: "", // TODO - add ID if exists
  firstName: customer.firstName,
  lastName: customer.lastName,
  address: customer.address ? toAddressModel(customer.address) : undefined
});

export const toLeadModel = (data: TitlesEngineTitleOrderWithAssociations): LeadModel => ({
  ae1Id: data.aeLeadId,
  teId: data.id,
  deal: data.aeDealId ? {
    ae1Id: data.aeDealId,
  } : undefined,
  recordId: data.aeRecordId,
  dealType: formatAeDealType(data.dealType),
  status: data.newAeStatus,
  applicationId: data?.applicationId ?? undefined,
  lender: data.lender ? {
    ae1Id: data.lender.aeLenderId,
    teId: data.lender.id,
    name: data.lender.name,
    phone: data.lender.phone ?? undefined,
    address: data.lender.address ? toAddressModel(data.lender.address) : undefined
  } : undefined,
  primaryBorrower: data.primaryCustomer ? toCustomerModel(data.primaryCustomer) : undefined,
  coBorrower: data.secondaryCustomer ? toCustomerModel(data.secondaryCustomer) : undefined
});
