import { ContractRequest, ContractStatusRequest, ContractStatusResponse, ExecutionResultModel, SendDocumentsToLenderRequest } from "@auto-approve/auto-approve-ui-library";
import axios, { AxiosRequestConfig } from "axios";
import { environmentVariables } from "../../config";

export const contract = async (
    request: ContractRequest,
    signal: AbortSignal,
    token: string
): Promise<ExecutionResultModel<undefined>> => {
    const url = `${environmentVariables.titlesWorkflowApi}/loan-app/contract`;
    const config: AxiosRequestConfig = {
        signal,
        headers: {
            Authorization: token,
        }
    };

    const result = await axios.post(url, request, config)
        .then(response => {
            // TODO - remove the 200 or 204
            if (response.status === 200 || response.status === 204) {
                // no response data since it should be 204
                return { error: false, message: "Began contracting with lender" };
            }
        })
        .catch(error => {
            return {
                error: true,
                message: error?.response?.data ?? "Unable to contract with lender"
            };
        });

    return result ?? {
        error: true,
        message: "Unable to contract with lender"
    };
};

export const sendDocuments = async (
    request: SendDocumentsToLenderRequest,
    signal: AbortSignal,
    token: string
): Promise<ExecutionResultModel<undefined>> => {
    const url = `${environmentVariables.titlesWorkflowApi}/loan-app/upload-docs`;
    const config: AxiosRequestConfig = {
        signal,
        headers: {
            Authorization: token,
        }
    };

    const result = await axios.post(url, request, config)
        .then(response => {
            // TODO - remove the 200 or 204
            if (response.status === 200 || response.status === 204) {
                // no response data since it should be 204
                return { error: false, message: "Started uploading documents to lender" };
            }
        })
        .catch(error => {
            return {
                error: true,
                message: error?.response?.data ?? "Unable to upload documents to lender"
            };
        });

    return result ?? {
        error: true,
        message: "Unable to upload documents to lender"
    };
};

export const getContractStatus = async (
    request: ContractStatusRequest,
    signal: AbortSignal,
    token: string
): Promise<ExecutionResultModel<ContractStatusResponse>> => {
    const url = `${environmentVariables.titlesWorkflowApi}/loan-app/contract-status`;
    const config: AxiosRequestConfig = {
        signal,
        headers: {
            Authorization: token,
        }
    };

    const result = await axios.post(url, request, config)
        .then(response => {
            // TODO - remove the 200 or 204
            if (response.status === 200 || response.status === 204) {
                // no response data since it should be 204
                return {
                    response: response.data,
                    error: false,
                    message: "Successfully fetched contract status"
                };
            }
        })
        // TODO: Update backend error handling so we don't have to hardcode user frinedly error below.
        .catch(() => {
            return {
                error: true,
                message: "Contract status is not ready, please make sure the application has been approved before contracting or sending documents."
            };
        });

    return result ?? {
        error: true,
        message: "Unable to retrieve contract status"
    };
};
