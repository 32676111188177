import { DashboardHeader } from "../shared/DashboardHeader";
import { useEffect, useState } from "react";
import { convertTitleOrderToDashboardRow, DashboardRow } from "../types";
import Page from "../../shared/Page/Page";
import { useAuth } from "../../Auth/AuthProvider";
import { getTitleSpecialistDashboardData } from "../../../networking/TitlesWorkflowAPI";
import { useTitleSpecialistDashboard } from "../context/TitleSpecialistDashboardContext";
import { useSharedDashboard } from "../context/SharedDashboardContext";
import { DashboardTable } from "../DashboardTable";
import { TitleSpecialistDetailsDrawer } from "./Drawer/TitleSpecialistDetailsDrawer";
import { getTitleSpecialistDashboardColumns } from "../TitleOrdersDashboard/columns";
import { isInTransitStatus } from "./core/types";
import { getFilteredRows } from "./TitleSpecialistDashboard";

export function TitleSpecialistInTransitDashboard() {
  const {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    setLoading,
    showCompleted,
  } = useTitleSpecialistDashboard();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<DashboardRow | undefined>(undefined);
  const { dashboardUpdate, searchTerm } = useSharedDashboard();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    getTitleSpecialistDashboardData(user.token, "all")
      .then((response) => {
        const rows = response.titleOrders.map((order) => convertTitleOrderToDashboardRow(order));
        setAllRows(rows);
        setDashboardRows(rows.filter((row) => isInTransitStatus(row)));
      })
      .finally(() => setLoading(false));
  }, [user, dashboardUpdate, setLoading, setAllRows, setDashboardRows]);

  useEffect(() => {
    if (!allRows.length) return;
    if (searchTerm === "") {
      setDashboardRows(allRows.filter((row) => isInTransitStatus(row)));
      return;
    }

    const filteredRows = getFilteredRows(
      searchTerm,
      showCompleted,
      allRows.filter((row) => isInTransitStatus(row))
    );

    setDashboardRows(filteredRows);
  }, [searchTerm, allRows, setDashboardRows, showCompleted]);

  const onCellClick = (row: DashboardRow) => {
    setDrawerOpen(true);
    setSelectedRow(row);
  };

  return (
    <Page>
      <DashboardHeader />
      <div className="pt-4">
        <DashboardTable
          getColumns={getTitleSpecialistDashboardColumns}
          hasRowRefreshButtons={false}
          defaultPageSize={15}
          rowsPerPageOptions={[10, 15, 20]}
          rows={dashboardRows}
          totalRows={dashboardRows.length}
          filterMode="client"
          sortingMode="client"
          paginationMode="client"
          rowsPerPage={20}
          useData={useTitleSpecialistDashboard}
          onCellClick={onCellClick}
        />
      </div>

      <TitleSpecialistDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        row={selectedRow}
        allRows={allRows}
        setAllRows={setAllRows}
      />
    </Page>
  );
}

