import { Static, Type } from "@sinclair/typebox";
import { ajv } from "../../../../networking/core/types";
import { TitleEngineStatus } from "../../../types";
import { DashboardRow } from "../../types";
import { AeLeadStatus } from "@auto-approve/auto-approve-ui-library";

export const greenAEStatuses = [
  AeLeadStatus.Funded,
  AeLeadStatus.LoanAccepted,
  AeLeadStatus.Mailer,
  AeLeadStatus.Approved,
  AeLeadStatus.DocsCreationInProgress,
  AeLeadStatus.DocsOut,
  AeLeadStatus.DocsOutCondition,
  AeLeadStatus.DocsTracking,
  AeLeadStatus.InHouse,
  AeLeadStatus.AtBank,
  AeLeadStatus.FutureDeal,
  AeLeadStatus.RequestedOptOut,
  AeLeadStatus.CompetitorDeal,
  AeLeadStatus.PoasReceived,
  AeLeadStatus.StateSpecificDocsReceived,
  AeLeadStatus.LrTitleReceived,
  AeLeadStatus.PacketToTitleVendor,
  AeLeadStatus.EtaFromPAR,
  AeLeadStatus.CompleteWithTitleVendorSentNewTitleToBank,
  AeLeadStatus.InHousePendingPoas,
  AeLeadStatus.DocsOutDeadDeal,
  AeLeadStatus.LienReleaseReceived,
  AeLeadStatus.TitleReceived,
  AeLeadStatus.InHouseDocsSigned,
  AeLeadStatus.ApplicationComplete,
  AeLeadStatus.InHousePending,
  AeLeadStatus.TitleCheckComplete,
  AeLeadStatus.ReadyForDocs,
  AeLeadStatus.AgedHotLead,
  AeLeadStatus.DocsOutLSS,
  AeLeadStatus.ActiveLeadInProgress,
  AeLeadStatus.HotLeadMSGReceived,
  AeLeadStatus.WarmLead,
];

export const orangeAEStatuses = [
  AeLeadStatus.NewLead,
  AeLeadStatus.WorkingLead,
  AeLeadStatus.HotLead,
  AeLeadStatus.ApplicationSubmitted,
  AeLeadStatus.RequestedDocs,
  AeLeadStatus.WorkingLeadNoContacted,
  AeLeadStatus.NotYetContacted,
  AeLeadStatus.NewLeadNoContacted,
  AeLeadStatus.DeadDeal,
  AeLeadStatus.ReWorkingLead,
  AeLeadStatus.PayoffCheckSent,
  AeLeadStatus.DealertrackPayoff,
  AeLeadStatus.PaperCheckPayoff,
  AeLeadStatus.PendingTitlePacket,
  AeLeadStatus.DupTitleRequested,
  AeLeadStatus.ProblemResolvedSentToTitleVendor,
];

export const redAEStatuses = [
  AeLeadStatus.TitleProblemInHouse,
  AeLeadStatus.RequestedDocsDelay,
  AeLeadStatus.InHouseDelay,
  AeLeadStatus.DuplicateLead,
  AeLeadStatus.Compliant,
  AeLeadStatus.DidNotReach,
  AeLeadStatus.DeclinedByLender,
  AeLeadStatus.DeclinedByAutoApprove,
  AeLeadStatus.MissingDownPayment,
  AeLeadStatus.MissingPoa,
  AeLeadStatus.WorkingLeadBadNumber,
  AeLeadStatus.CustomerDeclinedOffer,
  AeLeadStatus.ManagementEscalation,
  AeLeadStatus.TitleCheckNeeded,
  AeLeadStatus.FundDelay,
  AeLeadStatus.RepurchasedLoans,
];

export const greenTEStatuses = [
  TitleEngineStatus.EstimateCreated,
  TitleEngineStatus.OrderCreated,
  TitleEngineStatus.PacketBuilding,
  TitleEngineStatus.SubmittedToVendor,
  TitleEngineStatus.SentToJurisdiction,
  TitleEngineStatus.ResubmittedToVendor,
  TitleEngineStatus.Perfected,
];

export const orangeTEStatuses = [TitleEngineStatus.ReadyForReview, TitleEngineStatus.ReceivedByVendor];

export const redTEStatuses = [
  TitleEngineStatus.VendorHold,
  TitleEngineStatus.DMVOnHold,
  TitleEngineStatus.DeadEstimate,
];

const WorkQueueRowSchema = Type.Object({
  leadId: Type.String(),
  recordId: Type.Optional(Type.Number()),
  lastName: Type.String(),
  firstName: Type.String(),
  vin: Type.Optional(Type.String()),
  lienholder: Type.Optional(Type.String()),
  fundedDate: Type.Optional(Type.String()),
  transactionType: Type.Optional(Type.String()),
  state: Type.Optional(Type.String()),
  teStatus: Type.Optional(Type.Enum(TitleEngineStatus)),
  aeStatus: Type.Enum(AeLeadStatus),
});

const WorkQueueResponseSchema = Type.Array(WorkQueueRowSchema);
type WorkQueueResponse = Static<typeof WorkQueueResponseSchema>;
export type WorkQueueRow = Static<typeof WorkQueueRowSchema>;

export function isWorkQueueResponse(response: unknown): response is WorkQueueResponse {
  const validate = ajv.compile(WorkQueueResponseSchema);

  const isValid = validate(response);

  if (!isValid) {
    console.error(JSON.stringify(response));

    validate.errors?.forEach((error) => console.error(`${error.instancePath} ${error.message}`));
  }

  return isValid;
}

export function isWorkQueueRow(response: unknown): response is WorkQueueRow {
  return ajv.validate(WorkQueueRowSchema, response);
}

export const inTransitStatuses = [
  AeLeadStatus.PacketToTitleVendor,
  AeLeadStatus.ProblemResolvedSentToTitleVendor,
];

export const transitBlockedStatuses = [AeLeadStatus.PendingTitlePacket, AeLeadStatus.TitleProblemInHouse];

export function isInTransitStatus(row: DashboardRow) {
  return inTransitStatuses.includes(row.aeStatus);
}

export function isCompleted(row: DashboardRow) {
  return row.aeStatus === AeLeadStatus.CompleteWithTitleVendorSentNewTitleToBank;
}
