import { Box, CircularProgress } from "@mui/material";
import { isUndefined } from "lodash";
import { getCopy } from "../../../../authoring/copy";
import { CustomButton } from "../Button";
import { DashboardRow } from "../../types";
import { useAuth } from "../../../Auth/AuthProvider";
import { ROUTES } from "../../../../App";
import { useNavigate } from "react-router-dom";
import { getTitleOrderById } from "../../../../networking/TitlesWorkflowAPI";
import { formatAeDealType, formatAeTitleVendorType, formatDate } from "../FieldFormats";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { PanelSectionTitle } from "../PanelSectionTitle";
import { canCreateEstimate } from "../../../Auth/Auth";
import { Add } from "@mui/icons-material";
import { DetailsPanelSection, DetailsPanelSectionRow } from "./DetailsPanelSection";
import { TitlesEngineAddress, TitlesEngineCustomer } from "../../../../networking/title_orders/types";
import { useQuery } from "@tanstack/react-query";
import { formatDateWithoutTimestamp } from "../../helpers";
import { formatAeLeadStatus } from "@auto-approve/auto-approve-ui-library";
import { AeTitleVendorTypeEnum } from "../../../../networking/core/types";
import { titleVendorDefault } from "../../../../helpers/defaults";

type DetailsPanelProps = {
  row: DashboardRow;
  allRows: DashboardRow[];
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
  showUpdateButton: boolean;
  showEscalateButton: boolean;
  setUpdateProgressModalOpen?: (state: boolean) => void;
  setRequestEscalationModalOpen?: (state: boolean) => void;
  handleClose?: () => void;
  setActiveTab?: Dispatch<SetStateAction<string | null>>;
};

const {
  titleSpecialistDashboard: { titleOrderInformation, drawer },
} = getCopy();

export function DetailsPanel(props: DetailsPanelProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const { data: titleOrder, isFetching, refetch } = useQuery(
    ["titleOrder", props.row.recordId],
    () => getTitleOrderById(props.row.titleOrderId, user?.token ?? "", initiallyLoaded),
    { onSettled: () => setInitiallyLoaded(true) }
  );

  const getAddressLine1 = (address?: TitlesEngineAddress | null) => {
    if (!address) return "";
    const { street1, street2 } = address;
    const suffix = street2 ? `. ${street2}` : "";
    return street1 !== "" ? `${street1}${suffix}.` : "";
  };

  const getAddressLine2 = (address?: TitlesEngineAddress | null) => {
    if (!address) return "";
    const { city, state, zip } = address;
    return `${city}, ${state} ${zip}`;
  };

  const getCustomerName = (customer?: TitlesEngineCustomer | null) => {
    if (!customer) return "";
    const { firstName, lastName } = customer;
    return `${lastName}, ${firstName}`;
  };

  const getFormattedDate = (date?: string, defaultValue = "") => {
    return date ? formatDate(date) : defaultValue;
  };

  const getFormattedFollowUpDate = () => {
    const followUpDate = titleOrder ? titleOrder.followUpDate : props.row.followUpDate;
    return getFormattedDate(followUpDate, "No follow up date");
  };

  const isWKLSVendor = useMemo(() => {
    const vendor = titleVendorDefault(titleOrder);
    return vendor === AeTitleVendorTypeEnum.WKLS;
  }, [titleOrder]);
  const DEFAULT_BORDER = "1px dashed #DEDEDE";
  {
    if (!isFetching) {
      return (
        <>
          <PanelSectionTitle
            aeLeadId={props.row.aeLeadId}
            addButton={canCreateEstimate(user) ? <CustomButton
              variant="text"
              disabled={!isWKLSVendor}
              tooltip={!isWKLSVendor ? `This record is not supported by WKLS Title Processing. Please use ${formatAeTitleVendorType(titleOrder?.vendor)} for this deal.` : ""}
              onClick={() => navigate(`${ROUTES.CREATE_ESTIMATE}?leadId=${props.row.aeLeadId}`)}
            >
              <Add /> Create Estimate
            </CustomButton> : undefined}
            refresh={refetch}>
            Details
          </PanelSectionTitle>
          <Box
            sx={{ backgroundColor: "#e5e5e857" }}
            marginBottom="2rem"
            borderRadius="1rem"
          >
            <DetailsPanelSectionRow
              className="p-6"
              fields={[
                {
                  label: titleOrderInformation.recordDetails.fundedDate,
                  value1: formatDate(titleOrder?.fundedDate)
                },
                {
                  label: titleOrderInformation.recordDetails.dealType,
                  value1: formatAeDealType(titleOrder?.dealType)
                },
                {
                  label: titleOrderInformation.recordDetails.titleVendor,
                  value1: formatAeTitleVendorType(titleOrder?.vendor)
                },
                {
                  label: titleOrderInformation.recordDetails.state,
                  value1: titleOrder?.primaryCustomer?.address?.state
                },
                {
                  label: titleOrderInformation.recordDetails.aeStatus,
                  value1: formatAeLeadStatus(titleOrder?.newAeStatus)
                },
                {
                  label: titleOrderInformation.recordDetails.followUpDate,
                  value1: getFormattedFollowUpDate()
                },
              ]}
            />
          </Box>
          <div className="h-full overflow-auto word-break break-all">
            <DetailsPanelSection
              title={titleOrderInformation.sectionTitles.vehicle}
              direction="column"
              style={{ borderTop: DEFAULT_BORDER }}
              className="py-4"
              fields={[
                {
                  label: titleOrderInformation.recordDetails.vin,
                  value1: titleOrder?.vehicle.vin
                },
                {
                  label: "Year",
                  value1: `${titleOrder?.vehicle.year ?? ""}`
                },
                {
                  label: "Make",
                  value1: titleOrder?.vehicle.make
                },
                {
                  label: "Model",
                  value1: titleOrder?.vehicle.model
                },
                {
                  label: "Color",
                  value1: titleOrder?.vehicle.color
                },
              ]}
            />
            <div
              className="d-g grid grid-cols-2 py-4"
              style={{ borderTop: DEFAULT_BORDER }}>
              <DetailsPanelSection
                className="pr-8"
                title={titleOrderInformation.sectionTitles.primaryCustomer}
                fields={titleOrder?.primaryCustomer ? [
                  {
                    label: titleOrderInformation.customerContactDetails.customerName.label,
                    value1: getCustomerName(titleOrder.primaryCustomer)
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.birthday.label,
                    // Formatting DOB here to avoid time zome issues
                    // The core issue however, is not fixed with this
                    // Concern: We have Epoch values (with the timezone) saved in AE mongo DB
                    value1: titleOrder.primaryCustomer.birthday ? formatDateWithoutTimestamp(titleOrder.primaryCustomer.birthday) : ""
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.phone.label,
                    value1: titleOrder.primaryCustomer?.homePhone
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.email.label,
                    value1: titleOrder.primaryCustomer?.email
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.address.label,
                    value1: getAddressLine1(titleOrder.primaryCustomer?.address),
                    value2: getAddressLine2(titleOrder.primaryCustomer?.address)
                  }
                ] : []}
              />
              <DetailsPanelSection
                className="pl-8"
                title={titleOrderInformation.sectionTitles.secondaryCustomer}
                fields={titleOrder?.secondaryCustomer ? [
                  {
                    label: titleOrderInformation.customerContactDetails.customerName.label,
                    value1: getCustomerName(titleOrder.secondaryCustomer)
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.birthday.label,
                    // Formatting DOB here to avoid time zome issues
                    // The core issue however, is not fixed with this
                    // Concern: We have Epoch values (with the timezone) saved in AE mongo DB
                    value1: titleOrder.secondaryCustomer.birthday ? formatDateWithoutTimestamp(titleOrder.secondaryCustomer.birthday) : ""
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.phone.label,
                    value1: titleOrder.secondaryCustomer?.homePhone
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.email.label,
                    value1: titleOrder.secondaryCustomer?.email
                  },
                  {
                    label: titleOrderInformation.customerContactDetails.address.label,
                    value1: getAddressLine1(titleOrder.secondaryCustomer?.address),
                    value2: getAddressLine2(titleOrder.secondaryCustomer?.address)
                  }
                ] : []}
              />
            </div>
            <div
              className="d-g grid grid-cols-2 py-4"
              style={{
                marginBottom: "calc(56px + 48px + 24px + 64px)",
                borderBottom: DEFAULT_BORDER,
                borderTop: DEFAULT_BORDER
              }}>
              <DetailsPanelSection
                className="pr-8"
                title={titleOrderInformation.sectionTitles.lienholderContact}
                fields={[
                  {
                    label: titleOrderInformation.lienHolderContactDetails.lienHolderName.label,
                    value1: titleOrder?.lienHolder.name
                  },
                  {
                    label: titleOrderInformation.lienHolderContactDetails.lienHolderPhone.label,
                    value1: titleOrder?.lienHolder.phone
                  },
                  {
                    label: titleOrderInformation.lienHolderContactDetails.address.label,
                    value1: getAddressLine1(titleOrder?.lienHolder?.address),
                    value2: getAddressLine2(titleOrder?.lienHolder?.address)
                  }
                ]}
              />
              <DetailsPanelSection
                className="pl-8"
                title={titleOrderInformation.sectionTitles.lenderContact}
                fields={[
                  {
                    label: titleOrderInformation.lenderContactDetail.lenderName.label,
                    value1: titleOrder?.lender?.name
                  },
                  {
                    label: titleOrderInformation.lenderContactDetail.lenderPhone.label,
                    value1: titleOrder?.lender?.phone ?? undefined
                  },
                  {
                    label: titleOrderInformation.lenderContactDetail.lenderAddress.label,
                    value1: getAddressLine1(titleOrder?.lender?.address),
                    value2: getAddressLine2(titleOrder?.lender?.address)
                  }
                ]}
              />
            </div>
          </div>
          {
            props.showUpdateButton ? <></> : <div className="pt-6 pl-12">
              {props.showUpdateButton && !isUndefined(props.setUpdateProgressModalOpen) && (
                <CustomButton
                  variant="contained"
                  sx={{ marginRight: 3, fontSize: 15, px: 4, py: 1 }}
                  onClick={() => {
                    if (!isUndefined(props.setUpdateProgressModalOpen)) {
                      props.setUpdateProgressModalOpen(true);
                    }
                  }}
                >
                  {drawer.markAs.buttonText}
                </CustomButton>
              )}
            </div>
          }
        </>
      );
    } else {
      return (
        <Box
          display="flex"
          margin="auto"
          width="100%"
          justifyContent="center"
          position="absolute"
          top="50%"
          sx={{ transform: "translate(0, -50%)" }}
        >
          <CircularProgress />
        </Box>
      );
    }
  }
}
