import { UsaStates } from "usa-states";
import { startCase } from "lodash";
import { getCopy } from "../../../authoring/copy";
import { DateTime } from "luxon";
import { AeDealTypeEnum, AeTitleVendorTypeEnum, StatusSeverity } from "../../../networking/core/types";

const copy = getCopy().titleOrdersDashboard;

export function formatState(stateNameOrAbbr: string | null | undefined) {
  const usaState = new UsaStates().states.find(
    (state) => state.name === stateNameOrAbbr || state.abbreviation === stateNameOrAbbr
  );
  return usaState ? usaState.abbreviation : copy.notFound;
}

export function formatAeDealType(dealType: AeDealTypeEnum | null | undefined) {
  return !dealType ? copy.notFound : startCase(AeDealTypeEnum[Number(dealType)]);
}
export function formatAeTitleVendorType(titleVendorType: AeTitleVendorTypeEnum | null | undefined) {
  switch (titleVendorType) {
    case AeTitleVendorTypeEnum.AutoApprove:
    case AeTitleVendorTypeEnum.WKLS:
    case AeTitleVendorTypeEnum.LocationServices:
      return startCase(AeTitleVendorTypeEnum[titleVendorType]);
    default:
      return startCase(AeTitleVendorTypeEnum[AeTitleVendorTypeEnum.WKLS]);
  }
}

export function formatDaysInStatus(daysInStatus?: number | null | undefined) {
  return daysInStatus ?? copy.notFound;
}

export function formatTotalDays(totalDays: number) {
  if (totalDays < 0) {
    return copy.notFound;
  }
  return totalDays;
}

export function formatDate(date: string | null | undefined, options?: { formatOptions: Intl.DateTimeFormatOptions }) {
  return date ? DateTime.fromISO(date).toLocaleString(options?.formatOptions) : copy.notFound;
}

export function formatLastTouchedDate(date: string | null | undefined) {
  return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) : copy.neverTouched;
}

export function severityToDisplayLabel(severity: StatusSeverity | undefined) {
  if (severity === undefined) return "";
  return copy.severities[severity];
}
