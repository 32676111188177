import { WklsEstimateTransactionDisplayType, WklsEstimateTransactionType } from "./types";

export function wklsEstimateTransactionDisplayTypeTo(
  type: WklsEstimateTransactionDisplayType
): WklsEstimateTransactionType {
  switch (type) {
    case WklsEstimateTransactionDisplayType.TitleAndRegistrationStateChange:
      return WklsEstimateTransactionType.TitleAndRegistration;
    case WklsEstimateTransactionDisplayType.TitleAndRegistrationLeaseBuyout:
      return WklsEstimateTransactionType.TitleAndRegistration;
    case WklsEstimateTransactionDisplayType.LienAdd:
      return WklsEstimateTransactionType.LienAdd;
    case WklsEstimateTransactionDisplayType.TitleOnly:
      return WklsEstimateTransactionType.TitleOnly;
    case WklsEstimateTransactionDisplayType.LienAddAddRemoveSpouse:
      return WklsEstimateTransactionType.LienAddAddRemoveSpouse;
    case WklsEstimateTransactionDisplayType.DuplicateTitle:
      return WklsEstimateTransactionType.DuplicateTitle;
  }
}
