import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatch, SetStateAction, useState } from "react";
import { getCopy } from "../../../authoring/copy";
import { updateProgressOfTitleOrderWithNote } from "../../../networking/TitlesWorkflowAPI";
import { useAuth } from "../../Auth/AuthProvider";
import { DashboardTitleOrder } from "../../../networking/core/types";
import { convertTitleOrderToDashboardRow, DashboardRow } from "../types";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { isEmpty, isNull } from "lodash";
import { isInTransitStatus } from "./core/types";
import { AeLeadStatus } from "@auto-approve/auto-approve-ui-library";

const { updateProgressModal } = getCopy().titleSpecialistDashboard;

type UpdateProgressModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  row: DashboardTitleOrder;
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
  closeDrawer: () => void;
};

export function UpdateProgressModal(props: UpdateProgressModalProps) {
  const [selectedOption, setSelectedOption] = useState<UpdateProgressOptions | undefined>(undefined);
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [uploadDateTime, setUploadDateTime] = useState<Date | null>(null);
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const [additionalInformation, setAdditionalInformation] = useState<string>("");
  const [issuesResolved, setIssuesResolved] = useState<string>("");
  const [missingOrExpiredDocuments, setMissingOrExpiredDocuments] = useState<string>("");

  const handleClose = () => {
    props.setOpen(false);
    setSelectedOption(undefined);
    setUploadDateTime(null);
    setTrackingNumber("");
    setAdditionalInformation("");
    setIssuesResolved("");
    setMissingOrExpiredDocuments("");
  };

  async function handleUpdateClick() {
    if (!user?.token) {
      return;
    }
    const { row } = props;

    if (selectedOption === undefined) return;

    if (
      selectedOption === UpdateProgressOptions.SubmittedToTitleVendor &&
      (!isNull(uploadDateTime) || !isEmpty(trackingNumber))
    ) {
      const note = `${!isEmpty(trackingNumber) ? `Title Packet to Vendor: Tracking # ${trackingNumber}` : ""}${
        !isNull(uploadDateTime) ? `Title Packet to Vendor: Upload Date ${uploadDateTime.toISOString()}` : ""
      }${!isEmpty(additionalInformation) ? ` // ${additionalInformation}` : ""}`;

      handleProgressUpdateWithNote({
        setAllRows: props.setAllRows,
        setLoading,
        handleClose,
        formattedNote: note,
        desiredStatus: AeLeadStatus.PacketToTitleVendor,
        userId: user.teUserId,
        userToken: user.token,
        row,
        closeDrawer: props.closeDrawer,
      });
    }

    if (selectedOption === UpdateProgressOptions.OnHoldReasonsResolvedAndSubmitted) {
      const note = `${!isEmpty(issuesResolved) ? `Issues Resolved: ${issuesResolved}` : ""}${
        !isEmpty(additionalInformation) ? ` // ${additionalInformation}` : ""
      }`;

      handleProgressUpdateWithNote({
        setAllRows: props.setAllRows,
        setLoading,
        handleClose,
        formattedNote: note,
        desiredStatus: AeLeadStatus.ProblemResolvedSentToTitleVendor,
        userId: user.teUserId,
        userToken: user.token,
        row,
        closeDrawer: props.closeDrawer,
      });
    }

    if (selectedOption === UpdateProgressOptions.MissingOrExpiredDocuments) {
      const note = `${!isEmpty(missingOrExpiredDocuments) ? `Pending - Missing ${missingOrExpiredDocuments}` : ""}${
        !isEmpty(additionalInformation) ? ` // ${additionalInformation}` : ""
      }`;

      handleProgressUpdateWithNote({
        setAllRows: props.setAllRows,
        setLoading,
        handleClose,
        formattedNote: note,
        desiredStatus: AeLeadStatus.PendingTitlePacket,
        userId: user.teUserId,
        userToken: user.token,
        row,
        closeDrawer: props.closeDrawer,
      });
    }
  }

  const styles = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      minWidth: "400px",
      maxWidth: "650px",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    select: {
      margin: "10px 10px 10px 0",
      width: "40%",
    },
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box sx={styles.box}>
        <div className={"float-right"}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography id="mark-as-heading" variant="h6" component="h6">
          {updateProgressModal.heading}
        </Typography>
        <FormControl sx={{ margin: "20px 0px 20px 0px", width: "100%" }}>
          <FormLabel sx={{ marginBottom: "15px" }}>{updateProgressModal.formHeader}</FormLabel>
          <RadioGroup name="update-status-options">
            <SubmittedToTitleVendorOption
              row={props.row}
              onClick={() => {
                setSelectedOption(UpdateProgressOptions.SubmittedToTitleVendor);
              }}
              isSelected={selectedOption === UpdateProgressOptions.SubmittedToTitleVendor}
              uploadDateTime={uploadDateTime}
              setUploadDateTime={setUploadDateTime}
              trackingNumber={trackingNumber}
              setTrackingNumber={setTrackingNumber}
            />
            <OnHoldReasonsResolvedAndSubmittedOption
              row={props.row}
              onClick={() => {
                setSelectedOption(UpdateProgressOptions.OnHoldReasonsResolvedAndSubmitted);
              }}
              isSelected={selectedOption === UpdateProgressOptions.OnHoldReasonsResolvedAndSubmitted}
              issuesResolved={issuesResolved}
              setIssuesResolved={setIssuesResolved}
            />
            <MissingOrExpiredDocumentsOption
              row={props.row}
              onClick={() => {
                setSelectedOption(UpdateProgressOptions.MissingOrExpiredDocuments);
              }}
              isSelected={selectedOption === UpdateProgressOptions.MissingOrExpiredDocuments}
              missingOrExpiredDocuments={missingOrExpiredDocuments}
              setMissingOrExpiredDocuments={setMissingOrExpiredDocuments}
            />
          </RadioGroup>
          <div>
            <div className="py-2">Any additional information?</div>
            <TextField
              multiline
              rows={6}
              fullWidth
              value={additionalInformation}
              onChange={(event) => setAdditionalInformation(event.target.value)}
            />
          </div>
        </FormControl>
        <div className={"flex items-center float-right"}>
          <Button
            variant="outlined"
            sx={{ borderRadius: "9999px", borderColor: "#5230A2", color: "#5230A2", marginRight: "10px" }}
            onClick={handleClose}
            disabled={loading}
          >
            {updateProgressModal.cancel.buttonText}
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{ borderRadius: "9999px", borderColor: "#5230A2", color: "#5230A2" }}
            onClick={handleUpdateClick}
            disabled={loading}
          >
            {updateProgressModal.updateRecord.buttonText}
          </Button>
          {loading && (
            <CircularProgress
              data-testid="loading-indicator"
              className="mx-4"
              size={20}
              thickness={8.0}
              color={"inherit"}
            />
          )}
        </div>
      </Box>
    </Modal>
  );
}

enum UpdateProgressOptions {
  SubmittedToTitleVendor,
  MissingOrExpiredDocuments,
  OnHoldReasonsResolvedAndSubmitted,
}

function handleProgressUpdateWithNote(args: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleClose: VoidFunction;
  formattedNote: string;
  desiredStatus: AeLeadStatus;
  row: DashboardTitleOrder;
  userId: number;
  userToken: string;
  setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
  closeDrawer: VoidFunction;
}) {
  const { setLoading, handleClose, formattedNote, row, userId, userToken, setAllRows, closeDrawer, desiredStatus } =
    args;

  setLoading(true);
  updateProgressOfTitleOrderWithNote({
    request: {
      aeLeadId: row.aeLeadId,
      desiredStatus: desiredStatus,
      teUserId: userId,
      titleOrderId: row.titleOrderId,
      note: formattedNote,
    },
    token: userToken,
  })
    .then((response) => {
      const rowWithUpdatedStatus: DashboardTitleOrder = {
        ...row,
        lastTouched: response.lastTouched,
        aeStatus: AeLeadStatus.PacketToTitleVendor,
      };
      const updatedDashboardRow = convertTitleOrderToDashboardRow(rowWithUpdatedStatus);
      setAllRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.id === updatedDashboardRow.id) return updatedDashboardRow;
          return row;
        });
      });

      if (isInTransitStatus(updatedDashboardRow)) {
        closeDrawer();
      }
      handleClose();
    })
    .catch((err) => {
      alert("Failed to make an update to the title order. Additional error info in the console.");
      console.error(err);
    })
    .finally(() => setLoading(false));
}

function showMissingOrExpiredDocuments(status: AeLeadStatus): boolean {
  return [
    AeLeadStatus.LienReleaseReceived,
    AeLeadStatus.TitleReceived,
    AeLeadStatus.PayoffCheckSent,
    AeLeadStatus.PendingTitlePacket,
  ].includes(status);
}

function MissingOrExpiredDocumentsOption(props: {
  row: DashboardTitleOrder;
  onClick: VoidFunction;
  isSelected: boolean;
  missingOrExpiredDocuments: string;
  setMissingOrExpiredDocuments: Dispatch<SetStateAction<string>>;
}) {
  if (!showMissingOrExpiredDocuments(props.row.aeStatus)) return <></>;
  return (
    <>
      <FormControlLabel
        control={<Radio checked={props.isSelected} />}
        label={updateProgressModal.updateProgressOptions.missingOrExpiredDocuments.label}
        onClick={props.onClick}
      />
      {props.isSelected && (
        <div className="py-2 space-between flex">
          <TextField
            label={updateProgressModal.updateProgressOptions.missingOrExpiredDocuments.fields.missingOrExpiredDocuments}
            value={props.missingOrExpiredDocuments}
            onChange={(event) => props.setMissingOrExpiredDocuments(event.target.value)}
            multiline
            rows={5}
            fullWidth
          />
        </div>
      )}
    </>
  );
}

function showOnHoldReasonsResolvedAndSubmitted(status: AeLeadStatus): boolean {
  return [AeLeadStatus.TitleProblemInHouse, AeLeadStatus.PendingTitlePacket].includes(status);
}

function OnHoldReasonsResolvedAndSubmittedOption(props: {
  row: DashboardTitleOrder;
  onClick: VoidFunction;
  isSelected: boolean;
  issuesResolved: string;
  setIssuesResolved: Dispatch<SetStateAction<string>>;
}) {
  if (!showOnHoldReasonsResolvedAndSubmitted(props.row.aeStatus)) return <></>;
  return (
    <>
      <FormControlLabel
        control={<Radio checked={props.isSelected} />}
        label={updateProgressModal.updateProgressOptions.onHoldReasonsResolvedAndSubmitted.label}
        onClick={props.onClick}
      />
      {props.isSelected && (
        <div className="py-2 space-between flex">
          <TextField
            label={updateProgressModal.updateProgressOptions.onHoldReasonsResolvedAndSubmitted.fields.issuesResolved}
            value={props.issuesResolved}
            onChange={(event) => props.setIssuesResolved(event.target.value)}
            multiline
            rows={5}
            fullWidth
          />
        </div>
      )}
    </>
  );
}

function showSubmittedToTitleVendor(status: AeLeadStatus): boolean {
  return [
    AeLeadStatus.LienReleaseReceived,
    AeLeadStatus.TitleReceived,
    AeLeadStatus.PayoffCheckSent,
    AeLeadStatus.PendingTitlePacket,
  ].includes(status);
}

function SubmittedToTitleVendorOption(props: {
  row: DashboardTitleOrder;
  onClick: VoidFunction;
  isSelected: boolean;
  uploadDateTime: Date | null;
  setUploadDateTime: Dispatch<SetStateAction<Date | null>>;
  trackingNumber: string;
  setTrackingNumber: Dispatch<SetStateAction<string>>;
}) {
  if (!showSubmittedToTitleVendor(props.row.aeStatus)) return <></>;
  return (
    <>
      <FormControlLabel
        control={<Radio checked={props.isSelected} />}
        label={updateProgressModal.updateProgressOptions.submittedToTitleVendor.label}
        onClick={props.onClick}
      />
      {props.isSelected && (
        <div className="py-2 space-between flex">
          <TextField
            label={updateProgressModal.updateProgressOptions.submittedToTitleVendor.fields.trackingNumber}
            value={props.trackingNumber}
            onChange={(event) => props.setTrackingNumber(event.target.value)}
          />
          <div className="flex justify-center align-center flex-col px-2">or</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              onChange={(value) => props.setUploadDateTime(value)}
              value={props.uploadDateTime}
              label={updateProgressModal.updateProgressOptions.submittedToTitleVendor.fields.uploadDateTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      )}
    </>
  );
}
