import { DashboardRow } from "../../types";
import { NotesSection } from "./NotesSection";

type NotesPanelProps = {
    row: DashboardRow;
};

export const NotesPanel = (props: NotesPanelProps) => (
    <NotesSection leadOid={props.row.aeLeadId} />
);
