import React from "react";
import { CreateEstimateTitleOrder } from "../../networking/core/types";
import { formatDateWithoutTimestamp } from "../Dashboards/helpers";

type Props = {
  titleOrder: CreateEstimateTitleOrder | undefined;
};

export function CustomerInfoForEstimate(props: Props) {
  if (!props.titleOrder) {
    return <></>;
  }

  const { primaryCustomer } = props.titleOrder;
  const address = primaryCustomer ? primaryCustomer.address : null;
  const birthday = primaryCustomer?.birthday ? formatDateWithoutTimestamp(primaryCustomer?.birthday) : "";
  const hasMiddleName = !!primaryCustomer?.middleName?.trim();
  return (
    <div>
      <Info title={"First Name"}>{primaryCustomer?.firstName}</Info>
      {hasMiddleName && <Info title={"Middle Name"}>{primaryCustomer?.middleName}</Info>}
      <Info title={"Last Name"}>{primaryCustomer?.lastName}</Info>
      <Info title={"Date of Birth"}>{birthday}</Info>
      <Info title={"Address"}>
        {address &&
          <>
            <div>
              {address.street1} {address.street2}
            </div>
            <div>
              {address.city}, {address.state} {address.zip}
            </div>
          </>}
      </Info>
      <Info title={"Phone"}>
        {primaryCustomer?.phoneNumbers.map((phoneNumber) => {
          return <div key={phoneNumber}>{phoneNumber}</div>;
        })}
      </Info>
      <Info title={"Email"}>
        <div>{primaryCustomer?.email ? primaryCustomer?.email : ""}</div>
      </Info>
    </div>
  );
}

function Info(props: { title: string; children: React.ReactNode }) {
  return (
    <div className="text-xs text-black flex pb-4">
      <div className="font-bold w-52 text-base text-aa-dark-grey">{props.title}</div>
      <span className="text-base text-aa-dark-grey">{props.children}</span>
    </div>
  );
}
