export type EnvironmentVariables = {
  titlesEngineApi: string;
  titlesWorkflowApi: string;
  aeUrl: string;
  muiPremiumLicenseKey: string;
  launchDarklyClientSideID: string;
  iLienUrl: string;
  boxBaseUrl: string;
};

// need to update env.js file regex at line 61

export const environmentVariables: EnvironmentVariables = {
  titlesEngineApi: process.env.REACT_APP_TITLES_ENGINE_API ?? "",
  titlesWorkflowApi: process.env.REACT_APP_TITLES_WORKFLOW_API ?? "",
  aeUrl: process.env.AUTO_APPROVE_APP_URL ?? "",
  muiPremiumLicenseKey: process.env.REACT_APP_MUI_PREMIUM_LICENSE_KEY ?? "",
  launchDarklyClientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? "",
  iLienUrl: process.env.REACT_APP_ILIEN_URL ?? "",
  boxBaseUrl: process.env.AUTO_APPROVE_APP_BOX_URL ?? "",
};
