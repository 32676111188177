import { ReactElement } from "react";
import { IconBarButton } from "../../shared/IconBarButton/IconBarButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { OpenRecordInAeButton } from "./OpenRecordInAeButton";

type PanelSectionTitleProps = {
    aeLeadId: string;
    addButton?: ReactElement | undefined;
    refresh?: () => void;
    children: JSX.Element | string;
};

export const PanelSectionTitle = (props: PanelSectionTitleProps) =>
    <div className="flex pb-8"
        style={{ justifyContent: "space-between", zIndex: 1, position: "sticky", top: 0, background: "white" }}>
        <div className={"text-2xl font-bold"}
            style={{ display: "flex", alignItems: "center" }}>
            {props.refresh && <IconBarButton
                onClick={props.refresh}
                tooltipText="Refresh">
                <AutorenewIcon />
            </IconBarButton>}
            {props.children}
            <div className="ml-4">
                <OpenRecordInAeButton aeLeadId={props.aeLeadId} />
            </div>
        </div>
        <div style={{ display: "flex" }}>
            {props.addButton && props.addButton}
        </div>
    </div>;