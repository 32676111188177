import { Select, SelectProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

type LoadingSelectProps<T = unknown> = SelectProps<T> & {
  loading?: boolean;
};

export function LoadingSelect<T>(props: LoadingSelectProps<T>) {
  return <Select {...props} endAdornment={props.loading ? <SelectLoadingIcon /> : null} />;
}

function SelectLoadingIcon() {
  return (
    <div className="mr-5">
      <CircularProgress data-testid="loading-indicator" size={20} thickness={8.0} />
    </div>
  );
}
