import { AeNote, AeNotes, isCreateNoteResponse, isNotesForLeadResponse } from "./types";
import axios, { AxiosRequestConfig } from "axios";
import { environmentVariables } from "../../config";

export async function getNotes(token: string, leadId: string, skip = 0): Promise<AeNotes> {
  const url = `${environmentVariables.titlesWorkflowApi}/approve-engine-notes`;
  const config: AxiosRequestConfig = {
    params: {
      leadId,
      skip,
    },
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.get(url, config);
  if (isNotesForLeadResponse(response.data)) {
    return response.data;
  }
  return Promise.reject("Unable to retrieve ae lead notes");
}

export async function createNote(token: string, leadId: string, description: string): Promise<AeNote> {
  const url = `${environmentVariables.titlesWorkflowApi}/approve-engine-notes`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const body = {
    leadId,
    description
  };

  const response = await axios.post(url, body, config);
  if (isCreateNoteResponse(response.data)) {
    return response.data;
  }
  return Promise.reject("Unable to create ae lead note");
}
