import { Button, ButtonProps, Tooltip } from "@mui/material";
import { styled, SxProps, Theme } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { omit } from "lodash";

interface CustomButtonProps extends ButtonProps {
  variant: "contained" | "outlined" | "text";
  sx?: SxProps<Theme>;
  loading?: boolean;
  tooltip?: string;
  tooltipPlacement?: "top" | "left" | "bottom" | "right" // more are supported
}

export function CustomButton(props: CustomButtonProps) {
  const styles = {
    contained: {
      backgroundColor: "#5230A2",
      borderColor: "#5230A2",
      color: "#FFFFFF",
    },
    outlined: {
      backgroundColor: "#FFFFFF",
      color: "#5230A2",
      borderColor: "#5230A2",
    },
    text: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      color: "#5230A2",
    },
  };

  const baseStyles = {
    fontWeight: "bold",
    borderRadius: "9999px",
  };

  const StyledButton = styled(Button)({
    "&:hover": {
      backgroundColor: styles[props.variant].backgroundColor,
      borderColor: styles[props.variant].borderColor,
      opacity: 0.9,
    },
  });

  return (
    <Tooltip title={props.tooltip ?? ""} placement={props.tooltipPlacement ?? "top"}>
      <div>
        <StyledButton {...omit(props, "loading")} sx={{ ...baseStyles, ...styles[props.variant], ...props.sx }}>
          {props.children}
          {(props.loading ?? false) && (
            <CircularProgress
              data-testid="loading-indicator"
              className="absolute right-0 mx-1"
              size={20}
              thickness={8.0}
              color={"inherit"}
            />
          )}
        </StyledButton>
      </div>
    </Tooltip>
  );
}
