import React from "react";
import { Accordion, AccordionSummary, CircularProgress, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DashboardTable } from "../DashboardTable";
import { AdditionalDashboardColumn, DashboardRow } from "../types";
import { styled } from "@mui/material/styles";
import { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import {
  GridCallbackDetails,
  GridColumns,
  GridRowParams,
  GridSelectionModel,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid";
import { DashboardContextType } from "../context/types";

type AccordionTableProps = {
  headerText: string;
  expanded?: boolean;
  setExpanded?: (header: string) => void;
  rows: DashboardRow[];
  setVisibleRowCount?: (count: number) => void;
  hasRowRefreshButtons?: boolean;
  getColumns: () => GridColumns<DashboardRow> & { field: keyof DashboardRow | "refreshButton" | AdditionalDashboardColumn }[];
  useData: () => DashboardContextType;
  checkboxSelection?: boolean;
  muiComponentProps?: GridSlotsComponentsProps;
  onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
  onCellClick?: (row: DashboardRow) => void;
  isRowSelectable?: (row: GridRowParams) => boolean;
  filterMode?: "client" | "server";
  sortingMode?: "client" | "server";
  paginationMode?: "client" | "server";
  setRowsPerPage?: (pageSize: number) => void;
  setSkipRows?: React.Dispatch<React.SetStateAction<number>>;
};

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => <AccordionSummary {...props} />)(() => ({
  flexDirection: "row-reverse",
}));

export const AccordionTable = React.memo(function AccordionTable(props: AccordionTableProps) {
  const { loading } = props.useData();
  const { hasRowRefreshButtons = false } = props;
  return (
    <Accordion
      disableGutters={true}
      disabled={loading || props.rows.length === 0}
      expanded={!loading && props.expanded && props.rows.length > 0}
      onChange={() => {
        if (props.setExpanded) {
          props.setExpanded(props.expanded ? "" : props.headerText);
        }
      }}
      aria-label={`${props.headerText} accordion`}
    >
      <CustomAccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMoreIcon />
          </IconButton>
        }
      >
        <Typography data-testid={"accordion-header"}>
          {loading ? <div style={{ display: "flex", alignItems: "center" }}>
            {props.headerText}<CircularProgress className="ml-4" size={20} thickness={8} />
          </div>
            : <>{props.headerText} ({props.rows.length})</>}
        </Typography>
      </CustomAccordionSummary>
      <DashboardTable
        getColumns={props.getColumns}
        hasRowRefreshButtons={hasRowRefreshButtons}
        defaultPageSize={10}
        rowsPerPageOptions={[10, 15, 20]}
        rows={props.rows}
        totalRows={props.rows.length}
        filterMode={props.filterMode ?? "client"}
        sortingMode={props.sortingMode ?? "client"}
        paginationMode={props.paginationMode ?? "client"}
        setVisibleRowCount={props.setVisibleRowCount}
        useData={props.useData}
        checkboxSelection={props.checkboxSelection}
        onSelectionModelChange={props.onSelectionModelChange}
        onCellClick={props.onCellClick}
        isRowSelectable={props.isRowSelectable}
        accordionFlag={true} // TODO - replace with better height solution
      />
    </Accordion>
  );
});
