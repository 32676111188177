import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { history } from "./history";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CustomRouter from "./components/CustomRouter/CustomRouter";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msal-config";
import { LicenseInfo as MuiXLicenseInfo } from "@mui/x-license-pro";
import { environmentVariables } from "./config";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

MuiXLicenseInfo.setLicenseKey(environmentVariables.muiPremiumLicenseKey);

export const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environmentVariables.launchDarklyClientSideID,
  });


  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <CustomRouter history={history}>
          <App msalInstance={msalInstance} />
        </CustomRouter>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
