import { useState } from "react";
import { DashboardRow } from "../types";

export function useOperations() {
  const [allRows, setAllRows] = useState<DashboardRow[]>([]);
  const [dashboardRows, setDashboardRows] = useState(allRows);
  const [dashboardUpdate, setDashboardUpdate] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  return {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    dashboardUpdate,
    setDashboardUpdate,
    searchTerm,
    setSearchTerm,
    loading,
    setLoading,
  };
}
