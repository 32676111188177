import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { ReactNode } from "react";

export function BaseModal(props: {
  isOpen: boolean;
  onClose: () => void;
  icon?: ReactNode;
  title: string;
  description?: string;
  children?: ReactNode;
  actions: Array<ReactNode>;
}) {

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} PaperProps={{ sx: { borderRadius: "12px", padding: "1rem" } }}>
      <DialogContent>
        <Close className="absolute top-4 right-6 text-aa-text-grey cursor-pointer" onClick={props.onClose} />
        <div className="flex items-center mb-6">
          {props.icon}
          <Typography fontSize={16} id="docs-received-task-modal-heading" variant="h6" component="h6">
            {props.title}
          </Typography>
        </div>
        {props.description && (
          <Typography
            fontSize={14}
            className="text-sm text-aa-text-grey"
            id="docs-received-task-modal-explanation"
            variant="body1"
            paragraph={true}
          >
            {props.description}
          </Typography>
        )}
        {props.children}
        <div className="float-right mt-6">{props.actions}</div>
      </DialogContent>
    </Dialog>
  );
}
