import React from "react";
import { omit } from "lodash";

export function CenteredDiv(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const outerDivProps = omit(props, "children");

  return (
    <div {...outerDivProps}>
      <div className="w-full h-full relative flex flex-row justify-center items-center">
        <div className="min-w-fit">{props.children}</div>
      </div>
    </div>
  );
}
