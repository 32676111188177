import { WklsEstimateTransactionType } from "../../components/types";
import { UIEstimateTemplate } from "../../contexts/estimates/core/types";
import {
  CreateEstimateTitleOrder,
  ExcludedFieldGroup,
  isGetCountiesResponse,
  isUIEstimateTemplate,
  TitleVendorCreateEstimateRequest,
  WORKFLOW_HOST_URL,
} from "../core/types";
import axios, { AxiosRequestConfig } from "axios";
import {
  UiEstimateTemplateField,
  SpecialInstructions,
} from "../../components/CreateEstimate/steps/submitEstimate/types";
import { specialInstructionsConverter, unknownFieldsConverter } from "../core/converters";

export async function getEstimateTemplate(
  token: string,
  transactionType: WklsEstimateTransactionType,
  state: string,
  county: string,
  aeRecordId: string
): Promise<UIEstimateTemplate> {
  const url = `${WORKFLOW_HOST_URL}/get-estimate-template`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const data = {
    transactionType,
    state,
    county,
    aeRecordId,
  };

  const response = await axios.post(url, data, config);
  if (isUIEstimateTemplate(response.data)) {
    return {
      fields: response.data.fields,
      estimateId: response.data.estimateId,
    };
  }
  throw new Error("Unable to retrieve estimate template fields");
}

export async function getEstimateCounties(token: string, transactionType: WklsEstimateTransactionType, state: string) {
  const url = `${WORKFLOW_HOST_URL}/get-counties`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };
  const data = {
    transactionType,
    state,
  };

  const response = await axios.post(url, data, config);
  if (isGetCountiesResponse(response.data)) {
    return response.data;
  }
  return Promise.reject("Unable to retrieve counties");
}

export async function submitEstimate(
  token: string,
  titleOrder: CreateEstimateTitleOrder,
  unknownFields: UiEstimateTemplateField[],
  specialInstructions: SpecialInstructions,
  estimateId: number,
  excludedFieldGroup: ExcludedFieldGroup,
) {
  const url = `${WORKFLOW_HOST_URL}/submit-estimate-to-title-vendor`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const payload: TitleVendorCreateEstimateRequest = {
    titleOrder,
    specialInstructions: specialInstructionsConverter(specialInstructions),
    unknownFields: unknownFieldsConverter(unknownFields),
    estimateId,
    excludedFieldGroup,
  };

  await axios.post(url, payload, config).catch((ex) => {
    console.log(`Error in ${submitEstimate.name}: ${ex}`);
    return Promise.reject(ex);
  });
}
