import { EstimateTemplateField } from "../../../../contexts/estimates/core/types";
import { CreateEstimateTitleOrder } from "../../../../networking/core/types";

export type TitleOrderField = {
  estimateTemplateField: EstimateTemplateField;
  displayLabel: string;
  isValid: boolean | undefined;
  getValue: (titleOrder: CreateEstimateTitleOrder | undefined) => string;
  setValue: (titleOrder: CreateEstimateTitleOrder, newValue: string | undefined) => void;
};

export type UiEstimateTemplateField = EstimateTemplateField & {
  value: string | undefined;
  isValid: boolean | undefined;
};

export type FieldGroup = {
  groupName: string;
  knownFields: TitleOrderField[];
  unknownFields: UiEstimateTemplateField[];
};

export type SpecialInstructions = {
  fields: Map<string, string>;
  text: string;
};

export function getEmptySpecialInstructions(): SpecialInstructions {
  return {
    fields: new Map<string, string>(),
    text: "",
  };
}
