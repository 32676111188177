import { TaskOutlined } from "@mui/icons-material";
import { useState } from "react";
import { getCopy } from "../../../../authoring/copy";
import { useAuth } from "../../../Auth/AuthProvider";
import { BaseModal } from "../../../shared/BaseModal/BaseModal";
import { CustomButton } from "../Button";

const { docsReceivedModal } = getCopy().associateTitleSpecialistDashboard.taskPanel;

export function DocsReceivedModal(props: { isOpen: boolean; onClose: () => void; onSubmit: () => Promise<unknown> }) {
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  return (
    <BaseModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      icon={<TaskOutlined className="mr-3" />}
      title={docsReceivedModal.heading}
      description={docsReceivedModal.explanation}
      actions={[
        <CustomButton
          key={docsReceivedModal.cancelButton.label}
          variant="text"
          sx={{ px: 4, color: "#5F5F5F", fontWeight: "normal" }}
          onClick={props.onClose}
          disabled={loading}
        >
          {docsReceivedModal.cancelButton.label}
        </CustomButton>,
        <CustomButton
          key={docsReceivedModal.submitButton.label}
          variant="outlined"
          sx={{ px: 4 }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? docsReceivedModal.submitButton.loadingLabel : docsReceivedModal.submitButton.label}
        </CustomButton>,
      ]}
    />
  );

  async function handleSubmit() {
    if (user?.token) {
      setLoading(true);
      props
        .onSubmit()
        .then(() => {
          props.onClose();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          alert(`Failed to save document status. Error: ${err}`);
        });
    }
  }
}
