import { getCopy } from "../../../authoring/copy";
import { useFormik } from "formik";
import { Box, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import { CenteredDiv } from "../CenteredDiv";
import { CustomButton } from "../../Dashboards/shared/Button";
import { noInitialErrorsButInvalid } from "../../../validation/formik";

type LookupByRecordIdProps = {
  onSubmit: (values: { recordId: string }) => void;
  defaultRecordId: string | null;
  nextStepIsLoading?: boolean;
  headerOverride?: string;
};

export function LookupByRecordId(props: LookupByRecordIdProps) {
  const { header, enterRecordId, nextButtonLabel, recordIdInputPlaceholder, recordIdValidationError } =
    getCopy().createEstimate.lookupRecordId;

  const formik = useFormik({
    initialValues: {
      recordId: props.defaultRecordId ?? "",
    },
    initialErrors: noInitialErrorsButInvalid(),
    onSubmit: props.onSubmit,
    validate: ({ recordId }) => {
      const result: Record<string, string> = {};

      if (!/^\d+$/.test(recordId)) {
        // one or more digits
        result.recordId = recordIdValidationError;
      }

      return result;
    },
    validateOnMount: props.defaultRecordId != null,
  });

  return (
    <CenteredDiv className="mt-10">
      <Box boxShadow={2} sx={{ p: "50px", width: "530px" }}>
        <h2 className="mb-4 text-2xl font-medium text-center">{props.headerOverride ?? header}</h2>

        <form className="mt-10 px-20 flex flex-col gap-2 justify-center items-center" onSubmit={formik.handleSubmit}>
          <div className="w-full flex flex-col">
            <FormControl sx={{ m: 3 }} error={formik.errors.recordId !== undefined} variant="standard">
              <FormControlLabel
                control={
                  <TextField
                    error={formik.errors.recordId !== undefined}
                    type="text"
                    id="recordId"
                    name="recordId"
                    data-testid="recordId"
                    data-cy="recordId"
                    placeholder={recordIdInputPlaceholder}
                    value={formik.values.recordId}
                    onChange={formik.handleChange}
                  />
                }
                value={"enter-record-id"}
                label={enterRecordId}
                labelPlacement={"top"}
                componentsProps={{
                  typography: {
                    fontSize: "14px",
                    color: "rgb(107, 114, 128)",
                    alignSelf: "flex-start",
                  },
                }}
              />
              <div className={"flex justify-center"}>
                <FormHelperText>{formik.errors.recordId}</FormHelperText>
              </div>
            </FormControl>
          </div>

          <div className="mt-7">
            <CustomButton
              sx={{ px: "2em", fontWeight: "400" }}
              type={"submit"}
              variant={"outlined"}
              loading={props.nextStepIsLoading}
              disabled={!formik.isValid || formik.isSubmitting}
              data-testid="estimate-record-id-next"
            >
              {nextButtonLabel}
            </CustomButton>
          </div>
        </form>
      </Box>
    </CenteredDiv>
  );
}
