import React, { useEffect, useState } from "react";
import { Alert, Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { CustomerInfoForEstimate } from "../CustomerInfoForEstimate";
import { CenteredDiv } from "../CenteredDiv";
import { CustomButton } from "../../Dashboards/shared/Button";
import { getCopy } from "../../../authoring/copy";
import { useFormik } from "formik";
import { WklsEstimateTransactionDisplayType } from "../../types";
import { noInitialErrorsButInvalid } from "../../../validation/formik";
import { CreateEstimateTitleOrder } from "../../../networking/core/types";
import { getEstimateCounties } from "../../../networking/estimates/TitlesWorkflowEstimatesApi";
import { useRequiredAuthUser } from "../../Auth/AuthProvider";
import { LoadingSelect } from "../../shared/Loading/LoadingSelect";
import { estimatesStates } from "../../../helpers/states";
import { wklsEstimateTransactionDisplayTypeTo } from "../../converters";
import { formatAeTitleVendorType } from "../../Dashboards/shared/FieldFormats";

type ConfirmEstimateProps = {
  onConfirmEstimate: (
    transactionType: WklsEstimateTransactionDisplayType,
    state: string,
    county: string,
    aeRecordId: string
  ) => void;
  onGoBack: () => void;
  titleOrder: CreateEstimateTitleOrder | undefined;
};

export function ConfirmEstimate(props: ConfirmEstimateProps) {
  const { confirmEstimate } = getCopy().createEstimate;

  const [selectedTransactionType, setSelectedTransactionType] = useState<WklsEstimateTransactionDisplayType | null>(
    null
  );
  const [selectedState, setSelectedState] = useState<string | undefined>(props.titleOrder?.primaryCustomer?.address?.state);
  const [selectedCounty, setSelectedCounty] = useState<string | null>(null);
  const [counties, setCounties] = useState<string[] | null>(null);
  const [countiesLoading, setCountiesLoading] = useState<boolean>(false);

  const user = useRequiredAuthUser();

  useEffect(() => {
    setSelectedCounty(null);

    const handleGetCounties = async () => {
      if (!selectedTransactionType || !selectedState) return;
      setCountiesLoading(true);
      const counties = await getEstimateCounties(
        user.token,
        wklsEstimateTransactionDisplayTypeTo(selectedTransactionType),
        selectedState
      ).finally(() => setCountiesLoading(false));
      setCounties(counties);
    };
    handleGetCounties();
  }, [selectedTransactionType, selectedState, user]);

  const formik = useFormik({
    initialValues: {
      transactionType: null,
      state: props.titleOrder?.primaryCustomer?.address?.state ?? null,
      county: null,
    },
    initialErrors: noInitialErrorsButInvalid(),
    onSubmit: () => {
      if (
        selectedTransactionType !== null &&
        selectedState !== undefined &&
        selectedCounty !== null &&
        props.titleOrder !== undefined
      ) {
        props.onConfirmEstimate(
          selectedTransactionType,
          selectedState,
          selectedCounty,
          props.titleOrder.aeRecordId.toString()
        );
      }
    },
    validate: ({ transactionType, state, county }) => {
      const result: Record<string, string> = {};
      if (!transactionType) {
        result.transactionType = confirmEstimate.transactionType.errorMessage;
      }

      if (!state) {
        result.state = confirmEstimate.state.errorMessage;
      }

      if (!county) {
        result.county = confirmEstimate.county.errorMessage;
      }
      return result;
    },
  });

  const displayTransactionTypeOptions = () => {
    return Object.values(WklsEstimateTransactionDisplayType).map((transactionType) => {
      return (
        <MenuItem
          data-testid={"transaction-type-select-item"}
          key={transactionType}
          onClick={() => {
            setSelectedTransactionType(transactionType);
          }}
          role="menuitem"
          value={transactionType}
        >
          {confirmEstimate.transactionType.options[transactionType]}
        </MenuItem>
      );
    });
  };

  const displayStateOptions = () => {
    return estimatesStates.map((state) => {
      return (
        <MenuItem
          data-testid={"state-select-item"}
          key={state.name}
          onClick={() => {
            setSelectedState(state.state);
          }}
          role="menuitem"
          value={state.state}
        >
          {state.name}
        </MenuItem>
      );
    });
  };

  const displayCountyOptions = () => {
    if (!selectedTransactionType || !selectedState || !counties) return;

    return counties.map((county) => {
      return (
        <MenuItem
          data-testid={"county-select-item"}
          key={county}
          onClick={() => {
            setSelectedCounty(county);
          }}
          role="menuitem"
          value={county}
        >
          {county}
        </MenuItem>
      );
    });
  };

  return (
    <CenteredDiv className="mt-10">
      <Box boxShadow={2} sx={{ p: "50px", width: "878px" }}>
        <h2 className="mb-4 text-2xl text-left font-medium text-aa-dark-purple">{confirmEstimate.header}</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-8">
            <CustomerInfoForEstimate titleOrder={props.titleOrder} />
          </div>
          {formatAeTitleVendorType(props.titleOrder?.vendor) === "WKLS" ?
          <>
            <div className="mt-8">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="transaction-type-select-label">{confirmEstimate.transactionType.placeholder}</InputLabel>
                <Select
                  name="transactionType"
                  aria-labelledby="transaction-type-select-label"
                  data-testid="transaction-type-select"
                  displayEmpty={false}
                  label={confirmEstimate.transactionType.placeholder}
                  error={formik.errors.transactionType !== undefined}
                  value={selectedTransactionType ?? ""}
                  onChange={formik.handleChange}
                >
                  {displayTransactionTypeOptions()}
                </Select>
                <FormHelperText>{formik.errors.transactionType}</FormHelperText>
              </FormControl>
            </div> 
            <div className="mt-8">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="state-select-label">{confirmEstimate.state.placeholder}</InputLabel>
                <LoadingSelect
                  name="state"
                  aria-labelledby="state-select-label"
                  data-testid="state-select"
                  displayEmpty={false}
                  label={confirmEstimate.state.placeholder}
                  error={formik.errors.state !== undefined}
                  value={selectedState ?? ""}
                  onChange={formik.handleChange}
                >
                  {displayStateOptions()}
                </LoadingSelect>
                <FormHelperText>{formik.errors.state}</FormHelperText>
              </FormControl>
            </div>
          </> :
            <Box marginY="3rem">
              <Alert severity="error">
                This record is not supported by WKLS Title Processing. Please use {formatAeTitleVendorType(props.titleOrder?.vendor)} for this deal.
              </Alert>
            </Box>
          }
          {selectedState && selectedTransactionType && (
            <div className="mt-8">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="county-select-label">{confirmEstimate.county.placeholder}</InputLabel>
                <LoadingSelect
                  name="county"
                  aria-labelledby="county-select-label"
                  data-testid="county-select"
                  displayEmpty={false}
                  label={confirmEstimate.county.placeholder}
                  error={formik.errors.county === "County is required" ? true : false}
                  value={selectedCounty ?? ""}
                  onChange={formik.handleChange}
                  disabled={!selectedTransactionType || !selectedState || countiesLoading}
                  loading={countiesLoading}
                >
                  {displayCountyOptions()}
                </LoadingSelect>
                <FormHelperText>{formik.errors.county}</FormHelperText>
              </FormControl>
            </div>
          )}
          <div className="mt-8">
            <hr />
          </div>
          <div className="mt-8 text-right flex justify-between">
            <CustomButton
              onClick={() => props.onGoBack()}
              variant="text"
              sx={{ mr: "12px", fontWeight: "400" }}
            >
              {confirmEstimate.backButtonText}
            </CustomButton>
            <div className="flex items-center">
              <p className="mr-6 text-aa-dark-grey">{confirmEstimate.confirmationText}</p>
              <CustomButton
                sx={{ fontWeight: "400" }}
                variant="outlined"
                loading={formik.isSubmitting}
                disabled={!formik.isValid || formik.isSubmitting}
                type={"submit"}
              >
                {confirmEstimate.confirmButtonText}
              </CustomButton>
            </div>
          </div>
        </form>
      </Box>
    </CenteredDiv>
  );
}
